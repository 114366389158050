import { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import usePermission from 'hooks/usePermission';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MinimalLayout from 'layout/MinimalLayout';
import useAuth from 'hooks/useAuth';
import { PHYSICIAN_CLIENT } from 'constant';
import Spinner from 'ui-component/Spinner';
// import LoginRoutes from './LoginRoutes';

const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user, isLoggedIn, isAppLoaded } = useAuth();
    const { hasPermission, isCynergyClient } = usePermission();

    const AccessibleRoutes = {
        ...MainRoutes,
        children: MainRoutes.children.filter(
            (item) =>
                hasPermission(item.permissions) &&
                (user.is_superuser && user?.client_id === PHYSICIAN_CLIENT
                    ? [
                          '/dashboard',
                          '/settings/client',
                          '/settings/location',
                          '/settings/employee',
                          '/settings/add-employee',
                          '/settings/add-client',
                          '/settings/edit-client/:id',
                          '/settings/edit-employee/:id',
                          '/mail',
                          '/account-profile'
                      ].includes(item.path)
                    : true) &&
                (isCynergyClient || user?.is_superuser || item.path !== '/reports/new-payroll-report') &&
                (isCynergyClient || user?.is_superuser || item.path !== '/settings/client-invoice') &&
                (isCynergyClient || user?.is_superuser || item.path !== '/reports/payroll-report') &&
                (user?.is_superuser || item.path !== '/personal-care-sessions')
        )
    };

    const RestrictedRoutes = {
        path: '/',
        element: (
            <AuthGuard>
                <MinimalLayout />
            </AuthGuard>
        ),
        children: MainRoutes.children
            .filter((item) => !hasPermission(item.permissions))
            .map((item) => ({
                path: item.path,
                element: <Error message="You do not have permission to access this page." />
            }))
    };

    const appRoutes = useRoutes([
        {
            path: '/',
            element: <Navigate to="/dashboard" replace />
        },
        {
            path: '*',
            element: <Error message="Page Not Found" />
        },
        AuthenticationRoutes,
        AccessibleRoutes,
        RestrictedRoutes
        // ...[...(isLoggedIn ? [AccessibleRoutes, RestrictedRoutes] : [])]
    ]);

    if (!isAppLoaded && isLoggedIn) {
        return <Spinner />;
    }

    return appRoutes;
}
