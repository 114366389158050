import { api } from './api';

export const coreApi = api.injectEndpoints({
    endpoints: (builder) => ({
        serviceList: builder.query({
            query: (payload) => ({
                url: 'api/core/service/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['ServiceList']
        }),
        subscriptionList: builder.query({
            query: (payload) => ({
                url: 'api/core/subscription/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['SubscriptionList']
        }),
        licenseTypeList: builder.query({
            query: (payload) => ({
                url: 'api/core/licencetype/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['LicenseTypeList']
        }),
        sendErrorLogMail: builder.mutation({
            query: (payload) => ({
                url: '/api/core/log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'send'
                }
            })
        }),
        missedReasonsList: builder.query({
            query: (payload) => ({
                url: 'api/core/reason/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
        }),
    })
});

export const { useServiceListQuery, useSubscriptionListQuery, useLicenseTypeListQuery, useSendErrorLogMailMutation, useMissedReasonsListQuery } = coreApi;
