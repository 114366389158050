import moment from 'moment';

export const isDateValid = (date, format = 'YYYY-MM-DD') => {
    const year = moment(date, format).year();
    if (year < 1000 || year > 9999) return false;
    // const month = moment(date, format).month();
    // if (month < 0 || month > 11) return false;
    // const day = moment(date, format).date();
    // if (day < 1 || day > 31) return false;

    if (date) {
        return moment(date, format).isValid();
    }
    return false;
};
