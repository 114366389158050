import { api } from './api';

export const locationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // common
        getLocationList: builder.query({
            query: (payload) => ({
                url: 'api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['LocationList']
        }),
        getLocationCustomList: builder.query({
            query: ({ payload, subType }) => ({
                url: 'api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: subType || 'LIST'
                }
            }),
            providesTags: ['LocationList']
        }),
        deleteLocation: builder.mutation({
            query: (payload) => ({
                url: 'api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['LocationList']
        }),
        // history
        getLocationHistory: builder.query({
            query: (payload) => ({
                url: 'api/location/activity/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            invalidatesTags: ['LocationHistory']
        }),
        getLocationTypes: builder.query({
            query: (payload) => ({
                url: 'api/core/locationtype/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['LocationTypeList']
        }),
        getPatientLocation: builder.query({
            query: (payload) => ({
                url: 'api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'patientlocation'
                }
            }),
            providesTags: ['PatientLocation']
        }),
        getContractType: builder.query({
            query: (payload) => ({
                url: 'api/core/contract-type/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['ContractTypes']
        }),
        getClientPatientLocation: builder.query({
            query: (payload) => ({
                url: 'api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'getpatientlocation'
                }
            }),
            // providesTags: ['LocationList']
        })
    })
});

export const {
    useGetLocationListQuery,
    useGetLocationCustomListQuery,
    useDeleteLocationMutation,
    useGetLocationHistoryQuery,
    useGetLocationTypesQuery,
    useGetPatientLocationQuery,
    useGetContractTypeQuery,
    useGetClientPatientLocationQuery
} = locationApi;
