import React from 'react';
import { Button, Grid, Popover, Stack, Typography } from '@mui/material';

function ConfirmationTooltip(props) {
    const {
        open,
        id,
        uploadConfirm,
        handleCloseUpload,
        handleConfirmUpload,
        description,
        txtWidth = 'none',
        anchorVer = 'bottom',
        anchorHor = 'left',
        transVer = 'top',
        transHor = 'right',
        sx,
        numberField,
        successButton,
        cancelButton
    } = props;

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={uploadConfirm}
                onClose={handleCloseUpload}
                anchorOrigin={{
                    vertical: anchorVer,
                    horizontal: anchorHor
                }}
                transformOrigin={{
                    vertical: transVer,
                    horizontal: transHor
                }}
            >
                <Stack sx={{ ...sx }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {numberField && numberField()}

                            {description && <Typography sx={{ p: 2, padding: '10px', maxWidth: txtWidth }}>{description}</Typography>}

                            <div style={{ padding: '5px 8px 8px', display: 'flex', justifyContent: 'space-evenly' }}>
                                {successButton ? (
                                    successButton({
                                        onClick: handleConfirmUpload
                                    })
                                ) : (
                                    <Button
                                        sx={{ margin: '0px 4px' }}
                                        variant="outlined"
                                        autoFocus
                                        size="small"
                                        fullWidth
                                        onClick={() => handleConfirmUpload()}
                                    >
                                        Yes
                                    </Button>
                                )}
                                {cancelButton ? (
                                    cancelButton({
                                        onClick: handleCloseUpload
                                    })
                                ) : (
                                    <Button
                                        sx={{ margin: '0px 4px' }}
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        onClick={() => handleCloseUpload()}
                                    >
                                        No
                                    </Button>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Stack>
            </Popover>
        </>
    );
}

export default ConfirmationTooltip;
