/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Link as MuiLink,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Delete, EditTwoTone, Lock, VisibilityTwoTone } from '@mui/icons-material';
import { encriptId } from 'utils/encription';
import usePermission from 'hooks/usePermission';
import { GLOBAL_DATE_FORMAT, LIST_LIMIT, THERAPIES, THERAPY_STATUS } from 'constant';
import {
    useDeleteEvaluationMutation,
    useGetEvaluationsQuery,
    useLazyGetEvaluationDurationQuery,
    useUnlockEvaluationMutation,
    useUpdateEvaluationDurationMutation
} from 'store/services/therapy/evaluation';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import ConfirmationModal from 'components/reusable/ConfirmationModal';
import useSnackbar from 'hooks/useSnackbar';
import Spinner from 'ui-component/Spinner';
import EvaluationView from './View';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useGetLocationsQuery, useLazyGetPatientListQuery } from 'store/services/patient';
import { useDeleteEventMutation, useGetSchedulesQuery, useGetTherapySchedulesQuery } from 'store/services/schedule/events';
import AddEventFrom from 'components/schedule/event/AddEventForm';
import CTable from 'components/CTable';
import Filters, { getFilterValues } from 'components/common/Filters';
import { useGetTherapistListQuery } from 'store/services/therapy';
import TabPanel, { CTabs, a11yProps } from 'components/reusable/CTabPanel';

import { PatientDropdown } from 'components/reusable/Dropdowns';
import { EditTreatmentPlanModal } from 'components/therapy/TreatmentPlan';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TbClockEdit } from 'react-icons/tb';
import ConfirmationTooltip from 'components/reusable/ConfirmationTooltip';
import { useFormik } from 'formik';
import { payloadToFormData } from 'utils/payload';
import * as yup from 'yup';
import EvalHistoryView from './History';
import { MdOutlineEditCalendar } from 'react-icons/md';
import HistoryIcon from '@mui/icons-material/History';

export const getEmployeeName = (employee) => {
    if (!employee) return '';

    // eslint-disable-next-line no-nested-ternary
    const name = employee.name
        ? employee.name
        : employee.first_name && employee.last_name
          ? `${employee.first_name} ${employee.last_name}`
          : '';
    const credentials = employee.credentials ? `, ${employee.credentials}` : '';
    const npi = employee.npi ? ` (${employee.npi})` : '';
    return `${name}${credentials}${npi}`;
};

const allyProps = {
    minwidth: 160,
    flex: 1,
    sortable: false
};

const validationSchema = yup.object({
    oldDuration: yup.number(),
    newDuration: yup
        .number()
        .required('* This field is required')
        .min(1, 'Must be greater than 0')
        .max(99, 'Must be less than 100')
        .test('value-not-same', 'Value should not be the same as Old Evaluation Duration', function (value) {
            return value !== this.parent.oldDuration;
        })
});

const initialFilters = [
    {
        id: 'locations',
        label: 'Locations',
        type: 'checkbox',
        multiple: true,
        options: [],
        default: []
    },
    {
        id: 'therapists',
        label: 'Therapists',
        type: 'checkbox',
        multiple: true,
        options: [],
        default: []
    }
    // {
    //     id: 'status',
    //     label: 'Status',
    //     type: 'checkbox',
    //     multiple: true,
    //     options: [
    //         { id: 7, name: 'Scheduled' },
    //         { id: 8, name: 'In Progress' },
    //         { id: 9, name: 'Completed' }
    //     ],
    //     default: []
    // }
];

export const scheduleCols = (user, hasPermission, handleEventSelect, handleEditClick, handleOpenDelete) => [
    {
        field: 'start_date_time',
        headerName: 'Schedule Date',
        ...allyProps,
        // valueGetter: (params) => moment.utc(params?.value).local().format(GLOBAL_DATE_FORMAT)
        renderCell: (params) => {
            return (
                <Box sx={{ position: 'relative' }}>
                    <Typography sx={{ paddingLeft: '8px' }} variant="body1">
                        {moment.utc(params?.value).local().format(GLOBAL_DATE_FORMAT)}
                    </Typography>

                    {Boolean(params?.row?.is_504) && (
                        <Tooltip title="504 Plan">
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: -20,
                                    left: -10,
                                    width: 0,
                                    height: 0,
                                    borderStyle: 'solid',
                                    borderWidth: '30px 30px 0 0',
                                    borderColor: '#1B357C transparent transparent transparent',
                                    zIndex: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: 'px',
                                        fontSize: '9px',
                                        color: '#fff'
                                    }}
                                >
                                    504
                                </Box>
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            );
        }
    },
    {
        field: 'patients',
        headerName: 'Patient',
        ...allyProps,
        renderCell: (params) => (
            <Typography variant="body1">
                {hasPermission(['patient_edit']) ? (
                    <MuiLink target="_blank" underline="none" component={Link} to={`/edit-patient/${encriptId(params?.value?.[0]?.id)}`}>
                        {params?.value?.[0]?.name}
                    </MuiLink>
                ) : (
                    <Typography variant="body1">{params?.value?.[0]?.name}</Typography>
                )}
            </Typography>
        )
    },
    {
        field: 'locations',
        headerName: 'Location',
        ...allyProps,
        valueGetter: (params) => params?.value?.[0]?.name
    },
    {
        field: 'employees',
        headerName: 'Therapist',
        ...allyProps,
        valueGetter: (params) => getEmployeeName(params.value?.[0])
    },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     ...allyProps,
    //     renderCell: (params) => <Chip label={params?.value} color={params?.value === 'COMPLETED' ? 'success' : 'warning'} size="medium" />
    // },
    {
        field: 'actions',
        headerName: 'Actions',
        ...allyProps,
        renderCell: (params) => (
            <Stack direction="row" justifyContent="center" alignItems="center">
                {hasPermission(['therapy_appointments_view']) && (
                    <Tooltip title="View">
                        <IconButton color="primary" size="large" onClick={() => handleEventSelect(params?.row)}>
                            <VisibilityTwoTone color="secondary" sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                )}
                {hasPermission([`therapy_appointments_edit`]) && params?.row.status === 'SCHEDULED' && (
                    <IconButton title="Edit" color="primary" onClick={() => handleEditClick(params?.row)}>
                        <EditTwoTone />
                    </IconButton>
                )}
                {hasPermission([`therapy_appointments_delete`]) && params?.row.status === 'SCHEDULED' && (
                    <IconButton title="Delete" color="primary" onClick={() => handleOpenDelete(params?.row)}>
                        <Delete />
                    </IconButton>
                )}
            </Stack>
        )
    }
];

const canEditEvaluation = (params, user, therapy, hasPermission, isSuperAdmin) => {
    if (isSuperAdmin) return true;
    const hasEditPermission = hasPermission([`${therapy}_eval_edit`]);
    const isOwner = params?.row.employee.id === user?.emp_id;
    const isSupervisee = user.supervisee?.includes(params?.row.employee.id);
    const isNotSignedByTherapist = !params?.row.is_signed_by_therapist || isSupervisee;
    const isNotFullySigned = !params?.row.is_full_signed;

    return (hasEditPermission || isOwner || isSupervisee) && isNotSignedByTherapist && isNotFullySigned;
};

const evaluationCols = (
    navigate,
    therapy,
    user,
    hasPermission,
    isSuperAdmin,
    handleView,
    handleHistory,
    showDeleteModal,
    handleUnlockOpen,
    handleOpenEvalTreatmentPlan,
    hasRole,
    handleEvalDur
) => [
    {
        field: 'date',
        headerName: 'Date',
        ...allyProps,
        // valueGetter: (params) => moment.utc(params?.value).local().format(GLOBAL_DATE_FORMAT)
        renderCell: (params) => {
            return (
                <Box sx={{ position: 'relative' }}>
                    <Typography sx={{ paddingLeft: '8px' }} variant="body1">
                        {moment.utc(params?.value).local().format(GLOBAL_DATE_FORMAT)}
                    </Typography>

                    {Boolean(params?.row?.is_504) && (
                        <Tooltip title="504 Plan">
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: -20,
                                    left: -10,
                                    width: 0,
                                    height: 0,
                                    borderStyle: 'solid',
                                    borderWidth: '30px 30px 0 0',
                                    borderColor: '#1B357C transparent transparent transparent',
                                    zIndex: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: 'px',
                                        fontSize: '9px',
                                        color: '#fff'
                                    }}
                                >
                                    504
                                </Box>
                            </Box>
                        </Tooltip>
                    )}
                </Box>
            );
        }
    },
    {
        field: 'patient__name',
        headerName: 'Patient',
        ...allyProps,
        renderCell: (params) => (
            <Typography variant="body1">
                {hasPermission(['patient_edit']) ? (
                    <MuiLink target="_blank" underline="none" component={Link} to={`/edit-patient/${encriptId(params?.row.patient?.id)}`}>
                        {params?.row.patient?.name}
                    </MuiLink>
                ) : (
                    <Typography variant="body1">{params?.row.patient?.name}</Typography>
                )}
            </Typography>
        )
    },
    {
        field: 'treatment_plan',
        headerName: 'Treatment Plan',
        ...allyProps,
        valueGetter: (params) => params.value?.name
    },
    {
        field: 'location',
        headerName: 'Location',
        ...allyProps,
        valueGetter: (params) => params?.value.name
    },
    {
        field: 'employee',
        headerName: 'Therapist',
        ...allyProps,
        valueGetter: (params) => getEmployeeName(params.value)
    },

    {
        field: 'is_batched',
        headerName: 'Billed',
        ...allyProps,
        valueGetter: (params) => (params?.value ? 'Yes' : 'No')
    },
    {
        field: 'actions',
        headerName: 'Actions',
        ...allyProps,
        minWidth: 270,
        renderCell: (params) => (
            <Stack direction="row" justifyContent="center" alignItems="center">
                {hasPermission([`${therapy}_eval_view`]) && (
                    <Tooltip title="View">
                        <IconButton color="primary" size="large" onClick={() => handleView(params?.row.id)}>
                            <VisibilityTwoTone color="secondary" sx={{ fontSize: '1.3rem' }} />
                        </IconButton>
                    </Tooltip>
                )}
                {canEditEvaluation(params, user, therapy, hasPermission, isSuperAdmin) && (
                    <Link
                        to={{
                            pathname: `/therapy/${therapy}/evaluation/edit/${encriptId(params?.row.id)}`,
                            search: params?.row.is_signed_by_therapist || params?.row.is_full_signed ? '?signed=true' : ''
                        }}
                    >
                        <IconButton title="Edit" color="primary">
                            <EditTwoTone />
                        </IconButton>
                    </Link>
                )}
                {(hasRole(['Therapist']) ||
                    hasPermission([`${therapy}_eval_edit`]) ||
                    params?.row.employee.id === user?.emp_id ||
                    user.supervisee?.includes(params?.row.employee.id)) &&
                    params?.row.is_full_signed && (
                        <IconButton title="Edit Treatment Plan" color="primary" onClick={() => handleOpenEvalTreatmentPlan(params?.row)}>
                            <EditNoteIcon />
                        </IconButton>
                    )}
                {params?.row?.is_full_signed && isSuperAdmin && (
                    <Tooltip title="Edit Evaluation Duration">
                        <IconButton sx={{ fontSize: '1.3rem' }} color="primary" onClick={(e) => handleEvalDur(e, params?.row)}>
                            {/* <TbClockEdit /> */}
                            <MdOutlineEditCalendar />
                        </IconButton>
                    </Tooltip>
                )}
                {hasPermission([`${therapy}_eval_view`]) && (
                    <Tooltip title="View History">
                        <IconButton color="secondary" size="large" onClick={() => handleHistory(params?.row.id)}>
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {(hasPermission([`${therapy}_eval_delete`]) ||
                    params?.row.employee.id === user?.emp_id ||
                    user.supervisee?.includes(params?.row.employee.id)) &&
                    (!params?.row.is_signed_by_therapist || user.supervisee?.includes(params?.row.employee.id)) &&
                    !params?.row.is_full_signed && (
                        <IconButton title="Delete" color="primary" onClick={() => showDeleteModal(params?.row.id)}>
                            <Delete />
                        </IconButton>
                    )}
                {!params?.row.is_batched &&
                    !params?.row.is_invoiced &&
                    hasPermission([`${therapy}_eval_unlock`]) &&
                    (params?.row.is_signed_by_therapist || params?.row.is_full_signed) && (
                        <IconButton title="Unlock" color="primary" onClick={() => handleUnlockOpen(params?.row.id)}>
                            <LockOpenIcon />
                        </IconButton>
                    )}
                {(params?.row.is_signed_by_therapist || params?.row.is_full_signed) && (
                    <IconButton title={params?.row.is_full_signed ? 'Fully Signed' : 'Supervisor Sign is Pending'} color="info">
                        <Lock />
                    </IconButton>
                )}
            </Stack>
        )
    }
];

function Evaluation() {
    const { user } = useAuth();
    const { therapy } = useParams();
    const navigate = useNavigate();

    const [filters, setFilters] = useState(initialFilters);
    const [filterValues, setFilterValues] = useState(getFilterValues(initialFilters));

    const onFilterChange = (value) => {
        setFilterValues(value);
    };

    const { data: locationList, isFetching: locationLoading } = useGetLocationsQuery({
        filters: {
            ...(user?.client_id && { client_id: user.client_id }),
            order_col: 'name',
            order: 'asc'
        }
    });

    useEffect(() => {
        if (locationList) {
            setFilters((prev) => {
                const newFilters = [...prev];
                const locationFilter = newFilters.find((item) => item.id === 'locations');
                locationFilter.options = locationList.fields.map((item) => ({
                    id: item.id,
                    name: item.name,
                    isActive: item?.is_active
                }));
                return newFilters;
            });
        }
    }, [locationList]);

    const { data: therapistList, isFetching: therapyLoading } = useGetTherapistListQuery({
        filters: {
            ...(user?.client_id && { clients: [user.client_id] }),
            services: [THERAPIES[therapy]],
            locations: filterValues.locations,
            order_col: 'name',
            order: 'asc'
        }
    });

    useEffect(() => {
        if (therapistList) {
            setFilters((prev) => {
                const newFilters = [...prev];
                const therapistFilter = newFilters.find((item) => item.id === 'therapists');
                therapistFilter.options = therapistList.fields.map((item) => ({
                    id: item.emp_id,
                    name: item.name
                }));
                return newFilters;
            });
        }
    }, [therapistList]);

    const { state: urlState } = useLocation();

    const { hasPermission } = usePermission();
    const [patient, setPatient] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const [getPatients, { data: patients, isLoading: patientLoading, isFetching: patientFetching }] = useLazyGetPatientListQuery();

    useEffect(() => {
        const payload = {
            filters: {
                name: searchTerm,
                // status: 1,
                order_col: 'name',
                order: 'asc'
            }
        };

        if (user?.client_id) {
            payload.filters.client_id = user.client_id;
        }

        const delayDebounceFn = setTimeout(() => {
            try {
                if (searchTerm) {
                    getPatients(payload);
                }
            } catch (error) {
                console.log(error);
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const [value, setValue] = useState(urlState ? 1 : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainCard
            title={`Evaluation (${therapy.toUpperCase()})`}
            secondary={
                <Stack direction="row" alignItems="center">
                    {!urlState && (
                        // <Autocomplete
                        //     size="small"
                        //     id="patients-select"
                        //     disablePortal
                        //     sx={{ width: 240 }}
                        //     options={patients?.fields || []}
                        //     loading={patientLoading || patientFetching}
                        //     onChange={(_, value) => {
                        //         setPatient(value);
                        //     }}
                        //     getOptionLabel={(option) => `${option.user?.first_name} ${option.user?.last_name}`}
                        //     renderOption={(props, option) => (
                        //         <li {...props} key={option.id}>
                        //             {`${option?.user?.first_name} ${option?.user?.last_name}`}
                        //         </li>
                        //     )}
                        //     isOptionEqualToValue={(option, value) => option.id === value.id}
                        //     renderInput={(params) => (
                        //         <TextField
                        //             {...params}
                        //             placeholder="type to search"
                        //             label="Patient"
                        //             onChange={(e) => setSearchTerm(e.target.value)}
                        //         />
                        //     )}
                        // />
                        <PatientDropdown
                            async
                            size="small"
                            sx={{ width: 240 }}
                            onChange={(value) => {
                                setPatient(value);
                            }}
                        />
                    )}

                    <Filters.Root dateRageKeys={['startDate', 'endDate']} value={filterValues} filters={filters} onChange={onFilterChange}>
                        <Filters.Datepicker sx={{ my: 2 }} label="From Date" id="startDate" />
                        <Filters.Datepicker label="To Date" id="endDate" />
                    </Filters.Root>

                    {hasPermission([`${therapy}_eval_add`]) && (
                        <Grid item>
                            <Button variant="contained" onClick={() => navigate(`/therapy/${therapy}/evaluation/create`)}>
                                Add new Evaluation
                            </Button>
                        </Grid>
                    )}
                </Stack>
            }
            isLoading={locationLoading || therapyLoading}
        >
            <Box>
                <CTabs value={value} onChange={handleChange}>
                    {!urlState && <Tab label="Scheduled" {...a11yProps(0)} />}
                    <Tab label={urlState ? 'Expired Evaluation' : 'In Progress'} {...a11yProps(1)} />
                    {!urlState && <Tab label="Signed" {...a11yProps(1)} />}
                </CTabs>

                <TabPanel value={value} index={0}>
                    <ScheduledEvaluations patient={patient} filterValues={filterValues} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CreatedEvaluations patient={patient} filterValues={filterValues} statuses={[THERAPY_STATUS.ONGOING]} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <CreatedEvaluations patient={patient} filterValues={filterValues} statuses={[THERAPY_STATUS.COMPLETED]} />
                </TabPanel>
            </Box>
        </MainCard>
    );
}

const ScheduledEvaluations = ({ patient, filterValues }) => {
    const { user } = useAuth();
    const { therapy } = useParams();

    const [schedulePage, setSchedulePage] = useState(1);

    const handleSchedulePageChange = (_e, val) => {
        setSchedulePage(val);
    };

    const { hasPermission } = usePermission();
    const service = THERAPIES[therapy];

    useEffect(() => {
        setSchedulePage(1);
    }, [patient, filterValues]);

    const { notify } = useSnackbar();

    const eventPayload = {
        filters: {
            ...(user?.client_id && { client_id: user.client_id }),
            type: 'EVALUATION',
            source: 'schedule',
            patient: patient?.id,
            service,
            status_ids: [THERAPY_STATUS.SCHEDULED],
            ...(filterValues.startDate && {
                start_date_time: moment(new Date(filterValues.startDate)).startOf('day').utc().format('YYYY-MM-DD HH:mm')
            }),
            ...(filterValues.endDate && {
                end_date_time: moment(new Date(filterValues.endDate)).endOf('day').utc().format('YYYY-MM-DD HH:mm')
            }),
            ...(filterValues.locations.length > 0 && { location_ids: filterValues.locations }),
            ...(filterValues.therapists.length > 0 && { therapist_ids: filterValues.therapists })
        },
        pagination: {
            current_page: schedulePage
        }
    };

    const { data: scheduleData, isFetching: scheduleLoading } = useGetTherapySchedulesQuery(eventPayload);

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tableEdit, setTableEdit] = useState(false);

    const handleEventSelect = (row) => {
        setSelectedEvent(row);
    };
    const handleModalClose = () => {
        setSelectedEvent(null);
        setTableEdit(false);
    };

    const handleEditClick = (row) => {
        setSelectedEvent(row);
        setTableEdit((value) => !value);
    };

    const [showDelete, setShowDelete] = useState(null);

    const handleOpenDelete = (row) => {
        setShowDelete(row);
    };

    const handleCloseDelete = () => {
        setShowDelete(null);
    };

    const [deleteEvent, { isLoading: deleteLoading2 }] = useDeleteEventMutation();

    const handleEventDelete = async () => {
        try {
            await deleteEvent({ sch_id: showDelete.sch_id });
            handleCloseDelete();

            notify({
                message: 'Schedule deleted successfully',
                severity: 'success'
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <Box
                sx={{
                    height: 'calc(100vh - 280px)',
                    my: 2
                }}
            >
                <CTable
                    loading={scheduleLoading || deleteLoading2}
                    rows={scheduleData?.fields || []}
                    cols={scheduleCols(user, hasPermission, handleEventSelect, handleEditClick, handleOpenDelete)}
                    getRowId={(option) => option.sch_id}
                    currentPage={schedulePage}
                    totalCount={scheduleData?.pagination?.total_records}
                    countPerPage={scheduleData?.pagination?.records_per_page}
                    onPageChange={handleSchedulePageChange}
                />
            </Box>

            <ConfirmationModal
                open={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={handleEventDelete}
                title="Delete Event"
                description="Are you sure you want to delete this schedule?"
            />
            {hasPermission(['therapy_appointments_view']) && (
                <Dialog
                    maxWidth="md"
                    fullWidth
                    onClose={handleModalClose}
                    open={Boolean(selectedEvent)}
                    sx={{ '& .MuiDialog-paper': { p: 0 } }}
                >
                    <AddEventFrom event={selectedEvent} onCancel={handleModalClose} table tableEdit={tableEdit} />
                </Dialog>
            )}
        </div>
    );
};

function CreatedEvaluations({ patient, filterValues, statuses }) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { therapy } = useParams();
    const { notify } = useSnackbar();

    const { state: urlState } = useLocation();

    const service = THERAPIES[therapy];

    const [page, setPage] = useState(1);

    const handlePageChange = (_e, val) => {
        setPage(val);
    };

    const { hasPermission, hasRole, isSuperAdmin } = usePermission();

    const [evalDurConfirm, setEvalDurConfirm] = useState(null);
    const [evalDur, setEvalDur] = useState(null);

    useEffect(() => {
        setPage(1);
    }, [patient, filterValues]);

    const payload = {
        filters: urlState
            ? {
                  client_id: user?.client_id,
                  ...(urlState?.notification_details && { notification_details: urlState.notification_details })
              }
            : {
                  status: 1,
                  service_id: [service],
                  ...(user?.client_id && { client_id: user.client_id }),
                  ...(patient?.id && { patient_id: [patient.id] }),
                  ...(filterValues.locations.length > 0 && { location_ids: filterValues.locations }),
                  ...(filterValues.therapists.length > 0 && { therapist_ids: filterValues.therapists }),
                  ...(filterValues.startDate && {
                      start_date: moment(new Date(filterValues.startDate)).startOf('day').utc().format('YYYY-MM-DD HH:mm')
                  }),
                  ...(filterValues.endDate && {
                      end_date: moment(new Date(filterValues.endDate)).endOf('day').utc().format('YYYY-MM-DD HH:mm')
                  }),
                  status_ids: statuses
              },
        pagination: {
            current_page: parseInt(page, 10)
        }
    };

    const formik = useFormik({
        initialValues: {
            oldDuration: '',
            newDuration: ''
        },
        // enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: async (values) => {
            handleConfirmEvalDur();
        }
    });

    const { data: evaluationData, isFetching: isLoading } = useGetEvaluationsQuery(payload);

    const [deleteId, setDeleteId] = useState(null);

    const showDeleteModal = (id) => {
        setDeleteId(id);
    };

    const hideDeleteModal = () => {
        setDeleteId(null);
    };

    const [deleteEvaluation, { isLoading: deleteLoading }] = useDeleteEvaluationMutation();
    const confirmDelete = async () => {
        try {
            const res = await deleteEvaluation({ id: deleteId }).unwrap();
            notify({
                message: res.message,
                severity: 'success'
            });
            hideDeleteModal();
        } catch (error) {
            console.log(error);
            notify({
                message: error.data?.error?.message || 'Something went wrong',
                severity: 'error'
            });
        }
    };

    const [evaluationId, setEvaluationId] = useState(null);
    const [evalId, setEvalId] = useState(null);

    const handleView = (id) => {
        setEvaluationId(id);
    };

    const handleViewClose = () => {
        setEvaluationId(null);
    };

    const handleHistory = (id) => {
        setEvalId(id);
    };

    const handleHistoryClose = () => {
        setEvalId(null);
    };

    const [unlockId, setUnlockId] = useState(null);

    const handleUnlockOpen = (id) => {
        setUnlockId(id);
    };

    const handleUnlockClose = () => {
        setUnlockId(null);
    };

    const [unlockEvaluation, { isLoading: isUnlocking }] = useUnlockEvaluationMutation();

    const handleConfirmUnlock = async () => {
        try {
            const res = await unlockEvaluation({ id: unlockId }).unwrap();
            notify({
                message: res.message,
                severity: 'success'
            });
            handleUnlockClose();
        } catch (error) {
            console.log(error);
            notify({
                message: error.data?.message || 'Something went wrong',
                severity: 'error'
            });
        }
    };

    const [evalTreatmentPlan, setEvalTreatmentPlan] = useState(null);

    const handleOpenEvalTreatmentPlan = (data) => {
        setEvalTreatmentPlan(data);
    };

    const handleCloseEvalTreatmentPlan = () => {
        setEvalTreatmentPlan(null);
    };

    const [evaluationDuration, { isLoading: isDurLoading, isFetching: isDurFetching }] = useLazyGetEvaluationDurationQuery();
    const [updateEvalDur, { isLoading: isUpdateDurLoading, isFetching: isUpdateDurFetching }] = useUpdateEvaluationDurationMutation();

    const handleCloseEvalDur = () => {
        setEvalDurConfirm(false);
        setEvalDur(null);
        formik.resetForm();
    };

    const handleEvalDur = async (e, row) => {
        const currentTarget = e.currentTarget;
        const formData = payloadToFormData({
            evaluation_id: row?.id
        });
        try {
            const res = await evaluationDuration(formData);
            if (res?.isSuccess) {
                setEvalDurConfirm(currentTarget);
                setEvalDur(row);
                formik.setFieldValue('oldDuration', res?.data?.fields?.duration_month);
            }
        } catch (error) {
            notify({
                message: error.data?.message || 'Something went wrong',
                severity: 'error'
            });
        }
    };

    const openConfirmEvalDur = Boolean(evalDurConfirm);
    const id = openConfirmEvalDur ? 'simple-popover' : undefined;

    const handleConfirmEvalDur = async () => {
        const formData = payloadToFormData({
            evaluation_id: evalDur?.id,
            duration_month: formik?.values?.newDuration
        });

        const { data: { fields: activityLogs } = {}, error } = await updateEvalDur(formData);

        if (error) {
            notify({ message: error?.data?.message?.[0] || 'Something went wrong', severity: 'error' });
        } else {
            handleCloseEvalDur();
            notify({ message: 'Evaluation duration updated', severity: 'success' });
        }
    };

    return (
        <div>
            <Box sx={{ height: 'calc(100vh - 280px)', my: 2 }}>
                <CTable
                    loading={isLoading || deleteLoading || isUnlocking || isDurFetching || isUpdateDurFetching}
                    rows={evaluationData?.fields || []}
                    cols={evaluationCols(
                        navigate,
                        therapy,
                        user,
                        hasPermission,
                        isSuperAdmin,
                        handleView,
                        handleHistory,
                        showDeleteModal,
                        handleUnlockOpen,
                        handleOpenEvalTreatmentPlan,
                        hasRole,
                        handleEvalDur
                    ).filter((item) => item.field !== 'is_batched')}
                    getRowId={(option) => option.id}
                    currentPage={page}
                    totalCount={evaluationData?.pagination?.total_records}
                    countPerPage={evaluationData?.pagination?.records_per_page}
                    onPageChange={handlePageChange}
                />
            </Box>
            <ConfirmationTooltip
                open={openConfirmEvalDur}
                id={id}
                uploadConfirm={evalDurConfirm}
                handleCloseUpload={handleCloseEvalDur}
                handleConfirmUpload={formik.handleSubmit}
                anchorHor="left"
                transHor="right"
                sx={{
                    minWidth: '300px'
                }}
                numberField={(props) => (
                    <Stack sx={{ margin: '15px 15px 5px 15px' }}>
                        <TextField
                            required
                            fullWidth
                            disabled
                            variant="outlined"
                            name="oldDuration"
                            type="number"
                            label="Old Evaluation Duration (Months)"
                            value={formik.values.oldDuration}
                            inputProps={{ min: 0, max: 99 }}
                            sx={{ marginBottom: '10px' }}
                        />
                        <TextField
                            required
                            fullWidth
                            variant="outlined"
                            name="newDuration"
                            type="number"
                            label="New Evaluation Duration (Months)"
                            value={formik.values.newDuration}
                            onChange={(e, val) => {
                                formik.setFieldValue('newDuration', e.target.value);
                            }}
                            inputProps={{ min: 0, max: 99 }}
                            error={formik.touched.newDuration && Boolean(formik.errors.newDuration)}
                            helperText={formik.touched.newDuration && formik.errors.newDuration}
                        />
                    </Stack>
                )}
                successButton={(props) => (
                    <Button sx={{ margin: '0px 4px' }} variant="contained" autoFocus size="small" fullWidth {...props}>
                        Save
                    </Button>
                )}
                cancelButton={(props) => (
                    <Button sx={{ margin: '0px 4px' }} variant="outlined" size="small" fullWidth {...props}>
                        Cancel
                    </Button>
                )}
            />

            <EvaluationView evaluationId={evaluationId} service={service} handleClose={handleViewClose} />
            <EvalHistoryView evaluationId={evalId} handleClose={handleHistoryClose} />
            <ConfirmationModal
                open={Boolean(deleteId)}
                handleClose={hideDeleteModal}
                handleConfirm={confirmDelete}
                title="Delete Evaluation"
                description="Are you sure you want to delete this evaluation?"
            />
            <ConfirmationModal
                open={Boolean(unlockId)}
                handleClose={handleUnlockClose}
                handleConfirm={handleConfirmUnlock}
                title="Unlock Evaluation"
                description="Are you sure you want to unlock this evaluation?"
            />

            <EditTreatmentPlanModal evaluation={evalTreatmentPlan} handleClose={handleCloseEvalTreatmentPlan} />
        </div>
    );
}

export default Evaluation;