export const getBg = (service) => {
    switch (service) {
        case 1:
            return 'rgb(27, 53, 124)';
        case 2:
            return 'rgb(141, 110, 99)';
        case 3:
            return 'rgb(102, 187, 106)';
        case 4:
            return 'rgb(230, 81, 0)';
        case 5:
            return 'rgb(236, 156, 61)';
        case 6:
            return 'rgb(158, 30, 158)';
        case 7:
            return 'rgb(0, 121, 107)';
        default:
            return '';
    }
};

export const bgMissedReason = 'rgb(203 59 47 / 89%)';
