import React from 'react';
import { Divider, Typography, Stack } from '@mui/material';

function TitleDivider({ title, sx, gap = 1 }) {
    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                ...sx
            }}
            gap={gap}
        >
            <Divider
                sx={{
                    flex: 1
                }}
            />
            <Typography
                sx={{
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    color: 'text.secondary'
                }}
                variant="body"
            >
                {title}
            </Typography>
            <Divider
                sx={{
                    flex: 1
                }}
            />
        </Stack>
    );
}

export default TitleDivider;
