import React, { Children, cloneElement, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { FilterAlt, Search, TuneOutlined } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    Radio,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import _ from 'lodash';
import { AspyreDatePicker as DatePicker } from 'components/common/InputFields';

import PropTypes from 'prop-types';
import Spinner from 'ui-component/Spinner';
import { GLOBAL_DATE_FORMAT } from 'constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useSnackbar from 'hooks/useSnackbar';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

export const getFilterValues = (filters) => {
    const initialValues = {};

    filters.forEach((filter) => {
        initialValues[filter.id] = filter.default;
    });

    return initialValues;
};

const validationSchema = yup.object().shape({
    date: yup.date().typeError('Please enter a valid date').nullable()
});

function Datepicker(props) {
    const { value, onChange, id, containerProps, sx, ...rest } = props;
    // const [date, setDate] = useState(value?.[id] || null);

    const formik = useFormik({
        initialValues: {
            date: value?.[id] || null
        },
        validationSchema
    });

    useEffect(() => {
        onChange(id || 'date', formik.values.date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.date]);

    return (
        <Box {...containerProps}>
            <DatePicker
                id={id}
                name={id}
                inputFormat={GLOBAL_DATE_FORMAT}
                value={formik.values.date}
                onChange={(val) => formik.setFieldValue('date', val)}
                renderInput={(props) => (
                    <TextField
                        {...props}
                        sx={sx}
                        fullWidth
                        size="small"
                        inputProps={{
                            // eslint-disable-next-line react/destructuring-assignment, react/prop-types
                            ...props.inputProps
                        }}
                        error={Boolean(formik.errors.date)}
                        helperText={formik.errors.date}
                    />
                )}
                {...rest}
            />
        </Box>
    );
}

function CListGroup({ name, options, value, multiple, groupBy = false, groupByKey, onChange, radio = false }) {
    const [selected, setSelected] = useState(value ?? (radio ? null : []));

    const [searchText, setSearchText] = useState('');

    const handleCheck = (id) => {
        if (multiple) {
            const idx = selected.findIndex((i) => i === id);
            if (idx < 0) {
                setSelected([...selected, id]);
            } else {
                setSelected((val) => val?.filter((i) => i !== id));
            }
        } else {
            setSelected(id);
        }
    };

    useEffect(() => {
        onChange(selected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const iterable = searchText ? options?.filter((i) => i?.name?.toLowerCase()?.includes(searchText.toLowerCase())) : options;

    const groupedBy = groupBy && groupByKey ? Object.groupBy(iterable, ({ location }) => location?.name) : null;

    return (
        <Box sx={{ p: 1, height: 380, overflowY: 'auto' }}>
            <Stack sx={{ mb: 1 }}>
                <Typography variant="subtitle1">{name}</Typography>
                {options?.length > 15 && (
                    <Box sx={{ position: 'relative', m: 0, p: 0 }}>
                        <TextField
                            type="search"
                            placeholder="Search..."
                            size="small"
                            value={searchText}
                            inputProps={{
                                sx: { height: 15 },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                mt: 1,
                                maxWidth: 'calc(100% / 1.5)',
                                '& fieldset': {
                                    borderRadius: 1
                                },
                                '& .MuiOutlinedInput-input': {
                                    ml: 4,
                                    padding: '10px 5px'
                                }
                            }}
                            onChange={(event) => setSearchText(event.target.value)}
                        />
                        <Search
                            sx={{
                                position: 'absolute',
                                width: 24,
                                height: 24,
                                left: 5,
                                top: 13
                            }}
                        />
                    </Box>
                )}
            </Stack>
            {groupBy && groupByKey ? (
                <FormGroup>
                    {Object.keys(groupedBy).map((category) => (
                        <Stack>
                            <Typography sx={{ padding: '8px 0px', color: '#999999' }}>{category}</Typography>
                            {groupedBy[category].map((i) => (
                                <Tooltip key={i.id} title={i.name} PopperProps={{ disablePortal: true }}>
                                    <FormControlLabel
                                        control={
                                            radio ? (
                                                <Radio
                                                    color="primary"
                                                    size="small"
                                                    checked={selected === i.id}
                                                    onChange={() => handleCheck(i.id)}
                                                    sx={{ mt: 0.2 }}
                                                />
                                            ) : (
                                                <Checkbox
                                                    color="primary"
                                                    size="small"
                                                    checked={multiple ? selected?.includes(i.id) : selected === i.id}
                                                    onChange={() => handleCheck(i.id)}
                                                    sx={{ mt: 0.2 }}
                                                />
                                            )
                                        }
                                        label={i.name}
                                        sx={{
                                            width: '100%',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    />
                                </Tooltip>
                            ))}
                        </Stack>
                    ))}
                </FormGroup>
            ) : (
                <FormGroup>
                    {iterable?.map((i) => (
                        <Tooltip key={i.id} title={i.name} PopperProps={{ disablePortal: true }}>
                            <FormControlLabel
                                control={
                                    radio ? (
                                        <Radio
                                            color="primary"
                                            size="small"
                                            checked={selected === i.id}
                                            onChange={() => handleCheck(i.id)}
                                            sx={{ mt: 0.2 }}
                                        />
                                    ) : (
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            checked={multiple ? selected?.includes(i.id) : selected === i.id}
                                            onChange={() => handleCheck(i.id)}
                                            sx={{ mt: 0.2 }}
                                        />
                                    )
                                }
                                label={`${i.name}${_.has(i, 'isActive') && !i?.isActive ? ' (Inactive)' : ''}`}
                                sx={{
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            />
                        </Tooltip>
                    ))}
                </FormGroup>
            )}
        </Box>
    );
}

const objectHasValue = (obj) => {
    let counter = 0;
    Object.values(obj).forEach((i) => {
        if (i?.length > 0 || Object?.keys(i || {})?.length > 0 || Number?.isInteger(i)) {
            counter += 1;
        }
    });
    return counter;
};

const Root = forwardRef(
    (
        {
            value,
            filters,
            onChange,
            isLoading,
            children,
            validationSchema,
            dateRageKeys = ['fromDate', 'toDate'],
            noFilterIcon = false,
            disableSearch = false,
            label = 'Search...'
        },
        refs
    ) => {
        const { resetRef, countRef } = refs || {};

        const { notify } = useSnackbar();
        const initialFilterValues = getFilterValues(filters);
        const [indicator, setIndicator] = useState(0);
        const [filterAnchor, setFilterAnchor] = useState(null);
        const [filterInputs, setFilterInputs] = useState(initialFilterValues);

        const [firstDate, secondDate] = dateRageKeys;

        const [errors, setErrors] = useState({});

        const { pathname } = useLocation();

        useEffect(() => {
            setIndicator(0);
        }, [pathname]);

        const handleFilterOpen = (e) => {
            setFilterAnchor(e.currentTarget);
        };
        const handleFilterClose = () => {
            setFilterAnchor(null);
        };

        const handleFilterReset = () => {
            handleFilterClose();
            setFilterInputs(initialFilterValues);
            onChange(initialFilterValues);
            setIndicator(0);
        };

        useImperativeHandle(resetRef, () => ({
            handleFilterReset
        }));

        const handleCount = () => {
            setIndicator(indicator - 1);
            setFilterInputs(initialFilterValues);
        };

        useImperativeHandle(countRef, () => ({
            handleCount
        }));

        const handleFilterSearch = async () => {
            if (validationSchema) {
                const validate = await validationSchema.validate(filterInputs).catch((err) => err);
                if (validate.errors?.length > 0) {
                    notify({
                        message: validate.errors || 'Something went wrong',
                        severity: 'error'
                    });
                    return;
                }
            }

            if (Object.keys(errors).length > 0) {
                console.log(errors);
                return;
            }

            onChange(filterInputs);
            handleFilterClose();

            const count = objectHasValue(filterInputs);
            setIndicator(count);
        };

        useEffect(() => {
            const handleSearch = setTimeout(() => {
                onChange(filterInputs);
            }, 500);

            return () => clearTimeout(handleSearch);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [filterInputs.search]);

        const filterSearch = filters?.filter((i) => i.type === 'text');
        const filterLists = filters?.filter((i) => i.type === 'checkbox');

        const handleChange = (key, value) => {
            const updateInputs = { ...filterInputs, [key]: value };
            setFilterInputs(updateInputs);
            if (dateRageKeys.includes(key) && updateInputs[firstDate] && updateInputs[secondDate]) {
                let isValid = false;
                if (key === firstDate) {
                    isValid = new Date(value) <= new Date(updateInputs[secondDate]);
                } else {
                    isValid = new Date(updateInputs[firstDate]) <= new Date(value);
                }
                console.log({ isValid });
                if (!isValid) {
                    setErrors((prev) => ({
                        ...prev,
                        endDate: 'Start date cannot be greater than end date'
                    }));
                } else {
                    setErrors({});
                }
            }
        };

        return (
            <>
                <Stack sx={{ m: 1 }} direction="row" alignItems="center">
                    {filterSearch.length > 0 && (
                        <FormControl sx={{ mr: 1, width: 200 }}>
                            <TextField
                                label={label}
                                type="search"
                                variant="outlined"
                                size="small"
                                sx={{ my: 1 }}
                                disabled={disableSearch}
                                value={filterInputs.search}
                                onChange={(e) => {
                                    setFilterInputs((prev) => ({
                                        ...prev,
                                        search: e.target.value
                                    }));
                                }}
                            />
                        </FormControl>
                    )}
                    {!noFilterIcon ? (
                        <Tooltip title="Filters">
                            <IconButton color="primary" onClick={handleFilterOpen} sx={{ position: 'relative' }}>
                                {indicator ? (
                                    <Chip
                                        size="small"
                                        label={indicator}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            height: 20,
                                            width: 20,
                                            background: '#2196f3',
                                            color: '#ffffff',
                                            fontSize: 8,
                                            borderRadius: 1,
                                            '& span': {
                                                p: 0
                                            }
                                        }}
                                    />
                                ) : null}
                                <FilterAlt />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </Stack>
                <Popover
                    open={Boolean(filterAnchor)}
                    anchorEl={filterAnchor}
                    onClose={handleFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            maxHeight: 50,
                            p: 1,
                            borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TuneOutlined sx={{ height: 24, width: 24 }} />
                            <Typography sx={{ ml: 1 }} variant="subtitle1">
                                Filters
                            </Typography>
                        </Box>
                        <IconButton onClick={handleFilterClose}>
                            <Close sx={{ height: 24, width: 24 }} />
                        </IconButton>
                    </Stack>
                    <Grid
                        container
                        sx={{
                            maxWidth: 'calc(100vw / 2)',
                            minWidth: 300
                        }}
                    >
                        {isLoading && <Spinner />}
                        {children && (
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                sx={{
                                    maxHeight: 500,
                                    overflowY: 'auto',
                                    p: 1,
                                    borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`
                                }}
                            >
                                {Children.map(children, (child) => cloneElement(child, { value, onChange: handleChange }))}
                                {Object.keys(errors).map((item) => (
                                    <FormHelperText sx={{ mt: 1 }} error>
                                        {errors[item]}
                                    </FormHelperText>
                                ))}
                            </Grid>
                        )}
                        <Grid item xs={12} lg={children ? 8 : 12} sx={{ overflowY: 'auto', maxHeight: 500 }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                            >
                                {filterLists?.map((item, idx) => (
                                    <Box
                                        key={idx}
                                        sx={{
                                            flex: 1,
                                            minWidth: 300,
                                            maxWidth: 400,
                                            borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
                                            borderRight: (theme) => `1px solid ${theme.palette.grey[300]}`
                                        }}
                                    >
                                        <CListGroup
                                            value={value?.[item.id]}
                                            name={item.label}
                                            multiple={item.multiple}
                                            options={item?.options || []}
                                            onChange={(val) => handleChange(item.id, val)}
                                            radio={item?.radio}
                                            groupBy={item?.groupBy}
                                            groupByKey={item?.groupByKey}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{
                                    borderTop: (theme) => `1px solid ${theme.palette.grey[300]}`,
                                    p: 1,
                                    flexShrink: 0
                                }}
                            >
                                <Button size="small" variant="outlined" onClick={handleFilterReset}>
                                    Reset
                                </Button>
                                <Button size="small" variant="contained" onClick={handleFilterSearch}>
                                    Apply
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Popover>
            </>
        );
    }
);

Datepicker.propTypes = {
    id: PropTypes.number,
    value: PropTypes.object,
    InputProps: PropTypes.object,
    containerProps: PropTypes.object,
    onChange: PropTypes.func
};

CListGroup.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.object,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    radio: PropTypes.bool
};

Root.propTypes = {
    isLoading: PropTypes.bool,
    value: PropTypes.object,
    filters: PropTypes.array,
    onChange: PropTypes.func,
    children: PropTypes.node
};

export default { Root, Datepicker };
