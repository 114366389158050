/* eslint-disable react/self-closing-comp */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    DialogContent,
    Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import useSnackbar from 'hooks/useSnackbar';
import {
    useCreateUpdateTreatmentPlanMutation,
    useGetCopyPreviousGoalsQuery,
    useValidateTreatmentPlanGoalMutation
} from 'store/services/therapy/evaluation';
import { AspyreTextField } from 'components/common/InputFields';
import TitleDivider from 'components/reusable/TitleDivider';
import Spinner from 'ui-component/Spinner';
import { CLASSIFICATION_BLANK } from 'constant';
import { HiLightBulb } from 'react-icons/hi';

const validationSchema = Yup.object({
    planName: Yup.string().required('* This field is required'),
    impInfo: Yup.string(),
    minPerWeek: Yup.number().required('* This field is required'),
    longTermGoals: Yup.string()
});

function TreatmentPlanModal({ eid, patientId, serviceId, setClose, mode, planId, setPlanId }) {
    const accessToken = window.localStorage.getItem('accessToken');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [classification, setClassification] = useState(null);
    const [classifications, setClassifications] = useState([]);
    const [clsLoading, setClsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [goals, setGoals] = useState([]);
    const [selectedGoals, setSelectedGoals] = useState([]);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [plan, setPlan] = useState(null);
    const { notify } = useSnackbar();
    const [markedGoals, setMarkedGoals] = useState([]);

    const [createUpdateTreatmentPlan, { isLoading: createLoading }] = useCreateUpdateTreatmentPlanMutation();

    const [goalLoading, setGoalLoading] = useState(false);

    const fetchTreatmentGoals = useCallback(async () => {
        if (planId) {
            const data = {
                filters: {
                    treatmentplan_id: planId,
                    status: 1
                }
            };

            setGoalLoading(true);
            axios
                .post(`${API_ENDPOINT}/api/therapy/eval/treatment-plan/goal/`, data, {
                    headers: { SUBTYPE: 'list', Authorization: `Bearer ${accessToken}` }
                })
                .then((resp) => {
                    console.log(resp.data.fields);
                    if (resp.data?.fields?.length > 0) {
                        const goalFields = resp.data.fields?.map((goal) => ({
                            id: goal.goal,
                            goalId: goal.id,
                            classification: { name: goal.classification },
                            goal: goal.goal_text
                        }));
                        setSelectedGoals(goalFields);
                        setMarkedGoals(goalFields);
                    }
                })
                .catch((error) => {
                    console.log({ error });
                })
                .finally(() => {
                    setGoalLoading(false);
                });
        }
    }, [mode, planId]);

    console.log({ goalLoading });

    useEffect(() => {
        fetchTreatmentGoals();
    }, [fetchTreatmentGoals]);

    const formik = useFormik({
        initialValues: {
            planName: plan?.name || '',
            impInfo: plan?.important_informaion || '',
            minPerWeek: plan?.intervention_min_per_week || '',
            longTermGoals: plan?.long_term_goal || ''
        },
        validationSchema,
        onSubmit: async (values) => {
            try {
                const formData = new FormData();
                formData.append('evaluation_id', eid);
                formData.append('direct_intervension_min_per_week', values.minPerWeek);
                formData.append('long_term_goal', values.longTermGoals);
                formData.append('patient_important_info', values.impInfo);
                formData.append('name', values.planName);
                const updatedGoals = markedGoals.map((goal) => ({
                    ...goal,
                    goal: selectedGoals.find((item) => item.id === goal.id)?.goal || goal.goal
                }));
                updatedGoals.forEach((goal) => {
                    formData.append(
                        'goals[]',
                        JSON.stringify({
                            id: goal.id,
                            template: goal.goal
                        })
                    );
                });
                if (planId) {
                    formData.append('id', planId);
                }

                const res = await createUpdateTreatmentPlan({ id: planId, payload: formData }).unwrap();
                setPlanId(res.fields.id);
                res?.message?.forEach((message) => notify({ message, severity: 'success' }));
                fetchTreatmentGoals();
            } catch (error) {
                console.log(error);
                error.data?.message?.forEach((message) => notify({ message, severity: 'error' }));
            }
        }
    });

    useEffect(() => {
        if (planId) {
            const formData = new FormData();
            formData.append('id', planId);
            axios
                .post(`${API_ENDPOINT}/api/therapy/eval/treatment-plan/`, formData, {
                    headers: { SUBTYPE: 'detail', Authorization: `Bearer ${accessToken}` }
                })
                .then((resp) => {
                    setPlan(resp.data.fields);
                    formik.setFieldValue('planName', resp.data.fields?.name);
                    formik.setFieldValue('impInfo', resp.data.fields?.important_informaion);
                    formik.setFieldValue('minPerWeek', resp.data.fields?.intervention_min_per_week);
                    formik.setFieldValue('longTermGoals', resp.data.fields?.long_term_goal);
                });
        }
    }, [planId]);

    useEffect(() => {
        axios
            .post(
                `${API_ENDPOINT}/api/core/classification/`,
                {
                    filters: {
                        service_id: serviceId
                    }
                },
                {
                    headers: {
                        SUBTYPE: 'list',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            .then((resp) => {
                setClassifications(resp.data.fields);
                setClsLoading(false);
            });
    }, [serviceId]);

    useEffect(() => {
        if (classification) {
            axios
                .post(
                    `${API_ENDPOINT}/api/core/short-term-goal/`,
                    {
                        filters: {
                            service_id: serviceId,
                            classification_id: classification?.id
                        }
                    },
                    {
                        headers: {
                            SUBTYPE: 'list',
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((resp) => {
                    const selectedData = selectedGoals
                        .filter((item) => item.classification.name === CLASSIFICATION_BLANK)
                        .sort((x, y) => (!x.goal ? 1 : 0) - (!y.goal ? 1 : 0));
                    const selectedOrderMap = new Map(selectedData.map((item, index) => [item.id, index]));
                    const goalsList =
                        classification?.name === CLASSIFICATION_BLANK
                            ? _.orderBy(
                                  resp.data?.fields.map((goal) => ({
                                      id: goal.id,
                                      classification,
                                      goal: goals.find((item) => item.id === goal.id)?.goal ?? goal.goal
                                  })),
                                  [
                                      (item) => (selectedOrderMap.has(item.id) ? selectedOrderMap.get(item.id) : Infinity), // First sort by selectedData order
                                      (item) => item.goal ?? Infinity // Then sort by priority, treating null/undefined as the largest value
                                  ],
                                  ['asc', 'asc']
                              )
                            : resp.data?.fields.map((goal) => ({
                                  id: goal.id,
                                  classification,
                                  goal: goals.find((item) => item.id === goal.id)?.goal ?? goal.goal
                              }));

                    setGoals(goalsList);
                });
        }
    }, [serviceId, classification, selectedGoals]);

    const handleGoalsSave = async () => {
        setIsOpen(false);
        setIsEditOpen(false);
        if (classification?.name === CLASSIFICATION_BLANK) {
            const filterMarked = markedGoals.filter((item) => !(item?.classification?.name === CLASSIFICATION_BLANK && !item.goal));

            const updatedGoals = filterMarked?.map((goal) => ({
                ...goal,
                goal: goal.goal
            }));
            setSelectedGoals(updatedGoals);
            setMarkedGoals(filterMarked);
        } else {
            const updatedGoals = markedGoals.map((goal) => ({
                ...goal,
                goal: goal.goal
            }));
            setSelectedGoals(updatedGoals);
        }
    };

    const handleGoalsUpdate = async () => {
        setIsOpen(false);
        setIsEditOpen(false);

        const updatedGoals = goals.map((goal) => ({
            ...goal,
            goal: selectedGoals.find((item) => item.id === goal.id)?.goal || goal.goal
        }));
        setGoals(updatedGoals);

        const updatedSelectGoals = markedGoals.map((goal) => ({
            ...goal,
            goal: selectedGoals.find((item) => item.id === goal.id)?.goal || goal.goal
        }));
        setSelectedGoals(updatedSelectGoals);
    };

    const payload = {
        eval_id: eid,
        patient_id: patientId,
        service_id: serviceId
    };

    const { data: previousGoals, isFetching: prevGoalsLoading } = useGetCopyPreviousGoalsQuery(payload);

    const handleCopyPreviousGoals = async () => {
        if (previousGoals?.fields.length > 0) {
            const copiedGoals = previousGoals?.fields.map((goal) => ({
                id: goal.goal,
                classification: { name: goal.classification },
                goal: goal.goal_text
            }));
            setMarkedGoals(copiedGoals || []);
            setSelectedGoals(copiedGoals || []);
        } else {
            notify({ message: 'No previous goals found', severity: 'warning' });
        }
    };

    return (
        <Box>
            {(createLoading || prevGoalsLoading) && <Spinner />}
            <DialogTitle>Treatment Plan</DialogTitle>
            <IconButton
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
                onClick={() => setClose()}
            >
                <CloseIcon fontSize="15px" />
            </IconButton>

            <Divider />

            <DialogContent>
                <Grid container gap={2}>
                    <Grid item xs="12">
                        <TextField
                            required
                            value={formik.values.planName}
                            name="planName"
                            disabled={mode === 'view'}
                            onChange={formik.handleChange}
                            error={formik.touched.planName && Boolean(formik.errors.planName)}
                            helperText={formik.touched.planName && formik.errors.planName}
                            label="Plan Name"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs="12">
                        <TextField
                            required
                            value={formik.values.minPerWeek}
                            name="minPerWeek"
                            disabled={mode === 'view'}
                            onChange={formik.handleChange}
                            error={formik.touched.minPerWeek && Boolean(formik.errors.minPerWeek)}
                            helperText={formik.touched.minPerWeek && formik.errors.minPerWeek}
                            type="number"
                            label="Progress Frequency (minutes per week)"
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>

                    <Grid item xs="12" spacing={1}>
                        <AspyreTextField
                            required
                            value={formik.values.longTermGoals}
                            name="longTermGoals"
                            disabled={mode === 'view'}
                            onChange={formik.handleChange}
                            error={formik.touched.longTermGoals && Boolean(formik.errors.longTermGoals)}
                            helperText={formik.touched.longTermGoals && formik.errors.longTermGoals}
                            multiline
                            minRows={5}
                            label="Long Term Goals"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs="12">
                        <AspyreTextField
                            multiline
                            minRows={5}
                            disabled={mode === 'view'}
                            value={formik.values.impInfo}
                            name="impInfo"
                            onChange={formik.handleChange}
                            error={formik.touched.impInfo && Boolean(formik.errors.impInfo)}
                            helperText={formik.touched.impInfo && formik.errors.impInfo}
                            label="Important Patient Information"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {mode !== 'view' && (
                    <>
                        <TitleDivider
                            sx={{
                                my: 2
                            }}
                            title="Add Short Term Goals"
                        />

                        <Grid container spacing={2} my={1} alignItems="center">
                            <Grid item md={12}>
                                <Autocomplete
                                    // sx={{
                                    //     height: 40
                                    // }}
                                    fullWidth
                                    // disabled={mode !== 'edit'}
                                    disablePortal
                                    options={classifications}
                                    loading={clsLoading}
                                    onChange={(_, value) => {
                                        setClassification(value);
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => <TextField {...params} label="Classification" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs="12" md={6}>
                                <Button fullWidth variant="contained" disabled={!classification} onClick={() => setIsOpen((open) => !open)}>
                                    Add Goals
                                </Button>
                            </Grid>
                            {!goalLoading && previousGoals?.fields.length > 0 && selectedGoals.length === 0 && (
                                <Grid item xs="12" md={6}>
                                    <Button fullWidth variant="contained" onClick={handleCopyPreviousGoals}>
                                        Copy Previous Goals
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}

                {selectedGoals.length > 0 && mode !== 'view' && (
                    <>
                        <TitleDivider
                            sx={{
                                mt: 3,
                                mb: 1
                            }}
                            title="Edit Short Term Goals"
                        />

                        <Grid item md="4" display="flex" justifyContent="flex-end">
                            <Button
                                // disabled={}
                                variant="contained"
                                onClick={() => setIsEditOpen((open) => !open)}
                            >
                                Edit Selected Goals
                            </Button>
                        </Grid>
                    </>
                )}
                <>
                    {selectedGoals.length > 0 && (
                        <Grid container maxHeight="75vh" overflow="scroll" p={1.5}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: '84px' }}>Goal No</TableCell>
                                                <TableCell>Classification</TableCell>
                                                <TableCell>Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedGoals.map((goal, index) => (
                                                <TableRow key={goal.id}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{goal?.classification?.name}</TableCell>
                                                    <TableCell>{goal.goal}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                </>
                {/* )} */}

                {mode !== 'view' && (
                    <Grid sx={{ my: 2 }} container spacing={1} justifyContent="end">
                        <Grid Item>
                            <Button variant="contained" onClick={formik.handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>

            <AddShortTermiGoals
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                goals={goals}
                setGoals={setGoals}
                setMarkedGoals={setMarkedGoals}
                markedGoals={markedGoals}
                handleGoalsSave={handleGoalsSave}
            />

            <EditShortTermGoals
                isEditOpen={isEditOpen}
                setIsEditOpen={setIsEditOpen}
                selectedGoals={selectedGoals}
                setSelectedGoals={setSelectedGoals}
                setMarkedGoals={setMarkedGoals}
                markedGoals={markedGoals}
                handleGoalsUpdate={handleGoalsUpdate}
            />
        </Box>
    );
}

const AddShortTermiGoals = ({ isOpen, setIsOpen, goals, setGoals, setMarkedGoals, markedGoals, handleGoalsSave }) => {
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const selectedGoals = goals.filter((item1) => markedGoals.some((item2) => item2?.id === item1?.id));

        if (goals?.length === selectedGoals?.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [isOpen, goals, markedGoals]);

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box
                bgcolor="white"
                sx={{
                    p: 3,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80vw',
                    borderRadius: '12px'
                }}
            >
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                    }}
                    onClick={() => setIsOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item>
                        <Typography variant="h2">Short Term Goals</Typography>
                    </Grid>
                </Grid>
                {goals.length > 0 && (
                    <Grid container maxHeight="75vh" overflow="scroll" p={1.5}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setSelectAll(true);
                                                            setMarkedGoals((prev) => _.uniqBy([...prev, ...goals], 'id'));
                                                        } else {
                                                            setSelectAll(false);
                                                            setMarkedGoals((prev) =>
                                                                prev.filter((item1) => !goals.some((item2) => item2?.id === item1?.id))
                                                            );
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>Classification</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {goals.map((goal) => (
                                            <TableRow key={goal.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={Boolean(markedGoals.find((item) => item.id === goal.id))}
                                                        name={goal.id.toString()}
                                                        onChange={(event) => {
                                                            if (event.target.checked) {
                                                                setMarkedGoals((goals) => [...goals, goal]);
                                                            } else {
                                                                setMarkedGoals((goals) => goals.filter((item) => item.id !== goal.id));
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>{goal?.classification?.name}</TableCell>
                                                <TableCell sx={{ textAlignLast: 'end', width: '2px', padding: '8px 2px' }}>
                                                    {Boolean(markedGoals?.find((item) => item.id === goal.id)) &&
                                                    goal?.classification?.name !== CLASSIFICATION_BLANK ? (
                                                        <Tooltip title={goal.goal}>
                                                            <span>
                                                                <HiLightBulb style={{ width: 15, height: 15, alignSelf: 'center' }} />
                                                            </span>
                                                        </Tooltip>
                                                    ) : null}
                                                </TableCell>
                                                <TableCell>
                                                    <AspyreTextField
                                                        // disabled
                                                        multiline
                                                        // row={4}
                                                        fullWidth
                                                        // eslint-disable-next-line no-extra-boolean-cast
                                                        value={
                                                            markedGoals?.find((item) => item.id === goal.id)
                                                                ? markedGoals?.find((item) => item.id === goal.id)?.goal
                                                                : goal.goal
                                                        }
                                                        name={goal.id.toString()}
                                                        onChange={(event) => {
                                                            const updatedValue = event.target.value.trim();
                                                            // setGoals((prevGoals) =>
                                                            //     prevGoals.map((item) =>
                                                            //         item.id === goal.id ? { ...item, goal: updatedValue } : item
                                                            //     )
                                                            // );
                                                            setMarkedGoals((prevMarkedGoals) => {
                                                                const isAlreadyMarked = prevMarkedGoals.some((item) => item.id === goal.id);
                                                                if (_.isEmpty(updatedValue)) {
                                                                    return prevMarkedGoals.filter((item) => item.id !== goal.id);
                                                                }
                                                                if (isAlreadyMarked) {
                                                                    return prevMarkedGoals.map((item) =>
                                                                        item.id === goal.id ? { ...item, goal: updatedValue } : item
                                                                    );
                                                                }
                                                                if (!_.isEqual(_.trim(goal?.goal), updatedValue)) {
                                                                    return [...prevMarkedGoals, { ...goal, goal: updatedValue }];
                                                                }
                                                                return prevMarkedGoals;
                                                            });
                                                        }}
                                                        inputProps={{ maxLength: 500 }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
                <Grid container gap={4} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="outlined" onClick={() => setIsOpen(false)}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={handleGoalsSave}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default TreatmentPlanModal;

const EditShortTermGoals = ({
    isEditOpen,
    setIsEditOpen,
    selectedGoals,
    setSelectedGoals,
    setMarkedGoals,
    markedGoals,
    handleGoalsUpdate
}) => {
    const { notify } = useSnackbar();
    const [validate, { isLoading }] = useValidateTreatmentPlanGoalMutation();

    const validateGoalChange = async (event, goal) => {
        // console.log({ goal });
        if (event.target.checked) {
            setMarkedGoals((goals) => [...goals, goal]);
        } else if (goal.goalId) {
            try {
                const res = await validate({ treatmentplan_goal_id: goal.goalId }).unwrap();
                if (!res.is_used) {
                    setMarkedGoals((goals) => goals.filter((item) => item.id !== goal.id));
                }
            } catch (error) {
                console.log({ error });
                if (error.data?.is_used) {
                    notify({
                        message: 'This goal is already used in treatment plan',
                        severity: 'error'
                    });
                }
            }
        } else {
            setMarkedGoals((goals) => goals.filter((item) => item.id !== goal.id));
        }
    };

    return (
        <Modal open={isEditOpen}>
            <Box
                bgcolor="white"
                sx={{
                    p: 3,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80vw',
                    borderRadius: '12px'
                }}
            >
                {isLoading && <Spinner />}
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                    }}
                    onClick={() => setIsEditOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item>
                        <Typography variant="h2">Edit Short Term Goals</Typography>
                    </Grid>
                </Grid>
                {selectedGoals.length > 0 && (
                    <Grid container maxHeight="75vh" overflow="scroll" p={1.5}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Goal No</TableCell>
                                            <TableCell>Classification</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedGoals.map((goal) => (
                                            <TableRow key={goal.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={Boolean(markedGoals.find((item) => item.id === goal.id))}
                                                        name={goal.id.toString()}
                                                        onChange={(e) => validateGoalChange(e, goal)}
                                                    />
                                                </TableCell>
                                                <TableCell>{goal.classification.name}</TableCell>
                                                <TableCell>
                                                    <AspyreTextField
                                                        multiline
                                                        fullWidth
                                                        value={goal.goal}
                                                        name={goal.id.toString()}
                                                        onChange={(event) => {
                                                            const updatedValue = event.target.value.trim();
                                                            const index = selectedGoals?.findIndex((item) => item?.id === goal?.id);
                                                            const newGoals = [...selectedGoals];
                                                            newGoals[index].goal = event.target.value;
                                                            setSelectedGoals(newGoals);
                                                            // eslint-disable-next-line consistent-return
                                                            setMarkedGoals((prevMarkedGoals) => {
                                                                const isAlreadyMarked = prevMarkedGoals?.some(
                                                                    (item) => item?.id === goal?.id
                                                                );

                                                                if (_.isEmpty(updatedValue)) {
                                                                    return prevMarkedGoals?.filter((item) => item?.id !== goal?.id);
                                                                    // eslint-disable-next-line no-else-return
                                                                } else if (!isAlreadyMarked) {
                                                                    return [...prevMarkedGoals, { ...goal, goal: updatedValue }];
                                                                }

                                                                return prevMarkedGoals;
                                                            });
                                                        }}
                                                        inputProps={{ maxLength: 500 }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
                <Grid container justifyContent="end">
                    <Grid item>
                        <Button variant="contained" onClick={handleGoalsUpdate}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};
