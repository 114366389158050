import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import TreatmentPlanModal from './TreatmentPlanModal';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { Close, Delete, EditTwoTone } from '@mui/icons-material';
import BackAction from 'components/reusable/BackAction';
import useSnackbar from 'hooks/useSnackbar';
import { useDeleteTreatmentPlanMutation, useGetTreatmentPlansQuery, useSaveEvaluationMutation } from 'store/services/therapy/evaluation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spinner from 'ui-component/Spinner';
import NoData from 'components/reusable/NoData';
import { useConfirmation } from 'components/reusable/ConfirmationModal';

function TreatmentPlan({ setValue, evaluation }) {
    const [plans, setPlans] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [mode, setMode] = useState(null);
    const [planId, setPlanId] = useState(null);
    const { notify, notifyError } = useSnackbar();
    // const [saveEvaluation, { isLoading }] = useSaveEvaluationMutation();

    const { data: treatmentPlansData, isFetching: treatmentPlansLoading } = useGetTreatmentPlansQuery(
        {
            filters: { evaluation_id: evaluation?.id, status: 1 }
        },
        {
            skip: !evaluation
        }
    );

    useEffect(() => {
        if (treatmentPlansData) {
            setPlans(treatmentPlansData.fields);
        }
    }, [treatmentPlansData]);

    // const validationSchema = Yup.object({
    //     duration: Yup.number().required('* This field is required').min(0, 'Must be greater than 0').max(99, 'Must be less than 100')
    // });

    // const formik = useFormik({
    //     initialValues: {
    //         duration: evaluation?.duration_month || ''
    //     },
    //     validationSchema,
    //     onSubmit: async (values) => {
    //         const formData = new FormData();
    //         formData.append('id', evaluation?.id);
    //         formData.append('flag', 'treatment');
    //         formData.append('duration_month', values.duration);

    //         try {
    //             const res = await saveEvaluation({ id: evaluation?.id, payload: formData }).unwrap();
    //             console.log(res);
    //             notify({
    //                 message: res.message,
    //                 severity: 'success'
    //             });
    //         } catch (err) {
    //             console.log(err);
    //             notify({
    //                 message: err?.data.message || 'Something Went Wrong',
    //                 severity: 'error'
    //             });
    //         }
    //     }
    // });

    const [deleteTrigger, { isLoading: deleteLoading }] = useDeleteTreatmentPlanMutation();

    // const [deletePlan, setDeletePlan] = useState(null);

    const { handleClickOpen } = useConfirmation();

    const handleDeleteClick = (row) => {
        const handleConfirmDelete = async () => {
            try {
                const payload = {
                    id: row?.id
                };
                const res = await deleteTrigger(payload).unwrap();
                console.log({ res });

                notify({
                    message: 'Treatment plan deleted successfully',
                    severity: 'success'
                });
            } catch (error) {
                console.log('error', error);
                notifyError({
                    error
                });
            }
        };

        // setDeletePlan(row);
        handleClickOpen({
            title: 'Delete Treatment Plan',
            content: 'Are you sure you want to delete this treatment plan?',
            onConfirm: () => handleConfirmDelete
        });
    };

    // console.log({ deletePlan });

    return (
        <div>
            {(treatmentPlansLoading || deleteLoading) && <Spinner />}
            <Box>
                {/* {setValue && (
                    <Grid my={2} spacing={2} container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={formik.values.duration}
                                name="duration"
                                onChange={formik.handleChange}
                                error={formik.touched.duration && Boolean(formik.errors.duration)}
                                helperText={formik.touched.duration && formik.errors.duration}
                                type="number"
                                label="Evaluation Duration (Months)"
                                inputProps={{ min: 0, max: 99 }}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" type="submit" disabled={isLoading} onClick={formik.handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                )} */}
                <Grid mb={2} item xs={12}>
                    <Grid container justifyContent="end" mb={1}>
                        <Grid Item>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setModalOpen((open) => !open);
                                    setMode('create');
                                    setPlanId(null);
                                }}
                            >
                                Add New Treatment Plan
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container height="100%" justifyContent="start">
                    {plans.length === 0 && (
                        <Grid item>
                            <NoData title="No Treatment Plans Found" />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        {plans.length > 0 && (
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right">Direct Intervention&nbsp;(M / W)</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {plans.map((plan) => (
                                            <TableRow key={plan.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {plan.name}
                                                </TableCell>
                                                <TableCell align="right">{plan.intervention_min_per_week}</TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        title="View"
                                                        color="primary"
                                                        onClick={() => {
                                                            setModalOpen((open) => !open);
                                                            setMode('view');
                                                            setPlanId(plan.id);
                                                        }}
                                                    >
                                                        <VisibilityTwoToneIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        title="Edit"
                                                        color="primary"
                                                        onClick={() => {
                                                            setModalOpen((open) => !open);
                                                            setMode('edit');
                                                            setPlanId(plan.id);
                                                        }}
                                                    >
                                                        <EditTwoTone />
                                                    </IconButton>
                                                    <IconButton title="Delete" color="primary" onClick={() => handleDeleteClick(plan)}>
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>

                    {setValue && (
                        <BackAction
                            actionElement={
                                <Button variant="contained" onClick={() => setValue((value) => value + 1)}>
                                    Next
                                </Button>
                            }
                        />
                    )}
                </Grid>
            </Box>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth maxWidth="sm">
                <TreatmentPlanModal
                    eid={evaluation?.id}
                    patientId={evaluation?.patient.id}
                    serviceId={evaluation?.service}
                    planId={planId}
                    setPlanId={setPlanId}
                    mode={mode}
                    setMode={setMode}
                    setClose={() => setModalOpen(false)}
                />
            </Dialog>
        </div>
    );
}

export default TreatmentPlan;

export const EditTreatmentPlanModal = ({ evaluation, handleClose }) => {
    // console.log({ evaluation });
    const [open, setOpen] = useState(evaluation || false);

    useEffect(() => {
        setOpen(evaluation || false);
    }, [evaluation]);

    const onClose = () => {
        setOpen(false);

        if (handleClose) {
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <Close />
            </IconButton>
            <DialogTitle>Edit Treatment Plan</DialogTitle>
            <Divider />
            <DialogContent>
                <TreatmentPlan evaluation={evaluation} />
            </DialogContent>
        </Dialog>
    );
};
