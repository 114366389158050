/* eslint-disable  */

import { api } from '../api';

export const itpApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getITPList: builder.query({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ITPList']
        }),
        getITPDetails: builder.query({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['ITPDetails']
        }),
        addUpdateITP: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.id ? 'update' : 'create'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList', 'ITPDetails'] : [])
        }),
        deleteITP: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList'] : [])
        }),
        itpSign: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'sign'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList', 'ITPDetails', 'UnsignedReportsList'] : [])
        }),
        requestSign: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'notify_therpist'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList', 'ITPDetails'] : [])
        }),
        closeITP: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'close_itp'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList', 'ITPDetails'] : [])
        }),
        unlockITP: builder.mutation({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unlock'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['ITPList', 'ITPDetails'] : [])
        }),
        getItpHistory: builder.query({
            query: (payload) => ({
                url: '/api/itp/itp/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'activity'
                }
            })
            // providesTags: ['ITPHistory']
        }),

        attendanceCheckinCheckout: builder.mutation({
            query: ({ payload, checkin }) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: checkin === 'checkin' ? 'studcheckin' : checkin === 'checkout' ? 'studcheckout' : 'studtcont'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionDetails'] : [])
        }),
        therapyCheckinCheckout: builder.mutation({
            query: ({ payload, checkin }) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: checkin ? 'studthrpyin' : 'studthrpyout'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionDetails'] : [])
        }),

        getTherapyHistory: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'studthrpyhistory'
                }
            })
        }),
        updateTherapyHistory: builder.mutation({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'studthrpyhistoryupdate'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionDetails'] : [])
        }),

        getSwitchClassrooms: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ClassroomList']
        }),

        lockUnlockSession: builder.mutation({
            query: (payload) => ({
                url: 'api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'lock'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionDetails', 'SessionList'] : [])
        }),

        // session goals
        getSessionGoals: builder.query({
            query: (payload) => ({
                url: 'api/classroom/student-goals/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'lstsesngls'
                }
            }),
            providesTags: ['SessionGoals']
        }),
        addStudentGoalsResult: builder.mutation({
            query: (payload) => ({
                url: 'api/classroom/student-goals/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'adstdgolrslt'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionGoals'] : [])
        }),
        getSessionHistory: builder.query({
            query: (payload) => ({
                url: 'api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'activity'
                }
            }),
            invalidatesTags: ['SessionHistory']
        }),
        getPdcSessionServiceAvailed: builder.query({
            query: (payload) => ({
                url: 'api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'serviceavailed'
                }
            })
        }),
        getSessionStudents: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'studlst'
                }
            }),
            providesTags: ['SessionStudentList']
        }),
        changeStudentAttendance: builder.mutation({
            query: ({ payload, present }) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: present ? 'pdcattendance' : 'pdcpickup'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['StudentList', 'SessionStudentList', 'SessionDetails'] : [])
        }),
        startSession: builder.mutation({
            query: (payload) => ({
                url: 'api/classroom/classroom-pdc-session/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'checkincheckout'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['SessionList', 'SessionDetails'] : [])
        }),
        getEarlyDismissalReasons: builder.query({
            query: (payload) => ({
                url: 'api/core/early-dismiss-reason/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
        })
    })
});

export const {
    useGetITPListQuery,
    useGetITPDetailsQuery,
    useAddUpdateITPMutation,
    useDeleteITPMutation,
    useItpSignMutation,
    useRequestSignMutation,
    useCloseITPMutation,
    useUnlockITPMutation,
    useLazyGetItpHistoryQuery,

    useGetTherapyHistoryQuery,
    useLazyGetTherapyHistoryQuery,
    useUpdateTherapyHistoryMutation,
    useAttendanceCheckinCheckoutMutation,
    useTherapyCheckinCheckoutMutation,
    useGetSwitchClassroomsQuery,

    useLockUnlockSessionMutation,
    useGetSessionGoalsQuery,
    useAddStudentGoalsResultMutation,
    useGetSessionHistoryQuery,
    useLazyGetSessionHistoryQuery,
    useGetPdcSessionServiceAvailedQuery,
    useGetSessionStudentsQuery,
    useChangeStudentAttendanceMutation,
    useStartSessionMutation,
    useGetEarlyDismissalReasonsQuery
} = itpApi;
