import React, { memo, useState } from 'react';
import { Box, Button, Divider, IconButton, Popover, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Close, DoneAll, Notifications, NotificationsPaused } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { GLOBAL_DATE_FORMAT, NOTIFICATION_PANEL_DIMENSIONS } from 'constant';
import PropTypes from 'prop-types';
import { useGetNotificationsQuery, useUpdateNotificationsMutation } from 'store/services/notification';
import { convertToLocal24 } from 'utils/time-conversion';
import NotificationDrawer from './Drawer';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { encriptId } from 'utils/encription';

const handleNotificationTypeReroute = (notification, navigate) => {
    if (notification) {
        const { target_type: targetType } = notification;

        switch (targetType) {
            case 'EMP_LISTING': {
                navigate('/settings/employee', { state: notification });
                break;
            }
            case 'ACCOUNT_SETTINGS': {
                navigate('/account-profile');
                break;
            }
            case 'EVAL_EXPIRATION_ST': {
                navigate('/therapy/st/evaluation', { state: notification });
                break;
            }
            case 'EVAL_EXPIRATION_PT': {
                navigate('/therapy/pt/evaluation', { state: notification });
                break;
            }
            case 'EVAL_EXPIRATION_OT': {
                navigate('/therapy/ot/evaluation', { state: notification });
                break;
            }
            case 'EVAL_EXPIRATION_DT': {
                navigate('/therapy/dt/evaluation', { state: notification });
                break;
            }
            case 'NOTIFICATION_UNSIGNED_COUNTS_EVAL': {
                navigate('/reports/unsigned-report', { state: notification });
                break;
            }
            case 'NOTIFICATION_UNSIGNED_COUNTS_EVAL_SUPERVISOR': {
                navigate('/reports/unsigned-report', { state: notification });
                break;
            }
            case 'NOTIFICATION_UNSIGNED_COUNTS_TREATMENT': {
                navigate('/reports/unsigned-report', { state: notification });
                break;
            }
            case 'NOTIFICATION_UNSIGNED_COUNTS_TREATMENT_SUPERVISOR': {
                navigate('/reports/unsigned-report', { state: notification });
                break;
            }
            case 'NOTIFICATION_UNSIGNED_COUNTS_ACTIVITY': {
                navigate('/reports/unsigned-report', { state: notification });
                break;
            }
            case 'NEW_EMAIL': {
                navigate('/mail', { state: notification });
                break;
            }
            case 'PROFILE_DOWNLOAD': {
                navigate(`/patient/document/downloads`, { state: notification });
                break;
            }
            case 'NOTIFICATION_ITP': {
                navigate(`/pdc/itp/edit/${encriptId(notification.notification_details?.[0])}`, { state: notification });
                break;
            }
            case 'NOTIFICATION_ANNUAL_REVIEW': {
                navigate(`/therapy/itp/edit/${encriptId(notification.notification_details?.[0])}`, { state: notification });
                break;
            }
            case 'LOCATION EDIT': {
                navigate(`/settings/edit-location/${encriptId(notification.notification_details?.[0])}`, { state: notification });
                break;
            }
            case 'NOTIFICATION_THERAPY_PROFILE_DOWNLOAD': {
                navigate('treat-eval/document/downloads', { state: notification });
                break;
            }
            default:
        }
    }
};

function NotificationCard(props) {
    const { id, final, read, message, date, type, onClick, ...rest } = props;
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                py: 1,
                borderBottom: !final && `1px solid ${theme.palette.grey[300]}`,
                cursor: 'pointer',
                '&:hover': {
                    background: 'rgba(0, 0, 0, 0.025)'
                }
            }}
            onClick={() => onClick(id)}
            {...rest}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 80,
                        height: 80,
                        borderRadius: 80,
                        background: theme.palette.primary.light,
                        flexShrink: 0
                    }}
                >
                    <span
                        style={{
                            fontSize: 30,
                            fontWeight: 900,
                            textTransform: 'capitalize'
                        }}
                    >
                        {type?.charAt(0) || 'N'}
                    </span>
                </Box>
                <Stack spacing={1}>
                    <Typography sx={{ '&:hover': { color: (theme) => theme.palette.primary.main } }}>{message}</Typography>
                    <Typography variant="subtitle2">{convertToLocal24(date, GLOBAL_DATE_FORMAT)}</Typography>
                </Stack>
            </Stack>
            {!read && (
                <span
                    style={{
                        marginLeft: 20,
                        height: 10,
                        width: 10,
                        borderRadius: 10,
                        background: theme.palette.primary.main,
                        flexShrink: 0
                    }}
                />
            )}
        </Stack>
    );
}

function CTabPanel(props) {
    const { children, value, index, ...rest } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...rest} style={{ height: '100%' }}>
            {value === index && <Box sx={{ height: '100%', py: 1, pr: 1 }}>{children}</Box>}
        </div>
    );
}

function Notification() {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawer, setDrawer] = useState(null);
    const [value, setValue] = useState(0);

    const navigate = useNavigate();

    const [readNotification] = useUpdateNotificationsMutation();
    const {
        data: notifications,
        isError,
        refetch
    } = useGetNotificationsQuery(
        {
            filters: { ...(user?.client_id && { client_id: user.client_id }) },
            pagination: { current_page: 1 }
        },
        { refetchOnMountOrArgChange: true }
    );

    const handleNotificationOpen = (e) => {
        refetch();
        setAnchorEl(e.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (e, val) => {
        setValue(val);
    };

    const handleNotificationClick = (id) => {
        readNotification({
            notification_ids: [id]
        });

        handleNotificationClose();
        const not = notifications?.fields?.filter((i) => i.id === id)?.[0];
        handleNotificationTypeReroute(not, navigate);
    };

    const handleDrawerOpen = () => {
        handleNotificationClose();
        setDrawer(true);
    };

    const handleDrawerClose = () => {
        setDrawer(false);
    };

    const handleMarkAllRead = () => {
        const unseen = notifications?.fields?.filter((n) => !n.is_read);
        readNotification({
            ...(unseen?.length > 0 && {
                notification_ids: unseen?.map((n) => n.id) || []
            })
        });
    };

    return (
        <>
            <IconButton
                color="primary"
                onClick={handleNotificationOpen}
                sx={{
                    position: 'relative',
                    background: '#e7e7e7',
                    borderRadius: '8px',
                    height: 34,
                    width: 34,
                    p: 1,
                    '&:hover': {
                        background: '#1B357C',
                        color: '#fff'
                    }
                }}
            >
                <Notifications />
                {notifications?.fields?.filter((n) => !n.is_read).length > 0 && (
                    <span
                        style={{
                            position: 'absolute',
                            height: 10,
                            width: 10,
                            background: 'green',
                            borderRadius: '50%',
                            top: 9,
                            right: 9
                        }}
                    />
                )}
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleNotificationClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{
                        background: (theme) => theme.palette.common.white,
                        height: '100%',
                        width: NOTIFICATION_PANEL_DIMENSIONS[0],
                        maxHeight: NOTIFICATION_PANEL_DIMENSIONS[1],
                        flexDirection: 'column'
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 2, py: 1 }}>
                        <Typography variant="h4">Notifications</Typography>
                        <IconButton onClick={handleNotificationClose}>
                            <Close sx={{ fontSize: 24 }} />
                        </IconButton>
                    </Stack>
                    <Box sx={{ pl: 2, flexGrow: 1 }}>
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="View all" value={0} sx={{ fontSize: 16 }} />
                            {/* <Tab label="Mentions" value={1} sx={{ fontSize: 16 }} /> */}
                        </Tabs>
                        <Box
                            sx={{
                                height: '100%',
                                maxHeight: NOTIFICATION_PANEL_DIMENSIONS[1] - 165,
                                overflowY: 'auto'
                            }}
                        >
                            <CTabPanel value={value} index={0}>
                                <Stack spacing={1} sx={{ mr: 1 }}>
                                    {(notifications?.fields?.length < 1 || isError) && (
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <NotificationsPaused
                                                sx={{
                                                    fontSize: 150,
                                                    color: (theme) => theme.palette.grey[300]
                                                }}
                                            />
                                            <Typography variant="subtitle2" sx={{ fontSize: 20 }}>
                                                You have no notifications yet
                                            </Typography>
                                        </Box>
                                    )}
                                    {notifications?.fields?.map((n, idx) => (
                                        <NotificationCard
                                            key={n.id}
                                            id={n.id}
                                            final={idx === notifications?.fields?.length - 1}
                                            read={n?.is_read}
                                            message={n?.message || ''}
                                            date={n?.created_on}
                                            type={n?.target_type}
                                            onClick={handleNotificationClick}
                                        />
                                    ))}
                                </Stack>
                            </CTabPanel>
                        </Box>
                    </Box>
                    <Divider />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
                        <Button variant="text" onClick={handleMarkAllRead}>
                            <DoneAll sx={{ fontSize: 24, mr: 1 }} />
                            Mark all as read
                        </Button>
                        <Button variant="contained" size="small" onClick={handleDrawerOpen}>
                            View all
                        </Button>
                    </Stack>
                </Box>
            </Popover>
            <NotificationDrawer open={drawer} onClose={handleDrawerClose} onClick={handleNotificationClick} />
        </>
    );
}

NotificationCard.propTypes = {
    id: PropTypes.number,
    final: PropTypes.bool,
    read: PropTypes.bool,
    message: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func
};

CTabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number
};

export default memo(Notification);
