import { api } from '../api';

export const evaluationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getEvaluations: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['EvaluationList']
        }),
        getEvaluationDetails: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'detail'
                }
            }),
            providesTags: ['EvaluationDetails']
        }),
        getEvaluationDuration: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list_evaluation_duration'
                }
            }),
            providesTags: ['EvaluationList']
        }),
        updateEvaluationDuration: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'update_evaluation_duration'
                }
            }),
            invalidatesTags: ['EvaluationDetails']
        }),
        saveEvaluation: builder.mutation({
            query: ({ id, payload }) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: id ? 'update' : 'create'
                }
            }),
            invalidatesTags: ['EvaluationList', 'EvaluationDetails', 'ScheduleList', 'EventDetails', 'TharapistScheduleList']
        }),
        savePlanGoals: builder.mutation({
            query: (payload) => ({
                url: 'api/therapy/eval/treatment-plan/goal/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'create'
                }
            }),
            invalidatesTags: ['EvaluationDetails']
        }),
        uploadEvaluationDocument: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'uploaddoc'
                }
            })
            // invalidatesTags: (result, error, arg) => (result ? ['EvaluationDetails'] : [])
        }),
        deleteEvaluation: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: ['EvaluationList']
        }),
        signEvaluation: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'sign'
                }
            }),
            invalidatesTags: ['EvaluationList', 'EvaluationDetails', 'UnsignedReportsList']
        }),
        unlockEvaluation: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unlock'
                }
            }),
            invalidatesTags: ['EvaluationList', 'EvaluationDetails']
        }),
        getAssessments: builder.query({
            query: (payload) => ({
                url: '/api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'evalassessment'
                }
            }),
            providesTags: ['AssessmentList']
        }),
        getEvaluationHistory: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/activity/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            invalidatesTags: ['EvaluationHistory']
        }),
        getGeneralAnalysis: builder.query({
            query: (payload) => ({
                url: '/api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'evalgeneral'
                }
            })
        }),
        getTreatmentPlans: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['TreatmentPlanList']
        }),
        getTreatmentPlanGoals: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/goal/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['TreatmentPlanGoals']
        }),
        validateTreatmentPlanGoal: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/goal/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'goal_validation'
                }
            })
            // providesTags: ['TreatmentPlanGoals']
        }),
        createUpdateTreatmentPlan: builder.mutation({
            query: ({ id, payload }) => ({
                url: '/api/therapy/eval/treatment-plan/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: id ? 'update' : 'create'
                }
            }),
            invalidatesTags: ['TreatmentPlanList', 'EvaluationList', 'EvaluationDetails']
        }),
        deleteTreatmentPlan: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: ['TreatmentPlanList', 'EvaluationList', 'EvaluationDetails']
        }),
        getTreatmentGoal: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/goal/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['TreatmentPlanList']
        }),
        getCopyPreviousGoals: builder.query({
            query: (payload) => ({
                url: '/api/therapy/eval/treatment-plan/goal/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list_previous_goals'
                }
            }),
            providesTags: ['CopyPreviousGoals', 'TreatmentPlanList', 'EvaluationList']
        })
    })
});

export const {
    useGetEvaluationsQuery,
    useSaveEvaluationMutation,
    useSavePlanGoalsMutation,
    useGetEvaluationDetailsQuery,
    useLazyGetEvaluationDurationQuery,
    useUpdateEvaluationDurationMutation,
    useUploadEvaluationDocumentMutation,
    useDeleteEvaluationMutation,
    useSignEvaluationMutation,
    useUnlockEvaluationMutation,
    useGetAssessmentsQuery,
    useLazyGetEvaluationHistoryQuery,
    useGetGeneralAnalysisQuery,
    useGetTreatmentPlansQuery,
    useGetTreatmentGoalQuery,
    useValidateTreatmentPlanGoalMutation,
    useGetTreatmentPlanGoalsQuery,
    useCreateUpdateTreatmentPlanMutation,
    useDeleteTreatmentPlanMutation,
    useGetCopyPreviousGoalsQuery,
    useLazyGetCopyPreviousGoalsQuery,
    useLazyGetEvaluationsQuery,
    useLazyGetEvaluationDetailsQuery,
} = evaluationApi;
