import { useSelector } from 'react-redux';
import useAuth from './useAuth';
import { CLIENT_CYNERGY_ID, PHYSICIAN_CLIENT } from 'constant';

// ==============================|| AUTH HOOKS ||============================== //

const usePermission = () => {
    const { user } = useAuth();

    const userPermissions = user?.permissions?.map((item) => item.code);

    const hasPermission = (permissions) => {
        if (permissions?.length > 0) {
            return userPermissions?.some((item) => permissions.includes(item));
        }
        return true;
    };

    const userRoles = user?.roles?.map((item) => item.role);

    const hasRole = (roles) => userRoles?.some((item) => roles.includes(item));

    const isTherapist = hasRole(['Therapist', 'Assistant Therapist']);

    const isTherapistOnly = user?.roles?.[0]?.role === 'Therapist';

    const isPhysician = hasRole(['Physician']) || user?.client_id === PHYSICIAN_CLIENT;

    const isSuperAdmin = user?.is_superuser;

    const isSuperVisor = user?.roles?.[0]?.role === 'Therapist' && user?.supervisee?.length > 0;

    const isCynergyClient = CLIENT_CYNERGY_ID === user?.client_id;

    const isSameAsMasterAdmin = user?.is_same_as_masteradmin;

    const hasOwenerPermission = (ownerId) => user?.emp_id === ownerId;

    const isServiceAvailable = (service) =>
        service ? user?.services?.map((val) => val?.service_code)?.includes(service?.toUpperCase()) : false;

    const hasServiceAvailable = (service) => {
        if (typeof service === 'object' && service?.length > 0) {
            return service?.some((item) => user?.services?.map((val) => val?.service_code)?.includes(item?.toUpperCase()));
        }
        if (typeof service === 'string') {
            return service ? user?.services?.map((val) => val?.service_code)?.includes(service?.toUpperCase()) : false;
        }
        return false;
    };

    return {
        userPermissions,
        hasPermission,
        userRoles,
        hasRole,
        isTherapist,
        isTherapistOnly,
        isSuperVisor,
        isPhysician,
        isSuperAdmin,
        isCynergyClient,
        isSameAsMasterAdmin,
        hasOwenerPermission,
        isServiceAvailable,
        hasServiceAvailable
    };
};

export default usePermission;
