import { api } from './api';

export const logsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createLogs: builder.mutation({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'CREATE'
                }
            }),
            invalidatesTags: (result, error) => (error ? [] : ['LogsList', 'LogsListCount'])
        }),
        updateLogs: builder.mutation({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'UPDATE'
                }
            }),
            invalidatesTags: (result, error) => (error ? [] : ['LogsList', 'LogsListCount', 'UnsignedReportsList'])
        }),
        listLogs: builder.query({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['LogsList']
        }),
        totalCountLogs: builder.query({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['LogsListCount']
        }),
        downloadLogsList: builder.query({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                },
                responseHandler: (res) =>
                    res.blob().then((blob) => ({
                        blob,
                        fileName: res.headers.get('content-disposition').split('filename=')[1],
                        type: res.headers.get('content-type')
                    }))
            })
        }),
        deleteLogs: builder.mutation({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'REMOVE'
                }
            }),
            invalidatesTags: (result, error) => (error ? [] : ['LogsList', 'LogsListCount'])
        }),
        getActivityLogHistory: builder.query({
            query: (payload) => ({
                url: 'api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'ACTIVITY'
                }
            }),
            invalidatesTags: ['ActivityLogHistory']
        }),
        listLogTherapist: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listtherapist'
                }
            })
        }),
        activityLogSign: builder.mutation({
            query: (payload) => ({
                url: '/api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'esign'
                }
            }),
            invalidatesTags: (result, error, arg) => (result ? ['LogsList', 'LogsListCount', 'UnsignedReportsList'] : [])
        }),
        unlockActivityLog: builder.mutation({
            query: (payload) => ({
                url: '/api/activity-log/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unlock'
                }
            }),
            invalidatesTags: (result, error) => (error ? [] : ['LogsList', 'LogsListCount'])
        }),
        downloadFile: builder.mutation({
            query: (payload) => ({
                url: 'api/core/download/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'download'
                },
                responseHandler: (res) =>
                    res.blob().then((blob) => ({
                        blob,
                        fileName: res.headers.get('content-disposition').split('filename=')[1],
                        type: res.headers.get('content-type')
                    }))
            }),
            invalidatesTags: ['FileDownload']
        }),
        viewFile: builder.query({
            query: (payload) => ({
                url: 'api/core/document/',
                method: 'POST',
                responseType: 'blob',
                body: payload,
                headers: {
                    SUBTYPE: 'view'
                },
                responseHandler: (res) =>
                    res.blob().then((blob) => ({
                        blob,
                        type: res.headers.get('content-type')
                    }))
            }),
            providesTags: ['FileDownload']
        })
    })
});

export const {
    useCreateLogsMutation,
    useListLogsQuery,
    useLazyListLogsQuery,
    useTotalCountLogsQuery,
    useLazyTotalCountLogsQuery,
    useLazyDownloadLogsListQuery,
    useListLogTherapistQuery,
    useDeleteLogsMutation,
    useGetActivityLogHistoryQuery,
    useLazyGetActivityLogHistoryQuery,
    useUpdateLogsMutation,
    useActivityLogSignMutation,
    useUnlockActivityLogMutation,
    useDownloadFileMutation,
    useLazyViewFileQuery,
    useViewFileQuery
} = logsApi;
