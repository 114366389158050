/* eslint-disable */
import * as React from 'react';
import { Grid, Typography, Popover, Chip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useGetPreviousBillingDetailQuery } from 'store/services/patient';
import { Stack } from '@mui/system';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';

export default function BillingView({ billingId, progressNoteId }) {
    const [open, setOpen] = React.useState(false);

    const [primaryPreferedPayer, setPrimaryPreferedPayer] = React.useState(null);
    const [secondPreferedPayer, setSecondPreferedPayer] = React.useState(null);
    const [thirdPreferedPayer, setThirdPreferedPayer] = React.useState(null);

    const [primaryPayorType, setPrimaryPayorType] = React.useState(null);
    const [secondaryPayorType, setSecondaryPayorType] = React.useState(null);
    const [territiaryPayorType, setTerritiaryPayorType] = React.useState(null);

    const [primaryStatus, setPrimaryStatus] = useState('');
    const [secondStatus, setSecondStatus] = useState('');
    const [thirdStatus, setThirdStatus] = useState('');

    const [modifier, setModifier] = React.useState(null);

    const [is504, setIs504] = React.useState(null);
    const [isCompensatory, setIsCompensatory] = React.useState(null);

    const {
        data: { fields: billingDetail } = {},
        error: billingError,
        isLoading: billingLoading
    } = useGetPreviousBillingDetailQuery(
        {
            id: billingId,
            progress_id: progressNoteId
        },
        { skip: !billingId || !open }
    );

    const handleClickOpen = (e) => {
        setOpen(e.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    function isEmpty(obj) {
        if (!obj) return true; // null or undefined
        if (Array.isArray(obj)) return obj.length === 0; // Empty array
        return Object.keys(obj).length === 0; // Standard objects
    }

    const getStatus = (isActive, isDeleted) => {
        let status = '';
        if ((_.isNull(isActive) || _.isUndefined(isActive)) && (_.isNull(isDeleted) || _.isUndefined(isDeleted))) {
            status = '';
        } else if (isActive && isDeleted) {
            status = 'Deleted';
        } else if (!isActive && isDeleted) {
            status = 'Deleted';
        } else if (!isActive && !isDeleted) {
            status = 'Inactive';
        } else if (isActive && !isDeleted) {
            status = '';
        } else {
            status = '';
        }
        return status;
    };

    const showStatus = (status) => {
        const bgColor = status === 'Inactive' ? '#fda300' : '#d9534f';
        if (!status) {
            return '';
        } else {
            return (
                <Tooltip title="Inactive">
                    <Chip
                        size="small"
                        label={status}
                        sx={{
                            color: (theme) => theme.palette.common.white,
                            background: bgColor
                        }}
                    />
                </Tooltip>
            );
        }
    };

    useEffect(() => {
        if (billingDetail) {
            const primaryNull =
                isEmpty(billingDetail.primary_payer_name) && isEmpty(billingDetail.primary_payer_type_name) ? null : 'Other';
            const secondaryNull =
                isEmpty(billingDetail.secondary_payer_name) && isEmpty(billingDetail.secondary_payer_type_name) ? null : 'Other';
            const territiaryNull =
                isEmpty(billingDetail.tertiary_payer_name) && isEmpty(billingDetail.tertiary_payer_type_name) ? null : 'Other';

            setPrimaryPreferedPayer(billingDetail.primary_payer_name || primaryNull);
            setSecondPreferedPayer(billingDetail.secondary_payer_name || secondaryNull);
            setThirdPreferedPayer(billingDetail.tertiary_payer_name || territiaryNull);

            setPrimaryPayorType(billingDetail.primary_payer_type_name);
            setSecondaryPayorType(billingDetail.secondary_payer_type_name);
            setTerritiaryPayorType(billingDetail.tertiary_payer_type_name);

            setPrimaryStatus(getStatus(billingDetail?.primary_payer_is_active, billingDetail?.primary_payer_is_deleted));
            setSecondStatus(getStatus(billingDetail?.secondary_payer_is_active, billingDetail?.secondary_payer_is_deleted));
            setThirdStatus(getStatus(billingDetail?.tertiary_payer_is_active, billingDetail?.tertiary_payer_is_deleted));

            setModifier(billingDetail.modifier_name);

            setIsCompensatory(billingDetail?.is_compensatory);
            setIs504(billingDetail?.is_504);
        } else {
            setPrimaryPreferedPayer(null);
            setSecondPreferedPayer(null);
            setThirdPreferedPayer(null);

            setPrimaryPayorType(null);
            setSecondaryPayorType(null);
            setTerritiaryPayorType(null);

            setPrimaryStatus('');
            setSecondStatus('');
            setThirdStatus('');

            setModifier(null);
            setIsCompensatory(null);
            setIs504(null);
        }
    }, [billingDetail]);

    const openInfo = Boolean(open);
    const id = openInfo ? 'simple-popover' : undefined;

    return (
        <>
            {billingId && (
                <Tooltip>
                    <Info
                        onClick={(e) => handleClickOpen(e)}
                        sx={{
                            cursor: 'pointer',
                            height: 20,
                            width: 20,
                            color: (theme) => theme.palette.grey[400]
                        }}
                    />
                </Tooltip>
            )}
            <Popover
                id={id}
                open={openInfo}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <>
                    <Stack sx={{ padding: '16px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Primary:</Typography>
                                <Stack sx={{ display: 'block' }}>
                                    <Typography>{primaryPreferedPayer}</Typography>
                                    {primaryPreferedPayer && showStatus(primaryStatus)}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Secondary:</Typography>
                                <Stack sx={{ display: 'block' }}>
                                    <Typography>{secondPreferedPayer}</Typography>
                                    {secondPreferedPayer && showStatus(secondStatus)}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Tertiary:</Typography>
                                <Stack sx={{ display: 'block' }}>
                                    <Typography>{thirdPreferedPayer}</Typography>
                                    {thirdPreferedPayer && showStatus(thirdStatus)}
                                </Stack>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Primary Payor Type:</Typography>
                                <Typography sx={{ display: 'flex' }}>{primaryPayorType}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Secondary Payor Type:</Typography>
                                <Typography sx={{ display: 'flex' }}>{secondaryPayorType}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Tertiary Payor Type:</Typography>
                                <Typography sx={{ display: 'flex' }}>{territiaryPayorType}</Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Modifier:</Typography>
                                <Typography sx={{ display: 'flex' }}>{modifier}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>Compensatory :</Typography>
                                <Typography sx={{ display: 'flex' }}>{isCompensatory ? 'Yes' : 'No'}</Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography style={{ fontWeight: '500', color: '#000', display: 'flex' }}>504 Plan:</Typography>
                                <Typography sx={{ display: 'flex' }}>{is504 ? 'Yes' : 'No'}</Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                    </Stack>
                </>
            </Popover>
        </>
    );
}
