import React, { useEffect } from 'react';
import { Box, Tabs, Tab, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Link, useSearchParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

export function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    };
}

export const CTabs = (props) => {
    const theme = useTheme();
    const { children, value, onChange, ref, sx, ...rest } = props;

    // const [searchParams, setSearchParams] = useSearchParams();
    // const tab = parseInt(searchParams.get('tab'), 10) || 0;
    // console.log({ searchParams, tab });

    // useEffect(() => {
    //     onChange(tab, tab);
    // }, [tab]);

    return (
        <Tabs
            value={value}
            onChange={(event, newValue) => {
                // console.log({ event, newValue });
                onChange(event, newValue);
                // setSearchParams({ tab: newValue }, { replace: true });
            }}
            ref={ref}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            sx={{
                // mb: 2,
                '& button': {
                    // py: 1,
                    // px: 1,
                    minHeight: 'auto',
                    minWidth: 10,
                    mr: 2.25,
                    color: theme.palette.grey[600],
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px'
                },
                '& a.Mui-selected': {
                    color: '#6045b7'
                },
                '& .MuiTabs-indicator': {
                    bottom: 2,
                    backgroundColor: '#6045b7'
                },
                '& a > svg': {
                    marginBottom: '0px !important',
                    mr: 1.25,
                    color: '#6045b7'
                },
                ...sx
            }}
            {...rest}
        >
            {children}
        </Tabs>
    );
};

export const CTab = (props) => {
    const { children, label, value, ...rest } = props;

    return (
        <>
            <Tab
                // component={Link}
                // to="#"
                label={label}
                value={value}
                {...a11yProps(value)}
                {...rest}
            >
                {children}
            </Tab>
        </>
    );
};

export default function CTabPanel(props) {
    const { children, value, index, keepMounted, ...rest } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
            {(value === index || keepMounted) && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}
