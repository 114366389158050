/* eslint-disable no-else-return */
import { GLOBAL_DATE_FORMAT, GLOBAL_DATE_TIME_FORMAT, PAYLOAD_DATE_TIME_FORMAT } from 'constant';
import moment from 'moment';
import { instanceOf } from 'prop-types';

export const convertToUTC = (date) => {
    const utc = moment(new Date(date)).utc().format('YYYY-MM-DDT hh:mm A');
    console.log(utc, '- now in UTC');
    return utc;
};

export const convertToLocal = (date) => {
    if (date) {
        const local = moment.utc(date).local().format('YYYY-MM-DDT hh:mm A');
        console.log(local, '- UTC now to local');
        return local;
    } else return '';
};

export const convertToUTC24 = (date, format) => {
    if (date) {
        const utc = moment(new Date(date))
            .utc()
            .format(format || 'YYYY-MM-DD hh:mm');
        return utc;
    } else return '';
};

export const convertToLocal24 = (date, format) => {
    if (date) {
        const local = moment
            .utc(date)
            .local()
            .format(format || 'YYYY-MM-DD hh:mm');
        return local;
    } else return '';
};

export const convertToStartDayUTC = (date, format) => {
    if (date) {
        const utc = moment(new Date(date))
            .startOf('day')
            .utc()
            .format(format || PAYLOAD_DATE_TIME_FORMAT);
        return utc;
    } else return '';
};

export const convertToEndDayUTC = (date, format) => {
    if (date) {
        const utc = moment(new Date(date))
            .endOf('day')
            .utc()
            .format(format || PAYLOAD_DATE_TIME_FORMAT);
        return utc;
    } else return '';
};

export const convertToFormat = (date, format) => {
    let ds = '';
    if (!date) return ds;
    if (typeof date === 'string') {
        ds = moment.utc(date).format(format || 'YYYY-MM-DD hh:mm');
    } else {
        ds = moment.utc(new Date(date)).format(format || 'YYYY-MM-DD hh:mm');
    }
    return ds;
};

export const convertMinToHrs = (duration, num = false) => {
    if (typeof duration !== 'number' || duration < 0) {
        return 'Invalid duration';
    }

    // if num is true return the number of hours
    if (num) {
        const hours = duration / 60;
        return hours;
    }

    if (duration >= 60) {
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        if (hours === 1 && minutes === 0) {
            return '1 Hr';
        } else if (hours === 1 && minutes > 0) {
            return `1 Hr ${minutes} Min`;
        } else if (hours > 1 && minutes === 0) {
            return `${hours} Hr`;
        } else {
            return `${hours} Hr ${minutes} Min`;
        }
    } else {
        return `${duration} Min`;
    }
};

export const getTimeDeltaBasedOnType = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'today': {
            const now = new Date();

            return [now, now];
        }
        case 'week': {
            const now = new Date();
            const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
            const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));

            return [startOfWeek, endOfWeek];
        }
        case 'month': {
            const now = new Date();

            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            return [startOfMonth, endOfMonth];
        }
    }

    return [];
};

export const utcToLocal = (date, format = GLOBAL_DATE_FORMAT) => {
    if (!date) return '';
    // if (instanceOf(date, 'Date')) date = date.getTime();
    const local = moment.utc(date).local().format(format);
    return local;
};

export const localToUTC = (date, format = PAYLOAD_DATE_TIME_FORMAT) => {
    if (!date) return '';
    // if (instanceOf(date, 'Date')) date = date.getTime();
    const local = moment(new Date(date)).utc().format(format);
    return local;
};

export const changeFormat = (date, format) => {
    let ds = '';
    if (!date) return ds;
    if (typeof date === 'string') {
        ds = moment(date).format(format || 'YYYY-MM-DD hh:mm');
    } else {
        ds = moment(new Date(date)).format(format || 'YYYY-MM-DD hh:mm');
    }
    return ds;
};
