/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Stack,
    Switch,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    CircularProgress
} from '@mui/material';
import axios from 'axios';
import parse from 'html-react-parser';
import { useReactToPrint } from 'react-to-print';

import moment from 'moment';

import CloseIcon from '@mui/icons-material/Close';

import Spinner from 'ui-component/Spinner';
import { useGetEvaluationDetailsQuery } from 'store/services/therapy/evaluation';
import { GLOBAL_DATE_FORMAT, SERVICES, THERAPIES, getServiceNameById } from 'constant';
import useAuth from 'hooks/useAuth';
import { useGetClientLogoQuery } from 'store/services/client';
import SignModal, { SIGN_VISIT_TYPE } from 'components/therapy/SignModal';
import { useGetPatientDetailsQuery } from 'store/services/patient';
import { getEmployeeName } from '.';
import { Box } from '@mui/system';
import ViewBilling from 'components/therapy/ViewBillingDetails';

export default function EvaluationViewModal({ evaluationId, service, handleClose, isViewOnly = false }) {
    const open = Boolean(evaluationId);

    const payload = {
        id: evaluationId,
        ...(service && { service_id: service })
    };

    const { data, isLoading, isFetching } = useGetEvaluationDetailsQuery(payload, {
        skip: !evaluationId
    });

    console.log('-----loaded--------', data);

    if (isLoading || isFetching) return <Spinner />;

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="xl" onClose={handleClose}>
                <DialogTitle sx={{ py: 1 }}>
                    <Typography variant="h2" gutterBottom>
                        Evaluation Details
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 20,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ m: 0, pt: 1 }}>
                    {data && <EvaluationView evaluation={data?.fields} isViewOnly={isViewOnly} />}
                </DialogContent>
            </Dialog>
        </>
    );
}

// function getTextareaContentHeight(textarea) {
//     const tempDiv = document.createElement('div');
//     tempDiv.style.cssText = `
//       font-family: ${getComputedStyle(textarea).fontFamily};
//       font-size: ${getComputedStyle(textarea).fontSize};
//       line-height: ${getComputedStyle(textarea).lineHeight};
//       white-space: pre-wrap;
//       width: ${textarea.clientWidth}px; /* Match textarea width for accurate line breaks */
//     `;
//     tempDiv.textContent = textarea.value;
//     document.body.appendChild(tempDiv);
//     const height = tempDiv.offsetHeight;
//     document.body.removeChild(tempDiv);
//     return height;
// }

export function EvaluationView({ evaluation, handleClose = null, isLoader = false, isViewOnly = false }) {
    console.log('evaluation::', evaluation);

    const [load, setLoad] = useState([false]);

    const [patient, setPatient] = useState(null);
    const componentRef = useRef();

    const {
        data: patientData,
        isLoading,
        isFetching
    } = useGetPatientDetailsQuery(
        { id: evaluation?.patient.id, ...(evaluation?.id && { source: 'update' }) },
        { skip: !evaluation?.patient.id }
    );

    useEffect(() => {
        setPatient(patientData?.fields);
    }, [patientData]);

    const [addtionalTime, setAddtionalTime] = useState([
        {
            startDate: null,
            startTime: '',
            endTime: '',
            serviceType: { stp_id: '', description: '', code: '' }
        }
    ]);

    useEffect(() => {
        setLoad(true);
        if (evaluation && evaluation.progress.length > 0) {
            setAddtionalTime(() =>
                evaluation.progress.map((item) => {
                    const type = {
                        stp_id: item.svc.id,
                        description: item.svc.description,
                        code: item.svc.code // types.find((type) => type.stp_id === item.svc.id)?.code || ''
                    };

                    return {
                        startTime: moment.utc(item.start_time).local().format('hh:mm A'),
                        endTime: moment.utc(item.end_time).local().format('hh:mm A'),
                        startDate: moment.utc(item.date).local().format(GLOBAL_DATE_FORMAT),
                        id: item.id,
                        serviceType: type
                    };
                })
            );
        }
        setLoad(false);
    }, [evaluation]);

    useEffect(() => {
        setLoad(true);
        const childNodes = document.querySelectorAll('.form-control');

        if (childNodes) {
            childNodes.forEach((node) => {
                node.disabled = true;
            });
        }

        const editables = document.querySelectorAll("[contenteditable='true']");
        if (editables) {
            editables.forEach((node) => {
                node.contentEditable = false;
            });
        }

        const tables = document.querySelectorAll('.table');

        tables.forEach((table) => {
            const tableContainer = document.createElement('div');
            tableContainer.classList.add('table-container');
            tableContainer.appendChild(table.cloneNode(true));
            const parent = table.parentElement;
            parent.replaceChild(tableContainer, table);
        });

        // make the input height static while printing
        const textareas = document.querySelectorAll('.print-pdf textarea');
        console.log('textareas::', textareas);

        textareas.forEach((item) => {
            // method 1
            // const contentHeight = getTextareaContentHeight(item);
            // console.log(item, contentHeight);
            // item.style.height = `${contentHeight + 24}px`;

            // method 2
            const oldElement = item;
            const newElement = document.createElement('p');
            newElement.classList.add('textarea');
            // replace new lines with <br>
            const formattedText = oldElement.innerHTML.replace(/\n/g, '<br />');
            newElement.innerHTML = formattedText;

            // Replace element
            oldElement.parentNode.replaceChild(newElement, oldElement);
        });
        setLoad(false);
    }, [evaluation]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey && event.key === 'p') {
                event.preventDefault();
                handlePrint();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const { user, client } = useAuth();

    const { data: logo } = useGetClientLogoQuery({ ...(user?.client_id && { client_id: user.client_id }) }, { skip: !user?.client_id });
    const [imageError, setImageError] = useState(false);

    const handleError = () => {
        setImageError(true);
    };

    useEffect(() => {
        setImageError(false);
    }, []);

    // if (isLoading || isFetching) return <Spinner />;

    return (
        <Box sx={{ paddingBottom: '50px' }}>
            <Stack mb={2} sx={{ gap: '12px' }} direction="row" justifyContent="flex-end" alignItems="center">
                {evaluation?.billing_details_id && (
                    <ViewBilling billingId={evaluation?.billing_details_id} progressNoteId={evaluation?.id} />
                )}
                <Button variant="contained" color="primary" onClick={handlePrint}>
                    Print
                </Button>

                {handleClose && (
                    <Button variant="outlined" onClick={handleClose}>
                        Close
                    </Button>
                )}
            </Stack>
            {/* <Loader /> */}
            {isLoader ? (
                <CircularProgress />
            ) : (
                <div ref={componentRef}>
                    <div className="print-pdf">
                        <div className="pdf-header">
                            <h2 className="title">{getServiceNameById(evaluation?.service)} Evaluation</h2>
                            {logo ? (
                                !imageError ? (
                                    <img className="print-logo" src={logo} alt={client?.name} onError={handleError} />
                                ) : (
                                    <>
                                        {!user?.is_superuser && user?.roles[0]?.role !== 'Physician' && (
                                            <h3 className="print-logo">{client?.name}</h3>
                                        )}
                                    </>
                                )
                            ) : (
                                <>
                                    {!user?.is_superuser && user?.roles[0]?.role !== 'Physician' && (
                                        <h3 className="print-logo">{client?.name}</h3>
                                    )}
                                </>
                            )}
                        </div>

                        <table>
                            <tbody>
                                <tr>
                                    <th scope="row">Patient</th>
                                    <td>{patient?.user.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Location</th>
                                    <td>{evaluation?.location?.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">DOB</th>
                                    <td>{moment(patient?.user.dob).format(GLOBAL_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Primary Diagnosis</th>
                                    <td>
                                        {
                                            patient?.service_info?.find((item) => item.service.id === evaluation?.service)
                                                ?.primary_diagnosis.icd_code
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Referral Diagnosis</th>
                                    <td>{patient?.referral_diagnosis?.map((diagnosis) => `${diagnosis?.icd_code}`).join(', ')}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Treatment Diagnosis</th>
                                    <td>
                                        {evaluation?.ignore_diagonosis
                                            ? 'Did not Qualify'
                                            : evaluation?.diagnosis?.map((diagnosis) => `${diagnosis?.icd_code}`).join(', ')}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Date</th>
                                    <td>{moment.utc(evaluation?.date).local().format(GLOBAL_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Therapist</th>
                                    <td>{getEmployeeName(evaluation?.employee)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Physician</th>
                                    <td>{patient?.physician_name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Outside Evaluation</th>
                                    <td>{evaluation?.is_outside_eval ? 'Yes' : 'No'}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* eslint-disable  */}
                        {!evaluation?.is_outside_eval && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Service Code</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addtionalTime.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.startDate}</td>
                                            <td>{item.startTime}</td>
                                            <td>{item.endTime}</td>
                                            <td>
                                                {item?.serviceType?.description && item?.serviceType?.code
                                                    ? `${item?.serviceType?.description} (${item?.serviceType?.code})`
                                                    : item?.serviceType?.description && !item?.serviceType?.code
                                                      ? `${item?.serviceType?.description || ''}`
                                                      : `${item?.serviceType?.code || ''}`}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {/* eslint-enable */}
                        <div className="eval-templates">
                            {evaluation?.templates?.map((element) => (
                                <div key={element?.id} id={`b${element?.id}`}>
                                    {parse(element.template)}
                                </div>
                            ))}
                        </div>

                        {evaluation?.service !== THERAPIES.dt && evaluation?.is_therapy_eob && (
                            <div className="therapy-eob">
                                <label
                                    style={{
                                        marginBottom: '24px'
                                    }}
                                    htmlFor="therapyEob"
                                >
                                    Therapy EOB
                                    <input id="therapyEob" type="checkbox" checked={evaluation?.is_therapy_eob} />
                                </label>

                                <>
                                    <div className="eob-boxes">
                                        <p className="label">
                                            Documentation that the Therapy administered effectively treats the beneficiary&apos;s condition.
                                        </p>
                                        <textarea disabled value={evaluation.eob_desc1} />
                                    </div>
                                    <div className="eob-boxes">
                                        <p className="label">
                                            Documentation that there is a reasonable expectation of meaningful improvement or that Therapy
                                            Services will prevent worsening of the beneficiaries&apos; current condition.
                                        </p>
                                        <textarea disabled value={evaluation.eob_desc2} />
                                    </div>
                                    <div className="eob-boxes">
                                        <p className="label">
                                            Frequency, intensity, and duration of the requested Therapy Services is realistic for the age of
                                            the child.
                                        </p>
                                        <textarea disabled value={evaluation.eob_desc3} />
                                    </div>
                                </>
                            </div>
                        )}

                        {evaluation?.service !== THERAPIES.dt && evaluation?.last_component_date && (
                            <div className="lastcomponent-date">
                                <label htmlFor="lastDate">
                                    Last Component Date: {moment(evaluation?.last_component_date).format(GLOBAL_DATE_FORMAT)}
                                </label>
                            </div>
                        )}

                        <div className="treatment-plans">
                            <h2 className="plan-duration">Treatment Plan Durations: {evaluation?.duration_month} months</h2>
                            {evaluation?.treatment_plan?.map((plan) => (
                                <PlanList key={plan.id} planInfo={plan} />
                            ))}
                        </div>
                        <SignModal type={SIGN_VISIT_TYPE.EVALUATION} data={evaluation} isViewOnly={isViewOnly} />
                    </div>
                </div>
            )}
        </Box>
    );
}

function PlanList({ planInfo }) {
    const accessToken = window.localStorage.getItem('accessToken');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [goals, setGoals] = useState([]);

    useEffect(() => {
        const data = {
            filters: {
                treatmentplan_id: planInfo.id,
                status: 1
            }
        };
        axios
            .post(`${API_ENDPOINT}/api/therapy/eval/treatment-plan/goal/`, data, {
                headers: { SUBTYPE: 'list', Authorization: `Bearer ${accessToken}` }
            })
            .then((resp) => {
                console.log(resp.data.fields);
                if (resp.data?.fields?.length > 0) {
                    setGoals([
                        ...resp.data.fields?.map((goal) => ({
                            id: goal.goal,
                            classification: { name: goal.classification },
                            goal: goal.goal_text
                        }))
                    ]);
                }
            });
    }, [planInfo]);

    return (
        <div className="plan">
            <h3>Plan Name: {planInfo.name}</h3>
            <p>Long Term Goal: {planInfo.long_term_goal}</p>
            <p>Direct Intervention (M / W): {planInfo.intervention_min_per_week}</p>

            {goals.length > 0 && (
                <div className="plan-table table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Goal No</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {goals.map((goal, index) => (
                                <tr key={goal.id}>
                                    <td>{index + 1}</td>
                                    <td>{goal.goal}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
