/* eslint-disable */
import { Autocomplete, Checkbox, Chip, FormControl, TextField } from '@mui/material';
import AsyncDropdown from 'components/AsyncDropdown';
import { GLOBAL_DATE_FORMAT } from 'constant';
import useAuth from 'hooks/useAuth';
import moment from 'moment';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import { useGetICDListQuery } from 'store/services/common';
import { useServiceListQuery } from 'store/services/core';
import { useGetEmployeeListQuery, useGetSuperviseeListActivityReportQuery, useGetTherapistListQuery } from 'store/services/employee';
import { useGetLocationCustomListQuery, useGetLocationListQuery } from 'store/services/location';
import { useGetCampusesQuery, useGetPatientListQuery } from 'store/services/patient';
import { useGetStudentsQuery } from 'store/services/school/classroom';
import { useGetTreatmentPlansQuery } from 'store/services/therapy/evaluation';
import { getEmployeeName } from 'views/therapy/Evaluation/List';
import _ from 'lodash';
import './AutoCOmplete.css';

export const PatientDropdown = memo(
    forwardRef(({ sx, value, onChange, async, payload, multiple, required, excludeList, ...rest }, ref) => {
        const { user } = useAuth();

        const {
            data: patientData,
            error: patientError,
            isLoading: patientIsLoading
        } = useGetPatientListQuery(
            {
                filters: {
                    ...(user?.client_id && { client_id: user.client_id }),
                    // status: 1,
                    order_col: 'name',
                    order: 'asc',
                    ...payload
                }
            },
            { skip: async }
        );

        if (async) {
            return (
                <AsyncDropdown
                    ref={ref}
                    sx={sx}
                    multi={multiple}
                    name="patient"
                    excludeList={excludeList}
                    SearchAccessor="name"
                    handler={useGetPatientListQuery}
                    defaultValue={value}
                    onChange={onChange}
                    getOptionLabel={(option) => option.user?.name}
                    renderOption={(props, option) => {
                        const isSelected = multiple ? value?.some((item) => item?.id === option?.id) : false;
                        return (
                            <li
                                style={{
                                    backgroundColor: isSelected ? '#edeff5' : ''
                                }}
                                {...props}
                                key={option?.id}
                            >
                                {option.user?.name}
                            </li>
                        );
                    }}
                    payload={{
                        payload: {
                            filters: {
                                ...(user?.client_id && { client_id: user.client_id }),
                                // status: 1,
                                order_col: 'name',
                                order: 'asc',
                                ...payload
                            }
                        },
                        skip: !user?.client_id
                    }}
                    renderInput={(props) => (
                        <TextField
                            autoComplete="off"
                            required={required}
                            label="Patient"
                            placeholder="type to search"
                            error={rest.error}
                            helperText={rest.helperText}
                            {...props}
                        />
                    )}
                    filterOptions={(options, value) => {
                        const { inputValue, getOptionLabel } = value;
                        const searchValue = inputValue.toLowerCase().trim();
                        const filteredList = options.filter((option) => getOptionLabel(option).toLowerCase().trim().includes(searchValue));

                        return filteredList;
                    }}
                    {...rest}
                />
            );
        }

        return (
            <FormControl fullWidth>
                <Autocomplete
                    sx={sx}
                    multiple={multiple}
                    value={value}
                    loading={patientIsLoading}
                    options={patientData?.fields || []}
                    getOptionLabel={(option) => option?.user?.name || ''}
                    onChange={(e, v) => onChange(v)}
                    renderOption={(props, option) => <li {...props}>{option?.user?.name || ''}</li>}
                    renderInput={(params) => (
                        <TextField
                            required={required}
                            variant="outlined"
                            label="Patient"
                            {...params}
                            error={rest.error}
                            helperText={rest.helperText}
                            {...params}
                        />
                    )}
                    {...rest}
                />
            </FormControl>
        );
    })
);

export const StudentDropdown = forwardRef(({ sx, value, onChange, filters, skip, multiple, required, ...rest }, ref) => {
    const { user } = useAuth();

    return (
        <AsyncDropdown
            ref={ref}
            sx={sx}
            multi={multiple}
            name="student"
            SearchAccessor="name"
            handler={useGetStudentsQuery}
            defaultValue={value}
            value={value}
            onChange={onChange}
            getOptionLabel={(option) => option?.name || option?.name}
            renderOption={(props, option) => (
                <li {...props} key={option?.id}>
                    {option?.name}
                </li>
            )}
            payload={{
                payload: {
                    filters: {
                        ...(user?.client_id && { client_id: user.client_id }),
                        status: 1,
                        order_col: 'name',
                        order: 'asc',
                        ...filters
                    }
                },
                skip
            }}
            {...rest}
            renderInput={(props) => (
                <TextField
                    required={required}
                    label="Student"
                    placeholder="type to search"
                    {...props}
                    error={rest.error}
                    helperText={rest.helperText}
                />
            )}
        />
    );
});

export function LocationDropdown({
    label = '',
    async,
    sx,
    value,
    onChange,
    filters,
    multiple,
    required,
    subType = 'LIST',
    optionsList = null,
    ...rest
}) {
    const { user } = useAuth();

    const [locationList, setLocationList] = useState([]);

    const {
        data: locationData,
        error: locationError,
        isLoading: locationIsLoading
    } = useGetLocationCustomListQuery(
        {
            payload: {
                filters: {
                    ...(user?.client_id && { client_id: user.client_id }),
                    order_by: 'name',
                    order: 'asc',
                    ...filters
                }
            },
            subType
        },
        { skip: async || optionsList }
    );

    useEffect(() => {
        if (optionsList) {
            setLocationList(optionsList);
        } else {
            setLocationList(locationData?.fields);
        }
    }, [locationData, optionsList]);

    if (async) {
        return (
            <AsyncDropdown
                sx={sx}
                multi={multiple}
                name="location"
                SearchAccessor="name"
                handler={useGetLocationCustomListQuery}
                defaultValue={value}
                onChange={onChange}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        style={{
                            color: !option?.is_active && _.has(option, 'is_active') && '#959494'
                        }}
                    >
                        {option?.name}
                        {!option?.is_active && _.has(option, 'is_active') ? ' (Inactive)' : ''}
                    </li>
                )}
                payload={{
                    payload: {
                        filters: {
                            ...(user?.client_id && { client_id: user.client_id }),
                            order_by: 'name',
                            order: 'asc',
                            ...filters
                        }
                    },
                    skip: !user?.client_id,
                    subType
                }}
                renderInput={(props) => (
                    <TextField
                        required={required}
                        label="Location"
                        placeholder="type to search"
                        {...props}
                        error={rest.error}
                        helperText={rest.helperText}
                    />
                )}
                {...rest}
            />
        );
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                sx={sx}
                multiple={multiple}
                value={value}
                loading={locationIsLoading}
                options={locationList || []}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, v) => onChange(v)}
                renderOption={(props, option) => (
                    <li
                        {...props}
                        style={{
                            color: !option?.is_active && _.has(option, 'is_active') && '#959494'
                        }}
                    >
                        {option?.name || ''}
                        {!option?.is_active && _.has(option, 'is_active') ? ' (Inactive)' : ''}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        variant="outlined"
                        label={label || 'Location'}
                        {...params}
                        error={rest.error}
                        helperText={rest.helperText}
                    />
                )}
                {...rest}
            />
        </FormControl>
    );
}

export function CampusDropdown({ async, sx, value, onChange, filters, multiple, required, groupBy, ...rest }) {
    const { user } = useAuth();

    const { data, error, isLoading } = useGetCampusesQuery(
        {
            filters: {
                ...(user?.client_id && { client_id: user.client_id }),
                status: 1,
                order_col: 'name',
                order: 'asc',
                ...filters
            }
        }
        // { skip: async }
    );

    const campus = data ? [...data.fields] : [];
    const sortedData = campus.sort((a, b) => a.location.name.toLowerCase().localeCompare(b.location.name.toLowerCase())) || [];

    return (
        <FormControl fullWidth>
            <Autocomplete
                sx={sx}
                required={required}
                multiple={multiple}
                value={value}
                loading={isLoading}
                options={sortedData}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, v) => onChange(v)}
                renderInput={(params) => (
                    <TextField variant="outlined" label="Campus" {...params} error={rest.error} helperText={rest.helperText} />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                {...(groupBy && { groupBy })}
                {...rest}
            />
        </FormControl>
    );
}

export function ServiceDropdown({ sx, value, onChange, filters, excludes, multiple, required, ...rest }) {
    const { user } = useAuth();
    const {
        data: serviceData,
        error: serviceError,
        isLoading: serviceIsLoading
    } = useServiceListQuery(
        {
            filters: {
                status: 1,
                ...(user?.client_id && { client_id: user.client_id }),
                ...filters
            }
        },
        { skip: !user?.client_id }
    );

    return (
        <FormControl fullWidth>
            <Autocomplete
                sx={sx}
                multiple={multiple}
                value={value}
                loading={serviceIsLoading}
                options={excludes ? serviceData?.fields.filter((item) => !excludes.includes(item.id)) || [] : serviceData?.fields || []}
                getOptionLabel={(option) => option?.service_name || ''}
                onChange={(e, v) => onChange(v)}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        variant="outlined"
                        label="Service"
                        {...params}
                        error={rest.error}
                        helperText={rest.helperText}
                        // error={rest.error}
                        // helperText={rest.helperText}
                    />
                )}
                {...rest}
            />
        </FormControl>
    );
}

export const IcdDropdown = memo(
    forwardRef(({ async, sx, multiple, value, onChange, filters, required, excludeList, ...rest }, ref) => {
        const { user } = useAuth();

        const { data, error, isLoading } = useGetICDListQuery(
            {
                filters: {
                    ...filters
                }
            },
            { skip: async }
        );

        if (async) {
            return (
                <AsyncDropdown
                    ref={ref}
                    sx={sx}
                    multi={multiple}
                    name="treatmentDiagnosis"
                    excludeList={excludeList}
                    // SearchAccessor="name"
                    handler={useGetICDListQuery}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                    getOptionLabel={(option) => `${option?.icd_code} - ${option?.description}`}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option?.icd_code} - {option?.description}
                        </li>
                    )}
                    payload={{
                        payload: {
                            filters: {
                                ...filters
                            }
                        }
                    }}
                    renderInput={(props) => (
                        <TextField
                            autoComplete="off"
                            required={required}
                            label="Treatment Diagnosis"
                            placeholder="type to search"
                            error={rest.error}
                            helperText={rest.helperText}
                            {...props}
                        />
                    )}
                    {...rest}
                />
            );
        }

        return (
            <FormControl fullWidth>
                <Autocomplete
                    sx={sx}
                    multiple={multiple}
                    loading={isLoading}
                    options={data?.fields || []}
                    getOptionLabel={(option) => `${option?.icd_code} - ${option?.description}`}
                    value={value}
                    onChange={(e, v) => onChange(v)}
                    renderInput={(params) => (
                        <TextField
                            required={required}
                            variant="outlined"
                            label="Treatment Diagnosis"
                            {...params}
                            error={rest.error}
                            helperText={rest.helperText}
                        />
                    )}
                    {...rest}
                />
            </FormControl>
        );
    })
);

export const EmployeeDropdown = memo(
    forwardRef(
        (
            {
                async = true,
                sx,
                value,
                onChange,
                filters,
                multiple,
                required,
                excludeList,
                isSuperVisor = false,
                isSameAsMasterAdmin = false,
                ...rest
            },
            ref
        ) => {
            const { user } = useAuth();

            let queryResult;

            const commonFilters = {
                ...(user?.client_id && { client_id: user.client_id }),
                status: 1,
                order_col: 'name',
                order: 'asc',
                ...filters
            };

            if (isSameAsMasterAdmin || !isSuperVisor) {
                queryResult = useGetEmployeeListQuery(
                    { filters: commonFilters },
                    { skip: async }
                );
            } else {
                queryResult = useGetSuperviseeListActivityReportQuery(
                    {
                        filters: {
                            ...commonFilters,
                            emp_id: user?.emp_id
                        }
                    },
                    { skip: async }
                );
            }

            const { data, error, isLoading } = queryResult;

            if (async) {
                return (
                    <AsyncDropdown
                        ref={ref}
                        sx={sx}
                        multi={multiple}
                        name="employee"
                        excludeList={excludeList}
                        SearchAccessor="name"
                        handler={isSameAsMasterAdmin || !isSuperVisor ? useGetEmployeeListQuery : useGetSuperviseeListActivityReportQuery}
                        defaultValue={value}
                        value={value}
                        onChange={onChange}
                        getOptionLabel={(option) => `${option.user__first_name} ${option.user__last_name}`}
                        renderOption={(props, option) => {
                            const isSelected = multiple ? value?.some((item) => item?.emp_id === option?.emp_id) : false;
                            return (
                                <li
                                    {...props}
                                    style={{
                                        backgroundColor: isSelected ? '#edeff5' : ''
                                    }}
                                >{`${option.user__first_name} ${option.user__last_name}`}</li>
                            );
                        }}
                        payload={{
                            payload: {
                                filters: {
                                    ...(user?.client_id && { client_id: user.client_id }),
                                    status: 1,
                                    order_col: 'name',
                                    order: 'asc',
                                    ...filters
                                }
                            },
                            skip: !user?.client_id
                        }}
                        {...rest}
                        renderInput={(props) => (
                            <TextField
                                autoComplete="off"
                                required={required}
                                label="Employee"
                                placeholder="type to search"
                                error={rest.error}
                                helperText={rest.helperText}
                                {...props}
                            />
                        )}
                    />
                );
            }

            return (
                <FormControl fullWidth>
                    <Autocomplete
                        sx={sx}
                        multiple={multiple}
                        loading={isLoading}
                        options={data?.fields || []}
                        getOptionLabel={(option) => option.name}
                        value={value}
                        onChange={(e, v) => onChange(v)}
                        renderInput={(params) => (
                            <TextField
                                required={required}
                                variant="outlined"
                                label="Employee"
                                {...params}
                                error={rest.error}
                                helperText={rest.helperText}
                            />
                        )}
                        {...rest}
                    />
                </FormControl>
            );
        }
    )
);

export const TherapistDropdown = memo(
    forwardRef(({ async = false, sx, value, onChange, filters, multiple, required, excludeList, ...rest }, ref) => {
        const { user } = useAuth();

        const { data, error, isLoading } = useGetTherapistListQuery(
            {
                filters: {
                    ...(user?.client_id && { clients: [user.client_id] }),
                    status: 1,
                    order_col: 'name',
                    order: 'asc',
                    ...filters
                }
            },
            { skip: async }
        );

        if (async) {
            return (
                <AsyncDropdown
                    ref={ref}
                    sx={sx}
                    multi={multiple}
                    name="therapist"
                    excludeList={excludeList}
                    SearchAccessor="name"
                    handler={useGetTherapistListQuery}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                    getOptionLabel={(option) => getEmployeeName(option)}
                    renderOption={(props, option) => <li {...props}>{getEmployeeName(option)}</li>}
                    payload={{
                        payload: {
                            filters: {
                                ...(user?.client_id && { clients: [user.client_id] }),
                                status: 1,
                                order_col: 'name',
                                order: 'asc',
                                ...filters
                            }
                        },
                        skip: !user?.client_id
                    }}
                    {...rest}
                    renderInput={(props) => (
                        <TextField
                            autoComplete="off"
                            required={required}
                            label="Therapist"
                            placeholder="type to search"
                            error={rest.error}
                            helperText={rest.helperText}
                            {...props}
                        />
                    )}
                />
            );
        }
        return (
            <Autocomplete
                sx={sx}
                multiple={multiple}
                loading={isLoading}
                loadingText="loading.."
                options={data?.fields || []}
                getOptionLabel={(option) => getEmployeeName(option)}
                value={value}
                onChange={(e, v) => onChange(v)}
                renderOption={(props, option) => (
                    <li {...props} key={option?.emp_id}>
                        {getEmployeeName(option)}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        required={required}
                        variant="outlined"
                        label="Therapist"
                        {...params}
                        error={rest.error}
                        helperText={rest.helperText}
                    />
                )}
                filterOptions={(options, value) => {
                    const { inputValue, getOptionLabel } = value;
                    const searchValue = inputValue.toLowerCase().trim();
                    const filteredList = options.filter((option) => getOptionLabel(option).toLowerCase().trim().includes(searchValue));

                    return filteredList;
                }}
                {...rest}
            />
        );
    })
);

export const TreatmentPlanDropdown = memo(
    forwardRef(({ disabled, async = false, skip, sx, value, onChange, filters, multiple, required, excludeList, ...rest }, ref) => {
        const { user } = useAuth();

        const { data, error, isLoading } = useGetTreatmentPlansQuery(
            {
                filters: {
                    ...(user?.client_id && { clients: [user.client_id] }),
                    order_col: 'name',
                    order: 'asc',
                    ...filters
                }
            },
            {
                skip
            }
        );

        return (
            <Autocomplete
                disabled={disabled}
                options={data?.fields || []}
                loading={isLoading}
                groupBy={(option) => moment.utc(option.evaluation.date).local().format(GLOBAL_DATE_FORMAT)}
                value={value}
                onChange={(_, value) => {
                    onChange(value);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField required={required} label="Treatment Plan" {...params} error={rest.error} helperText={rest.helperText} />
                )}
            />
        );
    })
);
