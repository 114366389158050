/* eslint-disable arrow-body-style */
import PropTypes from 'prop-types';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Typography,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
import { AspyreDateTimePicker as DateTimePicker } from 'components/common/InputFields';
import _ from 'lodash';
import { useFormik, Form, FormikProvider } from 'formik';

import { GLOBAL_DATE_FORMAT, PAYLOAD_DATE_TIME_FORMAT, THERAPY_MAX_TIME, THERAPY_MIN_TIME, gridSpacing } from 'constant';

import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import usePermission from 'hooks/usePermission';
import ConfirmationModal from 'components/reusable/ConfirmationModal';
import useAuth from 'hooks/useAuth';
import { useAddEventMutation, useGetEventDetailsQuery } from 'store/services/schedule/events';
import moment from 'moment';
import { Close, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { encriptId } from 'utils/encription';
import useSnackbar from 'hooks/useSnackbar';
import Spinner from 'ui-component/Spinner';
import { getEmployeeName } from 'views/therapy/Evaluation/List';
import { getBg } from 'views/Schedule/serviceColors';
import { useGetServicesQuery } from 'store/services/common';
import { getInitialValues, generateHour, generateMinute, makeTitle, transformData, EventSchema, days, visitValues } from './useEventForm';
import { PatientDropdown } from 'components/reusable/Dropdowns';
// import { handlePatientChangeV2 } from 'components/AsyncDropdown/onChangeHelper';

// ==============================|| CALENDAR EVENT ADD / EDIT / DELETE ||============================== //

const AddEventFrom = ({ event, range, handleDelete, onCancel, table, tableEdit, isViewOnly = false }) => {
    const scheduleId = event?.sch_id;
    const accessToken = window.localStorage.getItem('accessToken');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const { hasPermission, isTherapist: isRoleTherapist, isSameAsMasterAdmin } = usePermission();
    const isTherapist = isRoleTherapist && !isSameAsMasterAdmin;

    const hours = generateHour();
    const minute = generateMinute();

    const { notify } = useSnackbar();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [locations, setLocations] = useState(null);
    const [locLoading, setLocLoading] = useState(true);
    const [services, setServices] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [empLoading, setEmpLoading] = useState(true);
    const [patients, setPatients] = useState(null);
    const [patLoading, setPatLoading] = useState(true);
    const [didLocChange, setDidLocChange] = useState(false);
    const [isView, setIsView] = useState(event);
    const [scheduleData, setScheduleData] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const [addEvent, { isLoading: addLoading }] = useAddEventMutation();

    /**
     * * FORMIK
     */

    const formik = useFormik({
        initialValues: getInitialValues(scheduleData, range),
        validationSchema: EventSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                if (values.treatment_type === 'individual' && values?.patient?.is_active === false) {
                    notify({
                        message: 'Patient is inactive',
                        severity: 'warning'
                    });
                    return;
                }

                let grpPatients = [];
                if (values.treatment_type === 'group') {
                    grpPatients = values?.grp_patients.map((item) => {
                        return {
                            id: item.id,
                            name: item.user.name,
                            is_active: item.user.is_active
                        };
                    });
                }

                const payload = {
                    fg_color: values.textColor,
                    bg_color: getBg(values.service.service_name.replaceAll(' ', '').toLowerCase()),
                    employees: [values.employee.id],
                    locations: [values.location.id],
                    patients: values.treatment_type === 'group' ? grpPatients.map((item) => item.id) : [values.patient.id],
                    services: [values.service.id],
                    start_date_time: moment(new Date(values.start)).utc().format(PAYLOAD_DATE_TIME_FORMAT),
                    duration_hour: values.hour,
                    duration_minute: values.minute,
                    title: makeTitle(values.visitType, values.treatment_type === 'group' ? grpPatients : values.patient),
                    description: values.description,
                    visit_type: values.visitType,
                    dates: values.days.map((day) =>
                        moment(new Date(values.start))
                            .weekday(day + 1)
                            .utc()
                            .format(PAYLOAD_DATE_TIME_FORMAT)
                    ),
                    is_grouped: values.treatment_type === 'group' ? 1 : 0
                };

                if (scheduleData) {
                    payload.sch_id = scheduleData.sch_id;
                }
                const res = await addEvent(payload).unwrap();
                notify({
                    message: scheduleData ? 'Event updated successfully!' : 'Event created successfully!',
                    severity: 'success'
                });

                resetForm();
                onCancel();
                setSubmitting(false);
            } catch (error) {
                console.error(error);
                setSubmitting(false);
                notify({
                    message: error.data?.message || 'Something went wrong!',
                    severity: 'error'
                });
            }
        }
    });
    const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, handleBlur } = formik;

    /**
     * * FUNCTION :  SET UP FORMIK VALUES
     */
    const addToForm = (field, value) => {
        setFieldValue(field, value);
    };

    const addToFormArr = (field, value) => {
        if (value.length === 0) {
            setFieldValue(field, []);
        } else {
            setFieldValue(field, value);
        }
    };

    /**
     * * HANDLING API REQUESTS
     */
    const { data: scheduleDetail, isFetching: scheduleLoading } = useGetEventDetailsQuery({ sch_id: scheduleId }, { skip: !scheduleId });

    const { data: serviceData, isFetching: serviceLoading } = useGetServicesQuery(
        {
            filters: {
                locations: [values.location?.id],
                ...(user?.emp_id && { emp_id: user?.emp_id })
                // source: 'schedule'
            }
        },
        {
            skip: !values.location?.id
        }
    );

    /**
     * * FUNCTION :  HANDLING API REQUESTS
     */
    const fetchLocData = useCallback(async () => {
        const payload = {
            filters: {
                status: 1,
                order_col: 'name',
                order: 'ASC'
            }
        };
        if (user.client_id) {
            payload.filters.client_id = user.client_id;
        }

        let res;
        try {
            res = await axios.post(`${API_ENDPOINT}/api/location/`, payload, {
                headers: {
                    SUBTYPE: 'LIST',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setLocations(res.data.fields);
            setLocLoading(false);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchEmpData = useCallback(async () => {
        if (!values.location?.id || !values.service?.id) {
            return;
        }
        try {
            const payload = {
                filters: {
                    services: [values.service.id],
                    locations: [values.location.id],
                    source: 'schedular'
                }
            };
            if (user?.client_id) {
                payload.filters.clients = [user?.client_id];
            }
            const res = await axios.post(`${API_ENDPOINT}/api/employees/employee/`, payload, {
                headers: {
                    SUBTYPE: 'listthrp',
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setEmployees(res.data.fields);

            if (isTherapist && !event) {
                const currentEmployee = () => {
                    const therap = res.data?.fields.find((item) => item.emp_id === user?.emp_id);
                    if (therap) {
                        return {
                            id: therap.emp_id,
                            name: therap.name
                        };
                    }
                    return null;
                };
                addToFormArr('employee', currentEmployee());
            }

            setEmpLoading(false);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.location, values.service]);

    const fetchPatData = useCallback(async () => {
        if (!values.location?.id || !values.service?.id) {
            return;
        }
        try {
            const res = await axios.post(
                `${API_ENDPOINT}/api/scheduler/patient-list/`,
                {
                    filters: {
                        locations: [values.location.id],
                        services: [values.service.id]
                    }
                },
                {
                    headers: {
                        SUBTYPE: 'LIST',
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            setPatients(transformData(res.data.fields, 'name', 'pat_id'));

            setPatLoading(false);
        } catch (error) {
            console.log(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.location, values.service]);

    /**
     * * FUNCTION : Utils
     */
    const handleEditClick = () => {
        setIsView((isView) => !isView);
    };

    const handleEditEventClick = () => {
        if (scheduleData?.patients?.some((patient) => patient.is_active === false)) {
            notify({
                message: 'Patient is inactive',
                severity: 'warning'
            });
            return;
        }
        setIsView((isView) => !isView);
    };

    const handleOpenDelete = () => {
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
    };

    const handleStartEvaluation = () => {
        if (!scheduleData?.patients?.[0]?.is_active) {
            notify({
                message: 'Patient is inactive',
                severity: 'warning'
            });
        } else {
            if (scheduleData.visit_type === 'EVALUATION') {
                if (scheduleData.therapy.evaluation) {
                    navigate(
                        `/therapy/${scheduleData.services[0]?.service_code.toLowerCase()}/evaluation/edit/${encriptId(
                            scheduleData.therapy.evaluation
                        )}`
                    );
                } else {
                    navigate(`/therapy/evaluation/create/schedule/${encriptId(scheduleData.sch_id)}`);
                }
            }
            if (scheduleData.visit_type === 'TREATMENT') {
                if (scheduleData.therapy.treatment) {
                    navigate(
                        `/therapy/${scheduleData.services[0]?.service_code.toLowerCase()}/treatment/edit/${encriptId(
                            scheduleData.therapy.treatment
                        )}`
                    );
                } else {
                    navigate(`/therapy/treatment/create/schedule/${encriptId(scheduleData.sch_id)}`);
                }
            }
        }
    };

    const handleTreatmentTypeChange = (e, v) => {
        addToForm('treatment_type', v);
        if (v === 'individual') {
            // addToFormArr('patients', []);
        } else if (v === 'group') {
            // addToForm('patient', null);
        }
    };

    /**
     * * FUNCTION : SIDE EFFECTS
     */
    useEffect(() => {
        if (scheduleDetail) {
            setScheduleData(scheduleDetail.fields);
        }
    }, [scheduleDetail]);

    useEffect(() => {
        formik.setValues(getInitialValues(scheduleData, range));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleData]);

    useEffect(() => {
        if (!locations) {
            fetchLocData();
        }

        if (didLocChange) {
            setFieldValue('service', null);
            setFieldValue('employee', []);
            setFieldValue('patient', []);
        }
        setDidLocChange(false);
    }, [fetchLocData, locations, setFieldValue, didLocChange, setDidLocChange]);

    useEffect(() => {
        if (serviceData) {
            setServices(
                serviceData.fields
                    ?.filter((item) =>
                        hasPermission([`${item?.service_code?.toLowerCase()}_eval_add`, `${item?.service_code?.toLowerCase()}_note_add`])
                    )
                    .map((item) => ({
                        ...item,
                        id: item?.id
                    }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceData]);

    useEffect(() => {
        if (values.location?.id) {
            fetchEmpData();
        }
    }, [values.location, fetchEmpData]);

    useEffect(() => {
        if (values.location?.id || values.service?.id) {
            fetchPatData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.location, fetchPatData]);

    useEffect(() => {
        if (tableEdit) {
            setIsView((isView) => !isView);
        }
    }, [tableEdit]);

    useEffect(() => {
        if (formik.values.start) {
            formik.setFieldValue('days', [moment(new Date(formik.values.start)).weekday() - 1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.start]);

    useEffect(() => {
        if (formik?.values?.visitType && !event) {
            if (formik?.values?.visitType === 'TREATMENT') {
                addToFormArr('grp_patients', []);
                addToForm('patient', null);
            } else {
                addToForm('patient', null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.visitType]);

    useEffect(() => {
        // if (event) {
        if (formik?.values?.treatment_type === 'individual') {
            // addToForm('patients', null);
        } else {
            // addToForm('patient', null);
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.treatment_type]);

    const handlePatientChangeV2 = (v, reason, formik, selectedIds, setSelectedIds) => {
        let removedItem;

        if (reason === 'removeOption') {
            // Find the removed item
            removedItem = selectedIds.find((location) => !v.some((newLocation) => newLocation.id === location));

            // Update Formik's locations field to exclude the removed item
            formik.setFieldValue(
                'grp_patients',
                v.filter((item) => item?.id !== removedItem)
            );
        } else {
            // Get the patientIds from the 'v' array
            const patientIds = v.map((option) => option?.id);

            // Count occurrences of each location.id
            // Count occurrences of each patientId
            const patientCount = patientIds.reduce((acc, id) => {
                acc[id] = (acc[id] || 0) + 1; // Increment the count for each patientId
                return acc;
            }, {});

            // Filter out location ids that appear more than once
            const duplicatePatientIds = Object.keys(patientCount).filter((id) => patientCount[id] > 1);

            // Remove duplicate locations
            const filteredV = v.filter((option) => !duplicatePatientIds.includes(String(option?.id)));

            // Update the selectedIds state (keeping track of previously selected IDs)
            setSelectedIds((prevIds) => [...prevIds, ...filteredV.filter((id) => !prevIds.includes(id))]);

            // Update Formik's locations field with the filtered locations
            formik.setFieldValue('grp_patients', filteredV);
        }
    };


    if (scheduleLoading) {
        return <Spinner />;
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {(serviceLoading || addLoading) && <Spinner />}
                {isView ? (
                    <DialogTitle>
                        {values?.is_grouped
                            ? `Group Treatment for ${values.grp_patients.map((item) => item.user.name).join(', ')}${scheduleData?.missed_reason ? ' (Missed Note)' : ''}`
                            : values.title + (scheduleData?.missed_reason ? ' (Missed Note)' : '')}

                        {/* {values.is_grouped ? 'Group ' : ''} {values.title} {scheduleData?.missed_reason && '(Missed Note)'} */}
                    </DialogTitle>
                ) : (
                    <DialogTitle>{scheduleData ? 'Edit Event' : 'Add Event'}</DialogTitle>
                )}
                <Divider />
                <DialogContent sx={{ p: 3 }}>
                    <IconButton onClick={onCancel} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <Close />
                    </IconButton>
                    {isView && (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={6}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start;"
                                    alignItems="center"
                                    // divider={<Divider sx={{ color: 'black', fontSize: '1.4rem', fontWeight: 700 }}>:</Divider>}
                                >
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Patient
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>
                                    <div style={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}>
                                        <Typography varient="h4" component="h4">
                                            {values?.is_grouped  ? values.grp_patients?.map((pat) => pat.user.name).join(', ') : values.patient?.name}
                                        </Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Location
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography
                                        varient="h4"
                                        component="h4"
                                        sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                    >
                                        {values.location?.name}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Employee
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>
                                    <div style={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}>
                                        <Typography varient="h4" component="h4">
                                            {getEmployeeName(values.employee)}
                                        </Typography>
                                    </div>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Service
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography
                                        varient="h4"
                                        component="h4"
                                        sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                    >
                                        {values.service?.service_name}
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Visit Type
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography
                                        varient="h4"
                                        component="h4"
                                        sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                    >
                                        {values.visitType}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Status
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography varient="h4" component="h4" sx={{ minWidth: '150px', margin: '0px 10px' }}>
                                        {values.status}
                                    </Typography>
                                </Stack>
                            </Grid>
                            {values.is_grouped === false && (
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                        <Typography
                                            variant="h4"
                                            component="h4"
                                            sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}
                                        >
                                            Special needs
                                        </Typography>

                                        <Typography variant="h4" component="h4" ml={4} sx={{ minWidth: '10px', marginLeft: '0px' }}>
                                            :
                                        </Typography>

                                        <Typography
                                            ml="10px"
                                            varient="h4"
                                            component="h4"
                                            sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                        >
                                            {values.description}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Start Time
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography
                                        varient="h4"
                                        component="h4"
                                        sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                    >
                                        {moment(values.start)?.format(`${GLOBAL_DATE_FORMAT} - hh:mm a`)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" justifyContent="flex-start;" alignItems="center">
                                    <Typography variant="h4" component="h4" sx={{ minWidth: '120px', maxWidth: '150px', width: 'auto' }}>
                                        Duration
                                    </Typography>

                                    <Typography variant="h4" component="h4" sx={{ minWidth: '10px' }}>
                                        :
                                    </Typography>

                                    <Typography
                                        varient="h4"
                                        component="h4"
                                        sx={{ minWidth: '150px', maxWidth: '250px', width: 'auto', margin: '0px 10px' }}
                                    >
                                        {values.hour} hrs and {values.minute} min.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                    {!isView && (
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        value={values.location}
                                        onChange={(_, value) => {
                                            addToForm('location', value ? { id: value.id, name: value.name } : {});
                                            setDidLocChange(true);
                                        }}
                                        options={locations || []}
                                        disableClearable
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        loading={locLoading}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(errors.location) && touched.location}
                                                onBlur={handleBlur}
                                                name="location"
                                                label="Location *"
                                                helperText={touched.location && errors.location}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        onChange={(_, value) => {
                                            addToForm('service', value);
                                        }}
                                        options={services || []}
                                        disabled={Boolean(!values.location)}
                                        loading={serviceLoading}
                                        getOptionLabel={(option) => option.service_name}
                                        value={values.service}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                error={Boolean(errors.service) && touched.service}
                                                {...params}
                                                label="Service *"
                                                name="service"
                                                onBlur={handleBlur}
                                                helperText={touched.service && errors.service && (errors.service.name || errors.service)}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl error={Boolean(errors.visitType) && touched.visitType} fullWidth>
                                    <InputLabel id="visitType-label">Visit Type *</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="visitType-label"
                                        id="visitType"
                                        disabled={Boolean(!values.location)}
                                        {...getFieldProps('visitType')}
                                        name="visitType"
                                        onBlur={handleBlur('visitType')}
                                        onChange={(e, v) => {
                                            addToForm('visitType', e.target.value);
                                        }}
                                        label="Visit Type *"
                                    >
                                        {visitValues.map((type, index) => (
                                            <MenuItem value={type.value} key={index}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.visitType && Boolean(errors.visitType) && <FormHelperText>{errors.visitType}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            <Grid item md={6}>
                                <Typography variant="h2" component="h2" sx={{ fontWeight: 500, fontSize: '1.25rem' }}>
                                    Participants
                                </Typography>
                            </Grid>

                            <Grid item md={6}>
                                {values.visitType === 'TREATMENT' && (
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={values.treatment_type}
                                        exclusive
                                        onChange={(e, v) => {
                                            if (v !== null) {
                                                handleTreatmentTypeChange(e, v);
                                            }
                                        }}
                                        aria-label="treatment_type"
                                    >
                                        <ToggleButton value="individual">Individual</ToggleButton>
                                        <ToggleButton value="group">Group</ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disabled={isTherapist || Boolean(!values.location || !values.service)}
                                        fullWidth
                                        onChange={(e, value) => {
                                            addToForm(
                                                'employee',
                                                value
                                                    ? {
                                                          id: value.emp_id,
                                                          name: value.name,
                                                          credentials: value.credentials || ''
                                                      }
                                                    : null
                                            );
                                        }}
                                        options={employees || []}
                                        value={values.employee}
                                        loading={empLoading}
                                        getOptionLabel={(option) => getEmployeeName(option)}
                                        isOptionEqualToValue={(option, value) => option.emp_id === value.id}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option?.emp_id}>
                                                {getEmployeeName(option)}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                error={touched.employee && Boolean(errors.employee)}
                                                {...params}
                                                label="Employee *"
                                                onBlur={handleBlur}
                                                helperText={touched.employee && errors.employee}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            {values.treatment_type === 'group' &&  values.visitType === 'TREATMENT'? (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <PatientDropdown
                                            required
                                            limitTags={2}
                                            async
                                            multiple
                                            value={values.grp_patients}
                                            payload={{
                                                payer_category: 1,
                                                services: [values?.service?.id],
                                                location: [values?.location?.id]
                                            }}
                                            // for handling duplication
                                            onChange={(v, reason) => handlePatientChangeV2(v, reason, formik, selectedIds, setSelectedIds)}
                                            error={formik.touched.grp_patients && Boolean(formik.errors.grp_patients)}
                                            helperText={formik.touched.grp_patients && formik.errors.grp_patients}
                                        />
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            fullWidth
                                            id="fixed-tags-demo"
                                            onChange={(_, value) => {
                                                addToForm('patient', value);
                                            }}
                                            loading={patLoading}
                                            disabled={Boolean(!values.location)}
                                            options={patients || []}
                                            value={values.patient}
                                            getOptionLabel={(option) => option?.name || ''}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={touched.patient && Boolean(errors.patient)}
                                                    {...params}
                                                    label="Patient *"
                                                    onBlur={handleBlur}
                                                    helperText={touched.patient && errors.patient}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            )}

                            {values.treatment_type === 'individual' && (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        label="Special needs"
                                        {...getFieldProps('description')}
                                        disabled={Boolean(!values.location)}
                                        error={Boolean(touched.description && errors.description)}
                                        helperText={touched.description && errors.description}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    disabled={Boolean(!values.location)}
                                    // minTime={dayjs().set('hour', 5).set('minute', 59)}
                                    // maxTime={dayjs().set('hour', 22).set('minute', 59)}
                                    minTime={THERAPY_MIN_TIME}
                                    maxTime={THERAPY_MAX_TIME}
                                    label="Start date *"
                                    value={values.start}
                                    onChange={(date) => setFieldValue('start', date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={formik.touched.start && Boolean(formik.errors.start)}
                                            helperText={formik.touched.start && formik.errors.start}
                                        />
                                    )}
                                    slotProps={{ popper: { placement: 'top-start' } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container gap={1}>
                                    <Grid item xs={12} md={5}>
                                        <FormControl error={Boolean(errors.hour)} fullWidth>
                                            <InputLabel id="hours-label">Hour</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="hours-label"
                                                id="hour"
                                                // value={values.visitType}
                                                // onChange={handleChange}
                                                {...getFieldProps('hour')}
                                                label="Hour"
                                                disabled={Boolean(!values.location)}
                                            >
                                                {hours.map((type, index) => (
                                                    <MenuItem value={type.value} key={index}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(errors.hour) && <FormHelperText>{errors.hour}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <FormControl error={Boolean(errors.minute)} fullWidth>
                                            <InputLabel id="minute-label">Minute</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="minute-label"
                                                id="minute"
                                                {...getFieldProps('minute')}
                                                label="Minute"
                                                disabled={Boolean(!values.location)}
                                            >
                                                {minute.map((type, index) => (
                                                    <MenuItem value={type.value} key={index}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {Boolean(errors.minute) && <FormHelperText>{errors.minute}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {!event && ![0, 6].some((day) => moment(new Date(formik.values.start)).weekday() === day) && (
                                <Grid item xs={12} md={12}>
                                    <InputLabel>Recurring Days</InputLabel>

                                    {days.map((item, i) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        Boolean(!values.location) ||
                                                        Boolean(!formik.values.start) ||
                                                        moment(new Date(formik.values.start)).weekday() - 1 > i
                                                    }
                                                    checked={formik.values.days.includes(i)}
                                                    onChange={(scheduleData) => {
                                                        if (i === moment(new Date(formik.values.start)).weekday() - 1) return;

                                                        const { checked } = scheduleData.target;
                                                        if (checked) {
                                                            formik.setFieldValue('days', [...formik.values.days, i]);
                                                        } else {
                                                            formik.setFieldValue(
                                                                'days',
                                                                formik.values.days.filter((day) => day !== i)
                                                            );
                                                        }
                                                    }}
                                                    name={item}
                                                />
                                            }
                                            label={item}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                    {isView && !isViewOnly && (
                        <>
                            <Grid item>
                                {(hasPermission(['therapy_appointments_delete']) ||
                                    values.employee?.id === user?.emp_id ||
                                    user.supervisee?.includes(values.employee?.id)) &&
                                    !table &&
                                    scheduleData?.status === 'SCHEDULED' && (
                                        <Tooltip title="Delete Event">
                                            <IconButton onClick={handleOpenDelete} size="large">
                                                <DeleteIcon color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </Grid>
                            {!scheduleData?.missed_reason && (
                                <Grid item>
                                    {(hasPermission(['therapy_appointments_edit']) ||
                                        values.employee?.id === user?.emp_id ||
                                        user.supervisee?.includes(values.employee?.id)) &&
                                        !table &&
                                        scheduleData?.status === 'SCHEDULED' && (
                                            <Tooltip title="Edit Event">
                                                <IconButton onClick={handleEditEventClick} size="large">
                                                    <Edit color="primary" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                </Grid>
                            )}
                            {!scheduleData?.missed_reason && (
                                <Grid item>
                                    {(hasPermission([`${scheduleData?.services[0]?.service_code.toLowerCase()}_eval_edit`]) ||
                                        values.employee?.id === user?.emp_id ||
                                        user.supervisee?.includes(values.employee?.id)) &&
                                        scheduleData?.status === 'SCHEDULED' &&
                                        (scheduleData?.visit_type === 'EVALUATION' || scheduleData?.visit_type === 'TREATMENT') && (
                                            <Button onClick={handleStartEvaluation} variant="contained">
                                                Start
                                            </Button>
                                        )}

                                    {!values.is_grouped &&
                                        (hasPermission([`${scheduleData?.services[0]?.service_code.toLowerCase()}_eval_edit`]) ||
                                            values.employee?.id === user?.emp_id ||
                                            user.supervisee?.includes(values.employee?.id)) &&
                                        scheduleData?.status !== 'SCHEDULED' &&
                                        (scheduleData?.visit_type === 'EVALUATION' || scheduleData?.visit_type === 'TREATMENT') && (
                                            <Button onClick={handleStartEvaluation} variant="contained">
                                                View
                                            </Button>
                                        )}
                                </Grid>
                            )}
                        </>
                    )}
                    {!isView && (
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid>
                                {!table && event && (
                                    <Button type="submit" variant="outlined" onClick={handleEditClick}>
                                        Go back
                                    </Button>
                                )}
                            </Grid>

                            <Grid item>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button type="submit" variant="contained" disabled={isSubmitting || Boolean(!values.location)}>
                                        {scheduleData ? 'Save' : 'Add'}
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                    <ConfirmationModal
                        open={showDelete}
                        handleClose={handleCloseDelete}
                        handleConfirm={() => handleDelete(scheduleData?.sch_id)}
                        title="Delete Event"
                        description="Are you sure you want to delete this event?"
                    />
                </DialogActions>
            </Form>
        </FormikProvider>
    );
};

AddEventFrom.propTypes = {
    event: PropTypes.object,
    range: PropTypes.object,
    handleDelete: PropTypes.func,
    handleCreate: PropTypes.func,
    handleUpdate: PropTypes.func,
    onCancel: PropTypes.func
};

export default AddEventFrom;
