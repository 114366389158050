import { api } from '../api';

export const frequencyCheckReport = api.injectEndpoints({
    endpoints: (builder) => ({
        getFrequecyCheckReport: builder.query({
            query: (payload) => ({
                url: 'api/report/frequency-check/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
        }),
        getFrequecyCheckReportDetail: builder.query({
            query: (payload) => ({
                url: 'api/report/frequency-check/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'detail'
                }
            })
        }),
        downloadFrequecyCheckReports: builder.query({
            query: (payload) => ({
                url: 'api/report/frequency-check/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'download'
                },
                responseHandler: (res) =>
                    res.blob().then((blob) => ({
                        blob,
                        fileName: res.headers.get('content-disposition').split('filename=')[1],
                        type: res.headers.get('content-type')
                    }))
            })
        }),

        getMissedTreatmentList: builder.query({
            query: (payload) => ({
                url: 'api/scheduler/schedule/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listmissedreason'
                }
            })
        }),

        saveMissedReasons: builder.mutation({
            query: (payload) => ({
                url: 'api/scheduler/schedule/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'updatemissedreason'
                }
            }),
            invalidatesTags: ['DocumentDownload']
        }),
    })
});

export const {
    useLazyGetFrequecyCheckReportQuery,
    useGetFrequecyCheckReportDetailQuery,
    useLazyGetFrequecyCheckReportDetailQuery,
    useLazyDownloadFrequecyCheckReportsQuery,
    useLazyGetMissedTreatmentListQuery,
    useSaveMissedReasonsMutation,
    // useGetTherapistFrequecyCheckReportQuery,
    // useLazyGetTherapistFrequecyCheckReportQuery
} = frequencyCheckReport;
