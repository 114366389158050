import { api } from '../api';

export const eventsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSchedules: builder.query({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ScheduleList']
        }),
        getTherapySchedules: builder.query({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'schedulelist'
                }
            }),
            providesTags: ['TharapistScheduleList']
        }),
        getEventDetails: builder.query({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['EventDetails']
        }),
        getPreferFilter: builder.query({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'preferedlocationstatus'
                }
            }),
            providesTags: ['PreferFilter']
        }),
        createPreferFilter: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'createdefaultlocorstatus'
                }
            }),
            invalidatesTags: ['PreferFilter']
        }),
        updatePreferFilter: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'updatedefaultlocorstatus'
                }
            }),
            invalidatesTags: ['PreferFilter']
        }),
        addEvent: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.sch_id ? 'UPDATE' : 'CREATE'
                }
            }),
            invalidatesTags: ['ScheduleList', 'TharapistScheduleList', 'EventDetails']
        }),
        updateEvent: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'UPDATE'
                }
            }),
            invalidatesTags: ['ScheduleList', 'TharapistScheduleList', 'EventDetails']
        }),

        deleteEvent: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['EventList', 'ScheduleList', 'TharapistScheduleList']
        }),
        deleteAllEvent: builder.mutation({
            query: (payload) => ({
                url: `api/scheduler/schedule/`,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETEAll'
                }
            }),
            invalidatesTags: ['EventList', 'ScheduleList']
        })
    })
});

export const {
    useGetSchedulesQuery,
    useLazyGetSchedulesQuery,
    useGetTherapySchedulesQuery,
    useGetEventDetailsQuery,
    useGetPreferFilterQuery,
    useCreatePreferFilterMutation,
    useUpdatePreferFilterMutation,
    useAddEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useDeleteAllEventMutation
} = eventsApi;
