import { api } from '../api';

export const evaluationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getProgressNotes: builder.query({
            query: (payload) => ({
                url: 'api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ProgressNoteList']
        }),
        getProgressNotesSp: builder.query({
            query: (payload) => ({
                url: 'api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list_sp'
                }
            }),
            providesTags: ['ProgressNoteList']
        }),
        getProgressNoteDetails: builder.query({
            query: (payload) => ({
                url: 'api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['ProgressNoteDetails']
        }),
        saveProgressNotes: builder.mutation({
            query: ({ payload, progressId }) => ({
                url: 'api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: progressId ? 'update' : 'create'
                }
            }),
            invalidatesTags: [
                'ProgressNoteList',
                'ProgressNoteDetails',
                'ScheduleList',
                'EventDetails',
                'TharapistScheduleList',
                'TreatmentMinutes',
                'CopyPreviousGoals'
            ]
        }),
        getPreviousResponses: builder.query({
            query: (payload) => ({
                url: 'api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'goalresponse'
                }
            })
        }),
        deleteProgressNote: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'delete'
                }
            }),
            invalidatesTags: ['ProgressNoteList']
        }),
        signProgressNote: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'sign'
                }
            }),
            invalidatesTags: ['ProgressNoteList', 'ProgressNoteDetails', 'UnsignedReportsList']
        }),
        unlockProgressNote: builder.mutation({
            query: (payload) => ({
                url: '/api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unlock'
                }
            }),
            invalidatesTags: ['ProgressNoteList', 'ProgressNoteDetails']
        }),
        getTreatmentHistory: builder.query({
            query: (payload) => ({
                url: '/api/therapy/progress/activity/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
            // invalidatesTags: ['TreatmentHistory']
        }),
        getPreviousTreatmentMins: builder.query({
            query: (payload) => ({
                url: '/api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'visitedminsprescribedmins'
                }
            }),
            providesTags: ['TreatmentMinutes']
        }),

        createScheduler: builder.mutation({
            query: (payload) => ({
                url: '/api/scheduler/schedule/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'updateschedulegroup'
                }
            }),
        }),
    })
});

export const {
    useGetProgressNotesQuery,
    useGetProgressNotesSpQuery,
    useGetProgressNoteDetailsQuery,
    useSaveProgressNotesMutation,


    useCreateSchedulerMutation,
    useGetPreviousResponsesQuery,
    useDeleteProgressNoteMutation,
    useSignProgressNoteMutation,
    useUnlockProgressNoteMutation,
    useLazyGetTreatmentHistoryQuery,
    useGetPreviousTreatmentMinsQuery,
    useLazyGetProgressNotesSpQuery,
    useLazyGetProgressNoteDetailsQuery,
    
} = evaluationApi;
