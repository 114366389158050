/* eslint-disable arrow-body-style */
import React from 'react';
import PropType from 'prop-types';

import { convertDateToGlobalFormat } from 'utils/dateFormatter';
import TimeLineCard from './card';
import NoData from 'components/reusable/NoData';
import './styles.css';

const Timeline = ({ collectionMap, intersectionRef }) => {
    // if (Object.keys(collectionMap).length === 0) {
    //     return <NoData title="No History Found" />;
    // }

    return (
        <div style={{ width: '100%' }}>
            <div className="page">
                <div className="timeline">
                    {Object.keys(collectionMap)
                        .sort((a, b) => new Date(b) - new Date(a))
                        .map((group) => {
                            return collectionMap[group].length > 0 ? (
                                <div key={group} className="timeline__group">
                                    <span className="timeline__year time" aria-hidden="true">
                                        {convertDateToGlobalFormat(group)}
                                    </span>
                                    <div className="timeline__cards">
                                        {collectionMap[group].map((activity, idx) => {
                                            const [time, median] = activity?.time?.split(' ');
                                            return (
                                                <TimeLineCard
                                                    key={idx}
                                                    action={activity?.action}
                                                    headerPrimary={time}
                                                    headerSecondary={median}
                                                    description={activity?.description}
                                                    footer={activity?.performed_by?.name}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null;
                        })}
                </div>
                <div ref={intersectionRef} />
            </div>
        </div>
    );
};

Timeline.propTypes = {
    collectionMap: PropType.object
};

export default Timeline;
