import React, { useState, useEffect, useRef, useCallback } from 'react';
import Spinner from 'ui-component/Spinner';
// import useSnackbar from 'hooks/useSnackbar';
import Timeline from 'ui-component/Timeline';
import { Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import { AspyreDatePicker as DatePicker } from 'components/common/InputFields';
import { Stack } from '@mui/system';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT } from 'constant';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NoData from '../reusable/NoData';

const validationSchema = yup.object().shape({
    startDate: yup.date().typeError('Please enter a valid date').nullable(),
    endDate: yup
        .date()
        .typeError('Please enter a valid date')
        .min(yup.ref('startDate'), 'End date should be greater than or equal to start date')
        .nullable()
});

const eventList = [
    {
        label: 'Create',
        value: 'create'
    },
    {
        label: 'Update',
        value: 'update'
    },
    {
        label: 'Delete',
        value: 'delete'
    },
    {
        label: 'General',
        value: 'general'
    }
];

const History = ({ data, payload, actions = eventList }) => {
    const ref = useRef();
    const paginationRef = useRef();
    const [page, setPage] = useState(1);

    const [currentData, setCurrentData] = useState({
        fields: [],
        pagination: null
    });

    const [store, setStore] = useState([]);
    const [timeline, setTimeline] = useState({});

    const [filters, setFilters] = useState({
        event: '',
        startFrom: null,
        endTo: null
    });

    const [trigger, result] = data;
    const { currentData: { fields, pagination } = {}, isFetching } = result;

    useEffect(() => {
        if (fields && pagination && !isFetching) {
            setCurrentData({
                fields,
                pagination
            });
        }
    }, [fields, isFetching]);

    useEffect(() => {
        setPage(1);
        setStore([]);
        setCurrentData({
            fields: [],
            pagination: null
        });

        const newPayload = {
            filters: {
                ...payload.filters,
                ...(filters?.event && { event: filters?.event })
            },
            pagination: {
                current_page: 1
            }
        };

        if (filters?.startFrom) {
            newPayload.filters.start_from = moment(new Date(filters?.startFrom)).startOf('day').utc().format('YYYY-MM-DD HH:mm');
        }
        if (filters?.endTo) {
            newPayload.filters.end_to = moment(new Date(filters?.endTo)).endOf('day').utc().format('YYYY-MM-DD HH:mm');
        }

        trigger(newPayload);
    }, [payload, filters.event, filters.startFrom, filters.endTo]);

    useEffect(() => {
        if (page !== 1) {
            const newPayload = {
                filters: {
                    ...payload.filters,
                    event: filters?.event
                },
                pagination: {
                    current_page: page
                }
            };

            if (filters?.startFrom) {
                newPayload.filters.start_from = moment(new Date(filters?.startFrom)).startOf('day').utc().format('YYYY-MM-DD HH:mm');
            }
            if (filters?.endTo) {
                newPayload.filters.end_to = moment(new Date(filters?.endTo)).endOf('day').utc().format('YYYY-MM-DD HH:mm');
            }

            trigger(newPayload);
        }
    }, [page]);

    useEffect(() => {
        const temp = {};

        store?.forEach((day) => {
            const dd = new Date(moment.utc(day.performed_on));
            const localDate = dd.toLocaleString('en-US');

            const [date, time, median] = localDate.replace(',', '').split(' ');
            temp[date] = temp[date]
                ? [...temp[date], { time: `${time?.substring(0, time?.length - 3)} ${median}`, ...day }]
                : [{ time: `${time?.substring(0, time?.length - 3)} ${median}`, ...day }];
        });

        setTimeline(temp);
    }, [store]);

    // useEffect(() => {
    //     const prevTmz = store?.map((s) => s.performed_on);
    //     const currFiltered = fields?.filter((d) => !prevTmz.includes(d.performed_on));

    //     setStore((store) => [...store, ...(currFiltered || [])]);
    // }, [fields]);

    useEffect(() => {
        const newStore = [...store, ...currentData.fields].sort((a, b) => new Date(b.performed_on) - new Date(a.performed_on));

        setStore(newStore);
    }, [currentData]);

    const handleIntersect = useCallback(
        (e) => {
            const target = e[0];
            if (target.isIntersecting && Math.floor(target.intersectionRatio) === 1) {
                setPage((page) => (page < paginationRef?.current?.last_page ? page + 1 : page));
            }
        },
        [page]
    );

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: 1
        });

        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, []);

    useEffect(() => {
        if (currentData.pagination && !paginationRef.current) {
            paginationRef.current = currentData.pagination;
        }
    }, [currentData.pagination]);

    const filterRef = useRef();

    const isSmallContainer = filterRef.current?.clientWidth < 1000;

    return (
        <Grid my={1} ref={filterRef} container spacing={2}>
            {isFetching && <Spinner />}
            <Grid
                sx={{
                    minWidth: 300,
                    order: isSmallContainer ? 0 : 1
                }}
                item
                sm={12}
                md={isSmallContainer ? 8 : 4}
            >
                <HistoryFilter setFilter={setFilters} actions={actions} />
            </Grid>

            <Grid item sm={12} md={isSmallContainer ? 12 : 8}>
                <Timeline collectionMap={timeline} intersectionRef={ref} />
            </Grid>
        </Grid>
    );
};

export default History;

const HistoryFilter = ({ setFilter, actions }) => {
    const formik = useFormik({
        initialValues: {
            event: '',
            startDate: null,
            endDate: null
        },
        validationSchema,
        onSubmit: (values) => {
            setFilter({
                event: values.event,
                startFrom: values.startDate,
                endTo: values.endDate
            });
        }
    });

    const handleFilterSubmit = () => {
        formik.handleSubmit();
    };

    const handleFilterReset = () => {
        formik.resetForm();
        setFilter({
            event: '',
            startFrom: null,
            endTo: null
        });
    };

    return (
        <Box>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="event">Event</InputLabel>
                                <Select
                                    // sx={{ width: '50%', minWidth: 240 }}
                                    value={formik.values.event}
                                    onChange={(e) => formik.setFieldValue('event', e.target.value)}
                                    label="Event"
                                >
                                    {actions.map((ev) => (
                                        <MenuItem key={ev.value} value={ev.value}>
                                            {ev.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        inputFormat={GLOBAL_DATE_FORMAT}
                        margin="normal"
                        maxDate={formik.values.endDate}
                        disableFuture
                        renderInput={(props) => (
                            <TextField
                                fullWidth
                                {...props}
                                inputProps={{
                                    ...props.inputProps
                                }}
                                error={formik.errors?.startDate}
                                helperText={formik.errors?.startDate}
                            />
                        )}
                        value={formik.values.startDate}
                        onChange={(val) => formik.setFieldValue('startDate', val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        inputFormat={GLOBAL_DATE_FORMAT}
                        margin="normal"
                        minDate={formik.values.startDate}
                        disableFuture
                        renderInput={(props) => (
                            <TextField
                                fullWidth
                                {...props}
                                inputProps={{
                                    ...props.inputProps
                                }}
                                error={formik.errors?.endDate}
                                helperText={formik.errors?.endDate}
                            />
                        )}
                        value={formik.values.endDate}
                        onChange={(val) => formik.setFieldValue('endDate', val)}
                    />
                </Grid>
            </Grid>

            <Stack direction="row" spacing={2}>
                <Button variant="contained" onClick={handleFilterSubmit}>
                    Search
                </Button>
                <Button variant="contained" onClick={handleFilterReset}>
                    Reset
                </Button>
            </Stack>
        </Box>
    );
};
