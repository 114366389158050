import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Pagination } from '@mui/material';

import { makeStyles } from '@mui/styles';
import Spinner from 'ui-component/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDataGrid-columnHeaders': {
            background: theme.palette.grey[200],
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            color: theme.palette.grey[800]
        },
        '& .MuiDataGrid-columnSeparator': {
            color: theme.palette.grey[200]
        },
        '& .MuiDataGrid-row:not(:last-child)': {
            borderBottom: `2px solid ${theme.palette.grey[300]}`
        },
        '& .MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${theme.palette.grey[300]}`
        }
    },
    muted: {
        background: theme.palette.grey[200]
    }
}));

export function CPagination({ totalCount, countPerPage, currentPage, onPageChange }) {
    return (
        <Box sx={{ my: 1 }}>
            <Pagination
                color="primary"
                showFirstButton
                showLastButton
                count={Math.ceil((totalCount || 1) / (countPerPage || 1))}
                page={currentPage}
                onChange={onPageChange}
            />
        </Box>
    );
}

const CTable = forwardRef((props, ref) => {
    const {
        loading,
        rows,
        cols,
        currentPage,
        totalCount,
        countPerPage,
        onPageChange,
        handleSortModelChange,
        pagination = true,
        rowHeight = 60,
        components,
        autoHeight,
        disabledKeyName = '',
        initialState = {},
        ...rest
    } = props;
    const styles = useStyles();

    return (
        <>
            {loading && <Spinner />}
            <DataGrid
                ref={ref}
                sortingMode="server"
                autoHeight={autoHeight}
                // loading={loading}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                disableVirtualization
                rows={rows}
                getRowId={(row) => row.id}
                columns={cols}
                rowHeight={rowHeight}
                className={styles.root}
                hideFooterPagination={!pagination}
                onSortModelChange={handleSortModelChange}
                {...(disabledKeyName && {
                    getRowClassName: (params) => (params?.row?.[disabledKeyName] ? '' : styles.muted)
                })}
                components={{
                    ...components,
                    ...(pagination && {
                        Pagination: () => (
                            <CPagination
                                totalCount={totalCount}
                                currentPage={currentPage}
                                countPerPage={countPerPage}
                                onPageChange={onPageChange}
                            />
                        )
                    })
                }}
                localeText={{ noRowsLabel: 'No Data Found' }}
                initialState={{
                    sorting: {
                        sortModel: [initialState]
                    }
                }}
                {...rest}
            />
        </>
    );
});

CPagination.propTypes = {
    totalCount: PropTypes.number,
    countPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func
};

CTable.propTypes = {
    loading: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    cols: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    countPerPage: PropTypes.number,
    pagination: PropTypes.bool,
    onPageChange: PropTypes.func,
    rowHeight: PropTypes.number,
    components: PropTypes.node,
    disabledKeyName: PropTypes.string,
    autoHeight: PropTypes.bool, // Boolean to control the table's height adjustment behavior.
    handleSortModelChange: PropTypes.func, // A function to handle changes to the column sort model.
    initialState: PropTypes.object // The initial state for the table, typically includes the default sort column and order.
};

export default CTable;

export const colProps = {
    sortable: false,
    flex: 1,
    minWidth: 100
};
