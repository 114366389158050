import { api } from './api';

export const commonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getHistory: builder.query({
            query: ({ url, payload, subType }) => ({
                url,
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: subType
                }
            }),
            providesTags: ['ClientList']
        }),
        getICDList: builder.query({
            query: (payload) => ({
                url: 'api/core/icd/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ICDList']
        }),
        getScriptIssueList: builder.query({
            query: (payload) => ({
                url: 'api/core/script-issue/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ScriptIssueList']
        }),
        getPlaceOfServicesList: builder.query({
            query: (payload) => ({
                url: 'api/core/place-of-service/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['PlaceofServiceList']
        }),
        getServiceTypeList: builder.query({
            query: (payload) => ({
                url: 'api/core/service-types/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listtrpy'
                }
            })
        }),
        getServices: builder.query({
            query: (payload) => ({
                url: 'api/core/service/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
        }),
        getStatusList: builder.query({
            query: (payload) => ({
                url: 'api/core/status/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
        }),
        resetEmpPassword: builder.mutation({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'resetpassword'
                }
            })
        })
    })
});

export const {
    useGetHistoryQuery,
    useGetICDListQuery,
    useGetScriptIssueListQuery,
    useGetPlaceOfServicesListQuery,
    useGetServiceTypeListQuery,
    useGetServicesQuery,
    useGetStatusListQuery,
    useResetEmpPasswordMutation
} = commonApi;
