/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
import { Popover, Box, Tabs, Tab, Stack, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import usePermission from 'hooks/usePermission';
import { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    popoverContent: {
        pointerEvents: 'auto'
    }
}));

const TabPanel = ({ children, value, index, ...rest }) => {
    return (
        <div role="tabpanel" hidden={value !== index} {...rest}>
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

const PanelBuilder = (idx, value, shorthand, permissions, userPermissions, setMissedNoteOpen, setService) => {
    return (
        <TabPanel value={value} index={idx}>
            <Stack spacing={1} sx={{ alignItems: 'start' }}>
                {userPermissions?.includes(`${shorthand}_eval_view`) && (
                    <Link href={`/therapy/${shorthand}/evaluation/create`} color="inherit" underline="none">
                        <Box
                            sx={{
                                width: '100%',
                                p: 2,
                                borderRadius: 2,
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#1b357c',
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                        >
                            <Typography sx={{ fontWeight: 500 }}>Evaluation</Typography>
                        </Box>
                    </Link>
                )}
                {userPermissions?.includes(`${shorthand}_note_view`) && (
                    <Link href={`/therapy/${shorthand}/treatment/create`} color="inherit" underline="none">
                        <Box
                            sx={{
                                width: '100%',
                                p: 2,
                                borderRadius: 2,
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#1b357c',
                                    backgroundColor: '#FFFFFF'
                                }
                            }}
                        >
                            <Typography sx={{ fontWeight: 500 }}>Treatment</Typography>
                        </Box>
                    </Link>
                )}
                <Box
                    sx={{
                        width: '100%',
                        p: 2,
                        borderRadius: 2,
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#1b357c',
                            backgroundColor: '#FFFFFF'
                        }
                    }}
                    onClick={() => {
                        setMissedNoteOpen(true);
                        setService(shorthand);
                    }}
                >
                    <Typography sx={{ fontWeight: 500 }}>Add Missed Note</Typography>
                </Box>
            </Stack>
        </TabPanel>
    );
};

const PopoverNavigation = ({ open, anchorEl, openPopover, closePopover, innerList, permissionsList, setMissedNoteOpen, setService }) => {
    const classes = useStyles();

    const { isSuperAdmin, isServiceAvailable } = usePermission();

    const [value, setValue] = useState(0);

    const handleChange = (_e, nv) => {
        setValue(nv);
    };

    const handleNavHover = (nv) => {
        setValue(nv);
    };

    useEffect(() => {
        const val = innerList?.filter((item) => {
            const atleastOnePerExist = item?.permission?.filter((p) => permissionsList.includes(p));
            return atleastOnePerExist.length > 0 && (isSuperAdmin || isServiceAvailable(item?.shorthand));
        });
        if (val.length > 0) {
            setValue(val?.[0]?.id);
        }
    }, [innerList]);

    return (
        <Popover
            className={classes.popover}
            classes={{
                paper: classes.popoverContent
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            PaperProps={{
                onMouseEnter: openPopover,
                onMouseLeave: closePopover,
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    borderRadius: 0,
                    overflow: 'visible'
                }
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    marginTop: '10px',
                    '&::before': {
                        backgroundColor: '#FFFFFF',
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        width: 12,
                        height: 12,
                        top: -6,
                        transform: 'rotate(45deg)',
                        left: 'calc(50% - 6px)'
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        p: 2,
                        borderRadius: 3,
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 0px 20px 1px rgba(0,0,0,0.5)'
                    }}
                >
                    <Tabs
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                border: 0
                            }
                        }}
                        orientation="vertical"
                        value={value}
                        onChange={handleChange}
                    >
                        {innerList?.map((item) => {
                            const atleastOnePerExist = item?.permission?.filter((p) => permissionsList.includes(p));
                            if (atleastOnePerExist.length > 0 && (isSuperAdmin || isServiceAvailable(item?.shorthand))) {
                                return (
                                    <Tab
                                        key={item?.label}
                                        value={item?.id}
                                        sx={{
                                            alignItems: 'start',
                                            marginRight: 2,
                                            borderRadius: 2,
                                            '&:hover': {
                                                backgroundColor: '#f0f0f0'
                                            }
                                        }}
                                        label={item?.label}
                                        onMouseOver={() => {
                                            handleNavHover(item?.id);
                                            setService(item?.shorthand);
                                        }}
                                    />
                                );
                            } else return null;
                        })}
                    </Tabs>
                    <Box sx={{ backgroundColor: '#f0f0f0', ml: 3, borderRadius: 2, maxWidth: 300 }}>
                        {innerList?.map((item, idx) =>
                            PanelBuilder(value, idx, item?.shorthand, item?.permission, permissionsList, setMissedNoteOpen, setService)
                        )}
                    </Box>
                </Box>
            </Box>
        </Popover>
    );
};

export default PopoverNavigation;
