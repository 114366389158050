import { api } from '../api';

export const unsingedProvidedReport = api.injectEndpoints({
    endpoints: (builder) => ({
        getUnsignedReports: builder.query({
            query: (payload) => ({
                url: 'api/report/unsigned-notes-reports/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unsignednotesreport'
                }
            }),
            providesTags: ['UnsignedReportsList']
        }),
        getUnsignedReportIds: builder.query({
            query: (payload) => ({
                url: 'api/report/unsigned-notes-reports/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unsignednotesreport'
                }
            }),
            providesTags: ['UnsignedReportsList']
        }),
        downloadUnsignedReports: builder.query({
            query: (payload) => ({
                url: 'api/report/unsigned-notes-reports/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'unsignednotesreport'
                },
                responseHandler: (res) =>
                    res.blob().then((blob) => ({
                        blob,
                        fileName: res.headers.get('content-disposition').split('filename=')[1],
                        type: res.headers.get('content-type')
                    }))
            })
        }),

        bulkSign: builder.mutation({
            query: (payload) => ({
                url: 'api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'supervisorsign'
                }
            }),
            invalidatesTags: ['UnsignedReportsList']
        })
    })
});

export const {
    useLazyGetUnsignedReportsQuery,
    useLazyGetUnsignedReportIdsQuery,
    useLazyDownloadUnsignedReportsQuery,
    useBulkSignMutation
} = unsingedProvidedReport;
