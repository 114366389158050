/**
 * Converts a JavaScript object (payload) into a FormData object.
 * This function is useful when you need to send data via HTTP requests,
 * particularly when working with file uploads or multipart/form-data.
 *
 * @param {Object} payload - The JavaScript object to be converted to FormData.
 * @returns {FormData} The FormData object containing the payload data.
 *
 * @example
 * const payload = {
 *   name: 'John Doe',
 *   age: 30,
 *   hobbies: ['Reading', 'Gardening'],
 *   avatar: FileObject, // A File object from an <input type="file"> element
 * };
 *
 * const formData = payloadToFormData(payload);
 * // Now you can use formData to send the data via an HTTP request.
 */
export const payloadToFormData = (payload) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
        if (Array.isArray(payload[key])) {
            payload[key].forEach((value) => {
                formData.append(key, value);
            });
        } else {
            formData.append(key, payload[key]);
        }
    });

    return formData;
};
