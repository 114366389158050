/* eslint-disable */
import React, { useState, memo } from 'react';
import { FormControl, InputLabel, TextField, Button, Typography, Box, Stack, Grid, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

import useSnackbar from 'hooks/useSnackbar';
import { useSignEvaluationMutation } from 'store/services/therapy/evaluation';
import { useSignProgressNoteMutation } from 'store/services/therapy/treatment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import { Close } from '@mui/icons-material';
import { GLOBAL_DATE_FORMAT } from 'constant';
import { useBulkSignMutation } from 'store/services/reports/unsignedReport';
import Spinner from 'ui-component/Spinner';
import { useItpSignMutation } from 'store/services/school/itp';
import { useActivityLogSignMutation } from 'store/services/logs';

export const SIGN_VISIT_TYPE = {
    EVALUATION: 'Evaluation',
    TREATMENT: 'Treatment',
    ITP: 'ITP',
    REVIEW: 'Quarter Review',
    ACTIVITY: 'Activity',
    COMPENSATORY: 'Compensatory Treatment'
};

function SignModal({ type, data, treatmentInfo, handleFormSubmit, isViewOnly = false }) {
    const { user } = useAuth();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="therapy-sign" sx={{ my: 2 }}>
            {(data?.employee.id === user?.emp_id || user.supervisee.includes(data?.employee.id)) &&
                !data?.is_full_signed &&
                data?.signed_therapist_emp?.id !== user?.emp_id &&
                !isViewOnly && (
                    <>
                        <Button sx={{ mb: 2 }} variant="contained" onClick={handleOpen}>
                            E-Sign
                        </Button>
                        <SignForm
                            open={open}
                            onClose={handleClose}
                            type={type}
                            data={data}
                            treatmentInfo={treatmentInfo}
                            handleFormSubmit={handleFormSubmit}
                        />
                    </>
                )}

            <SignView data={data} />
        </Box>
    );
}

export default memo(SignModal);

export const SignForm = ({
    open,
    onClose,
    type,
    data,
    treatmentInfo,
    handleFormSubmit,
    bulkSignData,
    setSignFailedTherapy,
    clearCheckboxes
}) => {
    const { notify } = useSnackbar();
    const { user } = useAuth();
    const [signEval, { isLoading }] = useSignEvaluationMutation();
    const [signProgress, { isLoading: signLoading }] = useSignProgressNoteMutation();
    const [signITP, { itpSignLoading }] = useItpSignMutation();
    const [signActivity, { isLoading: activitySignLoading }] = useActivityLogSignMutation();

    const [bulkSignTherapy, { isLoading: bulkSignLoading }] = useBulkSignMutation();

    const handleSave = async () => {
        try {
            await handleFormSubmit(null, true);
        } catch (error) {
            console.log({ error });
        }
    };
    const handleSign = async (values, { resetForm }) => {
        console.log({ values });
        if (handleFormSubmit) await handleSave();

        try {
            if (bulkSignData) {
                const payload = {
                    ...(bulkSignData?.evaluatonIds?.length > 0 && { evaluation_ids: bulkSignData?.evaluatonIds }),
                    ...(bulkSignData?.treatmentIds?.length > 0 && { progress_ids: bulkSignData?.treatmentIds }),
                    ...(bulkSignData?.itpIds?.length > 0 && { itp_sign_ids: bulkSignData?.itpIds }),
                    ...(bulkSignData?.activityIds?.length > 0 && { activity_ids: bulkSignData?.activityIds }),
                    client_id: user?.client_id,
                    password: values.password
                };
                const res = await bulkSignTherapy(payload).unwrap();
                console.log(res);
                onClose();
                resetForm();

                if (res.sign_block_data?.length > 0) {
                    setSignFailedTherapy(res.sign_block_data);
                    notify({
                        message: "Some of the selected records couldn't be signed. Please check the reasons.",
                        severity: 'warning'
                    });
                } else {
                    notify({
                        message: res.message,
                        severity: 'success'
                    });
                }
                if (clearCheckboxes) clearCheckboxes();
            } else {
                if (type === SIGN_VISIT_TYPE.EVALUATION) {
                    const payload = {
                        id: data.id,
                        password: values.password
                    };

                    const res = await signEval(payload).unwrap();
                    onClose();
                    resetForm();
                    notify({
                        message: res.message,
                        severity: 'success'
                    });
                } else if (type === SIGN_VISIT_TYPE.TREATMENT) {
                    // treatment group sign handled
                    if (treatmentInfo?.isGroup) {
                        const promiseArrry = treatmentInfo.savedIds.map((item) => {
                            if (item) {
                                return signProgress({
                                    id: item,
                                    password: values.password
                                }).unwrap();
                            }
                        });

                        const res = await Promise.all(promiseArrry);
                        console.log({ res });
                        res.forEach((item) => {
                            if (item?.error) {
                                notify({
                                    message: item?.error?.data?.message || 'Something Went Wrong',
                                    severity: 'error'
                                });
                            } else {
                                notify({
                                    message: item?.message,
                                    severity: 'success'
                                });
                                onClose();
                                resetForm();
                            }
                        });
                        // usinged report bulk sign
                    } else {
                        // normal sign
                        const payload = {
                            id: data.id,
                            password: values.password
                        };
                        const res = await signProgress(payload).unwrap();
                        onClose();
                        resetForm();
                        notify({
                            message: res.message,
                            severity: 'success'
                        });
                    }
                } else if (type === SIGN_VISIT_TYPE.ITP || type === SIGN_VISIT_TYPE.REVIEW) {
                    const payload = {
                        id: data.id,
                        password: values.password
                    };
                    const res = await signITP(payload).unwrap();
                    onClose();
                    resetForm();
                    notify({
                        message: res.message,
                        severity: 'success'
                    });
                } else if (type === SIGN_VISIT_TYPE.ACTIVITY) {
                    const payload = {
                        activity_log_id: Number(data?.activity_id),
                        client_id: user?.client_id,
                        password: values.password
                    };
                    const res = await signActivity(payload).unwrap();
                    onClose();
                    resetForm();
                    notify({
                        message: res.message,
                        severity: 'success'
                    });
                } else if (type === SIGN_VISIT_TYPE.COMPENSATORY) {
                    if (treatmentInfo?.isGroup) {
                        const promiseArrry = treatmentInfo.savedIds.map((item) => {
                            return signProgress({
                                id: item,
                                password: values.password,
                                is_compensatory: 1,
                                is_evaluation: 0
                            }).unwrap();
                        });

                        const res = await Promise.all(promiseArrry);
                        console.log({ res });
                        res.forEach((item) => {
                            if (item.error) {
                                notify({
                                    message: item.error.data.message || 'Something Went Wrong',
                                    severity: 'error'
                                });
                            } else {
                                notify({
                                    message: item.message,
                                    severity: 'success'
                                });
                                onClose();
                                resetForm();
                            }
                        });
                        // usinged report bulk sign
                    } else {
                        // normal sign
                        const payload = {
                            id: data.id,
                            password: values.password,
                            is_compensatory: 1,
                            is_evaluation: 0
                        };
                        const res = await signProgress(payload).unwrap();
                        onClose();
                        resetForm();
                        notify({
                            message: res.message,
                            severity: 'success'
                        });
                    }
                }
            }
        } catch (error) {
            console.log(error);
            notify({
                message: error.data?.message || 'Something Went Wrong',
                severity: 'error'
            });
        }
    };

    const validationSchema = yup.object({
        password: yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            password: ''
        },
        validationSchema,
        onSubmit: handleSign
    });

    return (
        <div className="sign-modal">
            {(signLoading || bulkSignLoading || itpSignLoading || activitySignLoading) && <Spinner />}

            <Dialog
                open={open}
                onClose={() => {
                    formik.resetForm();
                    onClose();
                }}
                fullWidth
                maxWidth="xs"
            >
                <IconButton
                    onClick={() => {
                        formik.resetForm();
                        onClose();
                    }}
                    sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
                >
                    <Close />
                </IconButton>
                <DialogTitle>{bulkSignData ? 'Bulk E-Sign' : 'E-Sign'}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent
                        sx={{
                            p: 2
                        }}
                    >
                        <TextField
                            // sx={{ mt: 1 }}
                            label="Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                            autoComplete="off"
                            {...formik.getFieldProps('password')}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            type="submit"
                            loading={signLoading || bulkSignLoading || itpSignLoading || activitySignLoading}
                            variant="contained"
                        >
                            Sign
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export function SignView({ data }) {
    return (
        <div className="e-sign">
            {data?.is_signed_by_therapist && (
                <div className="sign-info">
                    <p>Signed by</p>
                    <p>
                        <b>
                            {data?.signed_therapist_emp?.name || ''}, {data?.signed_therapist_emp?.credentials || ''}
                        </b>{' '}
                        on <b>{moment.utc(data?.therapist_sign_date).local().format(`${GLOBAL_DATE_FORMAT} hh:mm A`)}</b>
                    </p>
                </div>
            )}
            {data?.is_signed_by_supervisor && (
                <div className="sign-info">
                    <p>Signed by</p>
                    <p>
                        <b>
                            {data?.signed_supervisor_emp?.name || ''}, {data?.signed_supervisor_emp?.credentials || ''}
                        </b>{' '}
                        on <b>{moment.utc(data?.supervisor_sign_date).local().format(`${GLOBAL_DATE_FORMAT} hh:mm A`)}</b>
                    </p>
                </div>
            )}
        </div>
    );
}
