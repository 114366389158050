import { Box, Typography } from '@mui/material';
import React from 'react';

function NoData({ title = 'No Data', sx }) {
    return (
        <Box
            sx={{
                py: 5,
                ...sx
            }}
        >
            <Typography
                textAlign="center"
                sx={{
                    fontSize: 16,
                    fontWeight: 400,
                    color: '#9e9e9e'
                }}
                variant="h3"
                gutterBottom
                component="h3"
            >
                {title}
            </Typography>
        </Box>
    );
}

export default NoData;
