/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    Grid,
    Stack,
    Typography,
    Button,
    FormControl,
    TextField,
    Autocomplete,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';

import { Add as AddIcon, Close } from '@mui/icons-material';
import Spinner from 'ui-component/Spinner';
import moment from 'moment';
import useSnackbar from 'hooks/useSnackbar';
import { AspyreDatePicker as DatePicker } from 'components/common/InputFields';
import { getServiceNameById, GLOBAL_DATE_FORMAT } from 'constant';
import axios from 'axios';
import { useMissedReasonsListQuery, useSendErrorLogMailMutation } from 'store/services/core';
import { useLazyGetMissedTreatmentListQuery, useSaveMissedReasonsMutation } from 'store/services/reports/frequencyCheckReport';
import { LoadingButton } from '@mui/lab';
import { PatientDropdown } from 'components/reusable/Dropdowns';
import useAuth from 'hooks/useAuth';
import { changeFormat, utcToLocal } from 'utils/time-conversion';
import _ from 'lodash';
import { useGetTherapistListQuery } from 'store/services/employee';

function getCustomWeekNumber(date) {
    const firstDayOfYear = moment(new Date(date)).startOf('year');
    const firstWeekofYear = moment(firstDayOfYear).startOf('week').format('YYYY'); // Get ISO week of first day of the year
    const currentWeek = moment(new Date(date)).week(); // Get ISO week of given date
    const currentMonth = moment(new Date(date)).format('MM');
    const currentYear = moment(new Date(date)).format('YYYY');
    const totalWeeksPreviousYear = moment(new Date(firstWeekofYear)).isoWeeksInYear(); // Get total weeks in the year

    if (firstWeekofYear < currentYear) {
        if (currentWeek === 1) {
            return totalWeeksPreviousYear;
        }
        return currentWeek - 1;
    }
    if (currentMonth === '12' && currentWeek === 1) {
        return totalWeeksPreviousYear + 1;
    }

    return currentWeek;
}

//  validation schema
const validationSchema = Yup.object({
    patientSelect: Yup.object().typeError('Patient is required').nullable().required('Patient is required'),
    location: Yup.object().typeError('Location is required').nullable().required('Location is required'),
    dateSelect: Yup.date().typeError('Please enter a valid date').nullable().required('Date is required')
});

const validationSchemaTwo = Yup.object({
    missedSessionReason: Yup.array()
        .of(
            Yup.object().shape({
                date: Yup.date().required('Date is required').nullable().typeError('Date is required'),
                therapist: Yup.object().required('Therapist is required').nullable(),
                startTime: Yup.date().required('Start time is required').nullable(),
                endTime: Yup.date().required('End time is required').nullable(),
                hour: Yup.number().required('Hour is required').nullable(),
                minute: Yup.number().required('Minute is required').nullable(),
                reason: Yup.object().required('Reason is required').nullable(),
                remark: Yup.string().nullable().max(250, 'Maximum 250 characters only allowed')
            })
            // .test('is-row-filled', 'Please fill all fields in the row', (value) =>
            //     Object.values(value).every((field) => field !== null && field !== '')
            // )
        )
        .optional()
});

// eslint-disable-next-line react/prop-types
const AddMissedNote = ({ open, serviceId, setMissedNoteOpen, type = '' }) => {
    const { notify } = useSnackbar();
    const { user } = useAuth();

    // API SERVICES
    const {
        data: missedReasons,
        isFetching: isMissedReasonsFetching,
        isLoading: isMissedReasonsLoading
    } = useMissedReasonsListQuery({}, { skip: !open });
    const [
        trigger,
        { data: missedTreatment, isFetching: missedTreatmentFetching, isLoading: missedTreatmentLoading, isSuccess: missedTreatmentSuccess }
    ] = useLazyGetMissedTreatmentListQuery();
    const [triggerSave, { isLoading: saveLoading }] = useSaveMissedReasonsMutation();

    const {
        data: therapistData,
        error: therapistError,
        isLoading: therapistIsLoading
    } = useGetTherapistListQuery(
        {
            filters: {
                status: 1,
                ...(user?.client_id && { clients: [user.client_id] })
            }
        },
        { skip: !user?.client_id || !open }
    );
    const [sendErrorLogMail, { isLoading }] = useSendErrorLogMailMutation();

    const accessToken = window.localStorage.getItem('accessToken');
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const [firstSearch, setFirstSearch] = useState(false);
    const [filterInitial, setFilterInitial] = useState({
        patientSelect: null,
        location: null,
        dateSelect: null
    });
    const patientRef = useRef(null);

    const [openCreateConfirm, setOpenCreateConfirm] = useState(null);

    const handleOpenConfirm = (data) => {
        setOpenCreateConfirm(data);
    };

    const handleCloseConfirm = () => {
        setOpenCreateConfirm(null);
    };

    const generateHour = () => {
        const hours = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 13; i++) {
            hours.push({ value: i, label: i });
        }

        return hours;
    };

    const generateMinute = () => {
        const minute = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 61; i += 5) {
            if (i === 60) {
                minute.push({ value: 59, label: 59 });
            } else {
                minute.push({ value: i, label: i });
            }
        }

        return minute;
    };

    const hours = generateHour();
    const minute = generateMinute();

    // FORMIK INITIALIZATION
    const formik = useFormik({
        initialValues: {
            patientSelect: null,
            location: null,
            dateSelect: null
        },
        validationSchema,
        onSubmit: () => {
            // eslint-disable-next-line no-use-before-define
            callNotesList();
        }
    });

    const formikV2 = useFormik({
        initialValues: {
            missedSessionReason: [],
            missedMinutes: ''
        },
        validationSchema: validationSchemaTwo,
        onSubmit: () => {
            // eslint-disable-next-line no-use-before-define
            // callNotesList();
        }
    });

    const [startLimit, setStartLimit] = useState(null);
    const [endDateLimit, setEndDateLimit] = useState(null);

    const [remainingTime, setRemainingTime] = useState(formikV2?.values?.missedMinutes);
    const [locations, setLocations] = useState([]);
    const [locLoading, setLocLoading] = useState(true);

    // CALCULATE TOTAL MINUTES ADDED
    const calculateTotalMinutes = (missedSessionReason) => {
        return missedSessionReason.reduce((acc, item) => {
            if (item.startTime && item.endTime) {
                const startTime = new Date(item.startTime);
                const endTime = new Date(item.endTime);

                // Calculate the difference in milliseconds
                const differenceInMilliseconds = endTime - startTime;

                // Convert the difference to seconds, minutes, hours, or days
                const differenceInSeconds = differenceInMilliseconds / 1000;
                const differenceInMinutes = differenceInSeconds / 60;

                return acc + differenceInMinutes;
            }
            return acc;
        }, 0);
    };

    const formatDate = (date) => {
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format YYYY-MM-DD (Local Time)
    };

    const callNotesList = async () => {
        try {
            const convertedStartDate = moment(startLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            const convertedEndDate = moment(endDateLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            if (
                startLimit === 'Invalid date' ||
                endDateLimit === 'Invalid date' ||
                convertedStartDate === 'Invalid date' ||
                convertedEndDate === 'Invalid date'
            ) {
                notify({
                    message: 'Date is invalid',
                    severity: 'warning'
                });
                const payload = {
                    error: {
                        params: {
                            mode: 'treatment',
                            type,
                            date: formik?.values?.dateSelect,
                            start_date: startLimit,
                            end_date: endDateLimit,
                            converted_start_date: moment(startLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                            converted_end_date: moment(endDateLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                            service: [serviceId],
                            location: [formik?.values?.location?.location?.id],
                            patient: [formik?.values?.patientSelect?.id]
                        },
                        timestamp: new Date().toISOString(),
                        module: 'Add Missed Notes',
                        userAgent: navigator.userAgent,
                        url: window.location.href
                    }
                };
                sendErrorLogMail(payload);
            } else {
                const res = await trigger({
                    mode: 'treatment',
                    start_date: moment(startLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                    end_date: moment(endDateLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                    service: [serviceId],
                    location: [formik?.values?.location?.location?.id],
                    patient: [formik?.values?.patientSelect?.id]
                }).unwrap();
                formikV2.setFieldValue('missedMinutes', res?.missed_minutes);
                setFirstSearch(true);
                updateList(res?.fields);
                setFilterInitial({
                    dateSelect: formik?.values?.dateSelect,
                    location: formik?.values?.location?.location?.id,
                    patient: formik?.values?.patientSelect?.id
                });
            }
        } catch (error) {
            console.log({ error });
            notify({
                message: error.data?.message || 'Something went wrong',
                severity: 'error'
            });
        }
    };

    const handleDateChange = async (val) => {
        formik.setFieldValue('dateSelect', val);
        const startDate = moment(new Date(val)).startOf('week').startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = moment(new Date(val)).endOf('week').endOf('day').format('YYYY-MM-DD HH:mm:ss');

        setStartLimit(startDate);
        setEndDateLimit(endDate);
    };

    const updateList = async (missedTreatment, add = true) => {
        if (missedTreatment?.length > 0) {
            const objectsList = missedTreatment?.map((field) => {
                const startDate = moment.utc(field.missed_start_date).local();
                const endDate = moment.utc(field.missed_end_date).local();

                // Calculate the difference in minutes
                const durationInMinutes = endDate.diff(startDate, 'minutes');

                // Convert the duration into hours and minutes
                const hours = Math.floor(durationInMinutes / 60);
                const minutes = durationInMinutes % 60;

                return {
                    id: field.id,
                    date: startDate,
                    therapist: field.employees[0],
                    hour: field.progress_id ? '' : hours,
                    minute: field.progress_id ? '' : minutes,
                    startTime: field.progress_id ? null : startDate,
                    endTime: field.progress_id ? null : endDate,
                    reason: field.missed_reason,
                    remark: field.missed_reason_remark,
                    progress_id: field.progress_id
                };
            });
            const newObjects = [
                ...objectsList,
                add && {
                    id: null,
                    date: formik?.values?.dateSelect,
                    hour: '',
                    minute: '',
                    therapist: null,
                    startTime: null,
                    endTime: null,
                    reason: null,
                    remark: '',
                    progress_id: null
                }
            ].filter(Boolean);
            formikV2.setFieldValue('missedSessionReason', newObjects);
            const totalMinutes = calculateTotalMinutes(newObjects);
            setRemainingTime(totalMinutes);
        } else {
            // POPULATE DEFAULT ROW IF API RETURNS []

            formikV2.setFieldValue('missedSessionReason', [
                {
                    id: null,
                    date: formik?.values?.dateSelect,
                    hour: '',
                    minute: '',
                    therapist: null,
                    startTime: null,
                    endTime: null,
                    reason: null,
                    remark: '',
                    progress_id: null
                }
            ]);
            const totalMinutes = calculateTotalMinutes([]);
            setRemainingTime(totalMinutes);
        }
    };

    useEffect(() => {
        setLocations([]);
        if (formik.values.patientSelect?.id) {
            const payload = {
                patient_id: formik.values.patientSelect.id,
                service_id: serviceId
                // ...(progressId && { source: 'update' })
            };
            if (user?.client_id) {
                payload.client_id = user.client_id;
                payload.is_provides_compensatory_services = formik.values.is_compensatory;
            }
            setLocLoading(true);
            axios
                .post(`${API_ENDPOINT}/api/therapy/`, payload, {
                    headers: {
                        SUBTYPE: 'patientnotelocation',
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((response) => {
                    if (response.data?.fields?.length > 0) {
                        setLocations(response.data?.fields);

                        if (response.data?.fields.length === 1) {
                            formik.setFieldValue('location', response.data?.fields[0]);
                        }
                    }
                    setLocLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLocLoading(false);
                });
        }
    }, [formik.values.patientSelect]);

    // ADD ADDITIONAL MISSED REASONS
    const addReasonField = () => {
        // Calculate total minutes from missedSessionReason
        const totalMinutes = calculateTotalMinutes(formikV2.values.missedSessionReason);

        if (totalMinutes > formikV2?.values?.missedMinutes && formikV2?.values?.missedMinutes >= 0) {
            notify({
                message: 'No missed time remains to be added.',
                severity: 'warning'
            });
            return;
        }
        setRemainingTime(totalMinutes);

        const newReason = {
            date: null,
            therapist: null,
            hour: '',
            minute: '',
            startTime: null,
            endTime: null,
            reason: null,
            remark: '',
            progress_id: null,
            id: null
        };
        const newAdditionalReason = [...formikV2.values.missedSessionReason, newReason];

        formikV2.setFieldValue('missedSessionReason', newAdditionalReason);
    };

    // UPDATE FORMIK BASED ON DATA CHANGE
    const updateFieldValue = (index, field, value) => {
        const newReason = [...formikV2.values.missedSessionReason];
        newReason[index] = {
            ...newReason[index],
            [field]: value
        };
        formikV2.setFieldValue('missedSessionReason', newReason);
    };

    // CHECK DUPLICATE TIME THERAPIST COMPINATION
    const checkForDuplicates = (data) => {
        const seen = new Set(); // To track unique emp_id and date combinations
        const duplicates = []; // To store duplicate entries

        for (const entry of data) {
            const key = `${entry.therapist.emp_id}-${entry.date}`; // Create a unique key

            if (seen.has(key)) {
                duplicates.push(entry); // Add to duplicates if key already exists
            } else {
                seen.add(key); // Add key to the set
            }
        }

        if (duplicates.length > 0) {
            // Display error message to the user
            return `Same date has already been selected for ${duplicates.map((d) => d.therapist.name).join(', ')} `;
        }

        return false;
    };

    // HANDLE SAVE BUTTON CLICK
    const handleSubmit = async () => {
        try {
            formikV2.values.missedSessionReason.map((item) => {
                if (item.hour != null && item.hour !== '' && item.minute != null && item.minute !== '') {
                    // item.startTime = moment(new Date(item.date)).startOf('day').utc().format('YYYY-MM-DD HH:mm');
                    // console.log('item::', item.starTime);
                    item.startTime = moment(new Date(item.date)).startOf('day').utc().add(12, 'hours').format('YYYY-MM-DD HH:mm');

                    const startOfDay = moment(new Date(item.date)).startOf('day').utc().add(12, 'hours');

                    // Add item.hour and item.minute to the start of the day
                    const updatedStartTime = startOfDay.add(item.hour, 'hours').add(item.minute, 'minutes');
                    item.endTime = updatedStartTime.format('YYYY-MM-DD HH:mm');
                    // console.log(item.starTime, item.endTime);
                }

                return item;
            });
            formikV2.submitForm();
            const errors = await formikV2.validateForm();
            // console.log(errors);

            if (errors?.missedSessionReason?.length > 0) {
                // alert();
                return;
            }
            try {
                const sessionDates = formikV2.values.missedSessionReason.map((item) => {
                    // console.log(item);

                    if (item?.id) {
                        item.date = formatDate(item.date);
                    } else {
                        //  stest
                        const localDate = moment.utc(item.date).local();
                        item.date = formatDate(item.date);
                    }
                    return item; // Handle string case
                });

                // -------- CHECK DUPLICATION -----------
                const result = checkForDuplicates(sessionDates);
                if (result) {
                    notify({
                        message: result,
                        severity: 'warning'
                    });
                    return;
                }
                // --------END CHECK DUPLICATION -----------

                // -------- CALCULATE REMAINING TIME -----------
                const totalMinutes = calculateTotalMinutes(formikV2.values.missedSessionReason);
                if (totalMinutes > formikV2?.values?.missedMinutes && formikV2?.values?.missedMinutes >= 0) {
                    notify({
                        message: 'Total minutes entered exceed the available missed time.',
                        severity: 'warning'
                    });
                    return;
                }
                setRemainingTime(totalMinutes);
                // --------END REMAINING TIME  -----------

                // ------------HANDLE DELETE ARRAY--------------
                // Find objects in orgArray that are not in teampArray based on id
                const orgArray = missedTreatment?.fields || [];
                const teampArray = formikV2.values.missedSessionReason || [];

                const deleteArray = orgArray?.filter(
                    (missedTreatmentItem) => !teampArray.some((sessionReason) => sessionReason.id === missedTreatmentItem.id)
                );
                // ------------ END OF HANDLE DELETE --------------

                // eslint-disable-next-line arrow-body-style, no-unused-vars
                const payload = formikV2.values.missedSessionReason.map((item, _index) => {
                    // const starTime = moment(
                    //     `${moment(new Date(item.date)).format('YYYY-MM-DD')} ${moment(new Date(item.startTime)).format('HH:mm')}`
                    // )
                    //     .utc()
                    //     .format('YYYY-MM-DD HH:mm');

                    // const endTime = moment(
                    //     `${moment(new Date(item.date)).format('YYYY-MM-DD')} ${moment(new Date(item.endTime)).format('HH:mm')}`
                    // )
                    //     .utc()
                    //     .format('YYYY-MM-DD HH:mm');

                    return {
                        service: serviceId,
                        location: formik?.values?.location?.location?.id,
                        patient: formik?.values?.patientSelect?.id,
                        missed_start_time: item.startTime,
                        missed_end_time: item.endTime,
                        employee: item?.therapist?.emp_id,
                        missed_reason_id: item.reason?.id,
                        missed_reason_remark: item?.remark,
                        ...(item.progress_id && { progress_id: item.progress_id }),
                        ...(item.id && { id: item.id })
                    };
                });
                // console.log('payload::', payload);

                const resp = await triggerSave({
                    missed_reasons: payload,
                    mode: 'treatment',
                    delete_schedule: deleteArray.map((item) => item.id)
                });
                if (resp) {
                    if (resp?.data?.fields?.[0]?.invalid_date.length > 0) {
                        handleOpenConfirm(resp?.data?.fields?.[0]?.invalid_date);
                    } else {
                        notify({
                            message: resp?.data?.message,
                            severity: 'success'
                        });
                    }
                    if (!formik?.values?.dateSelect?.isValid() || startLimit === 'Invalid date' || endDateLimit === 'Invalid date') {
                        const payload = {
                            error: {
                                params: {
                                    mode: 'treatment',
                                    type,
                                    date: formik?.values?.dateSelect,
                                    start_date: startLimit,
                                    end_date: endDateLimit,
                                    converted_start_date: moment(startLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                                    converted_end_date: moment(endDateLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                                    service: [serviceId],
                                    location: [formik?.values?.location?.location?.id],
                                    patient: [formik?.values?.patientSelect?.id]
                                },
                                timestamp: new Date().toISOString(),
                                module: 'Add Missed Notes',
                                userAgent: navigator.userAgent,
                                url: window.location.href
                            }
                        };
                        sendErrorLogMail(payload);
                    } else {
                        const response = await trigger({
                            mode: 'treatment',
                            start_date: moment(startLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                            end_date: moment(endDateLimit, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                            service: [serviceId],
                            location: [formik?.values?.location?.location?.id],
                            patient: [formik?.values?.patientSelect?.id]
                        });
                        formikV2.setFieldValue('missedMinutes', response?.data?.missed_minutes);
                        updateList(response?.data?.fields, false);
                    }
                }
            } catch (error) {
                notify({
                    message: error.data?.message || 'Something went wrong',
                    severity: 'error'
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    // FN TO RENDER FORM FIELDS
    const renderFormFields = (item, i) => (
        <Grid key={`at${i}`} container spacing={1} mt={1} alignItems="center">
            <Grid item md={2}>
                <FormControl fullWidth>
                    <DatePicker
                        disabled={item?.id || item.progress_id}
                        required
                        orientation="portrait"
                        label="Date"
                        minDate={startLimit}
                        maxDate={endDateLimit}
                        inputFormat={GLOBAL_DATE_FORMAT}
                        value={item.date}
                        onChange={(date) => updateFieldValue(i, 'date', date)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                variant="outlined"
                                label="Date"
                                error={
                                    formikV2.touched.missedSessionReason?.[i]?.date &&
                                    Boolean(formikV2.errors.missedSessionReason?.[i]?.date)
                                }
                                helperText={
                                    formikV2.touched.missedSessionReason?.[i]?.date && formikV2.errors.missedSessionReason?.[i]?.date
                                }
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={2}>
                <FormControl fullWidth>
                    <Autocomplete
                        disabled={item?.id || item.progress_id}
                        limitTags={1}
                        required
                        value={item.therapist}
                        loading={therapistIsLoading}
                        options={therapistData?.fields || []}
                        getOptionLabel={(option) => option.name}
                        // getOptionLabel={(option) => getEmployeeName(option)}
                        onChange={(e, value) => updateFieldValue(i, 'therapist', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Therapist *"
                                error={
                                    formikV2.touched.missedSessionReason?.[i]?.therapist &&
                                    Boolean(formikV2.errors.missedSessionReason?.[i]?.therapist)
                                }
                                helperText={
                                    formikV2.touched.missedSessionReason?.[i]?.therapist &&
                                    formikV2.errors.missedSessionReason?.[i]?.therapist
                                }
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1.75}>
                <FormControl
                    error={Boolean(formikV2.errors.missedSessionReason?.[i]?.hour) && formikV2.touched.missedSessionReason?.[i]?.hour}
                    fullWidth
                >
                    <InputLabel id="hours-label">Hour *</InputLabel>
                    <Select
                        disabled={item?.id}
                        fullWidth
                        labelId="hours-label"
                        id="hour"
                        value={item.hour}
                        onChange={(e, value) => updateFieldValue(i, 'hour', e.target.value)}
                        // {...getFieldProps('hour')}
                        label="Hour"
                        // disabled={Boolean(!values.location)}
                    >
                        {hours.map((type, index) => (
                            <MenuItem value={type.value} key={index}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(formikV2.errors.missedSessionReason?.[i]?.hour) && formikV2.touched.missedSessionReason?.[i]?.hour && (
                        <FormHelperText>{formikV2.errors.missedSessionReason?.[i]?.hour}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={1.75}>
                <FormControl
                    error={Boolean(formikV2.errors.missedSessionReason?.[i]?.minute) && formikV2.touched.missedSessionReason?.[i]?.minute}
                    fullWidth
                >
                    <InputLabel id="minute-label">Minute *</InputLabel>
                    <Select
                        disabled={item?.id}
                        fullWidth
                        labelId="minute-label"
                        id="minute"
                        value={item.minute}
                        onChange={(e, value) => updateFieldValue(i, 'minute', e.target.value)}
                        // onChange={(e, value) => updateFieldValue(i, 'minute', value)}
                        label="Minute"
                        // disabled={Boolean(!values.location)}
                    >
                        {minute.map((type, index) => (
                            <MenuItem value={type.value} key={index}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(formikV2.errors.missedSessionReason?.[i]?.minute) && formikV2.touched.missedSessionReason?.[i]?.minute && (
                        <FormHelperText>{formikV2.errors.missedSessionReason?.[i]?.minute}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item md={2}>
                <FormControl fullWidth>
                    <Autocomplete
                        limitTags={1}
                        required
                        value={item.reason}
                        loading={isMissedReasonsFetching || isMissedReasonsLoading}
                        options={missedReasons?.fields || []}
                        getOptionLabel={(option) => option.reason}
                        onChange={(e, value) => updateFieldValue(i, 'reason', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Missed Reason *"
                                error={
                                    formikV2.touched.missedSessionReason?.[i]?.reason &&
                                    Boolean(formikV2.errors.missedSessionReason?.[i]?.reason)
                                }
                                helperText={
                                    formikV2.touched.missedSessionReason?.[i]?.reason && formikV2.errors.missedSessionReason?.[i]?.reason
                                }
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={2}>
                <FormControl fullWidth>
                    <TextField
                        name="remark"
                        fullWidth
                        multiline
                        label="Remark"
                        variant="outlined"
                        maxRows={1}
                        minRows={1}
                        value={item?.remark}
                        onBlur={formikV2.handleBlur}
                        InputLabelProps={{ shrink: item?.remarke }}
                        onChange={(e, value) => updateFieldValue(i, 'remark', e.target.value)}
                        error={
                            formikV2.touched.missedSessionReason?.[i]?.remark && Boolean(formikV2.errors.missedSessionReason?.[i]?.remark)
                        }
                        helperText={formikV2.touched.missedSessionReason?.[i]?.remark && formikV2.errors.missedSessionReason?.[i]?.remark}
                    />
                </FormControl>
            </Grid>
            <Grid item md={0.5}>
                {(item.id || (!item?.id && !item.progress_id)) && (
                    <IconButton
                        sx={{ border: '1px solid #E0E0E0' }}
                        size="small"
                        onClick={() => {
                            const newTime = [...formikV2.values.missedSessionReason];
                            newTime.splice(i, 1);
                            formikV2.setFieldValue('missedSessionReason', newTime);
                            const totalMinutes = calculateTotalMinutes(newTime);
                            setRemainingTime(totalMinutes);
                            formikV2.setTouched({ missedSessionReason: [] });
                        }}
                    >
                        <Close sx={{ fontSize: 18 }} color="error" />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );

    const handleClose = () => {
        formik.resetForm();
        formikV2.resetForm();
        setMissedNoteOpen(false);
        setFirstSearch(false);
        setStartLimit(null);
        setEndDateLimit(null);
    };

    const handleReset = () => {
        patientRef?.current?.handleReset();
        formik.resetForm();
        formikV2.resetForm();
        setFirstSearch(false);
        setStartLimit(null);
        setEndDateLimit(null);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h3">Add Missed Note ({getServiceNameById(serviceId)})</Typography>
                    <IconButton
                        onClick={() => {
                            handleClose();
                        }}
                        sx={{ position: 'absolute', top: 10, right: 10, color: (theme) => theme.palette.grey[500] }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pt: 2 }}>
                    <>
                        <Grid container spacing={1} direction="row" xs={12} sx={{ py: 1 }}>
                            <Grid item xs={12} md={3}>
                                <PatientDropdown
                                    required
                                    async
                                    fullWidth
                                    // disabled={treatmentInfo.isGroup}
                                    // sx={{
                                    //     mt: 2,
                                    //     maxWidth: 300
                                    // }}
                                    value={formik.values.patientSelect}
                                    onChange={(e, val) => {
                                        formik.setFieldValue('patientSelect', e);
                                        formik.setFieldValue('location', null);
                                    }}
                                    ref={patientRef}
                                    onBlur={formik.handleBlur}
                                    payload={{
                                        service: serviceId,
                                        mode: 'treatment'
                                        // ...(treatmentInfo.patients.length > 1 && {
                                        //     location: [formik.values.location?.location.id],
                                        //     payer_category: formik.values.location?.payer_category
                                        // })
                                    }}
                                    // excludeList={treatmentInfo.patients.map((item) => item.patientId)}
                                    error={Boolean(
                                        formik.values.patientSelect?.notification ||
                                            (formik.touched.patientSelect && formik.errors.patientSelect)
                                    )}
                                    helperText={
                                        formik.values.patientSelect?.notification ||
                                        (formik.touched.patientSelect && formik.errors.patientSelect)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Autocomplete
                                    disabled={!formik.values.patientSelect}
                                    // disablePortal
                                    options={locations || []}
                                    //    loading={locLoading}
                                    onChange={(e, val) => formik.setFieldValue('location', val)}
                                    value={formik.values.location}
                                    getOptionLabel={(option) => option.location?.name}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.location?.id}>
                                            {option.location?.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label="Locations"
                                            fullWidth
                                            error={Boolean(formik.touched.location && formik.errors.location)}
                                            helperText={formik.touched.location && formik.errors.location}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    required
                                    fullWidth
                                    orientation="portrait"
                                    label="Date"
                                    // minDate={startLimit}
                                    // maxDate={endDateLimit}
                                    inputFormat={GLOBAL_DATE_FORMAT}
                                    value={formik.values.dateSelect}
                                    onChange={(date) => handleDateChange(date)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="Date"
                                            error={formik.touched.dateSelect && Boolean(formik.errors.dateSelect)}
                                            helperText={formik.touched.dateSelect && formik.errors.dateSelect}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack direction="row" spacing={1} sx={{ minHeight: 50 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            minHeight: 50
                                            // width: '200px',
                                            // marginTop: '10px'
                                        }}
                                        disabled={formik.values.patientSelect?.notification}
                                        onClick={() => formik.handleSubmit()}
                                        // onClick={() => (firstSearch ? callNotesList() : addReasonField())}
                                    >
                                        Add Missed Note
                                    </Button>
                                    <Button onClick={handleReset} variant="contained">
                                        Reset
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* </Card> */}
                        {((startLimit && endDateLimit) || formikV2?.values?.missedMinutes !== '') && (
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                xs={12}
                                sx={{ pt: 3, pb: 2, px: 2, justifyContent: 'space-between' }}
                            >
                                {startLimit && endDateLimit && (
                                    <Stack direction="row" alignItems="flex-start" sx={{ alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: '14px' }} variant="span">
                                            Missed note for the Week :
                                        </Typography>
                                        <Typography
                                            sx={{ marginLeft: '2px', fontWeight: '500', color: '#212121', fontSize: '15px' }}
                                            variant="span"
                                        >
                                            {startLimit &&
                                            endDateLimit &&
                                            formik?.values?.dateSelect &&
                                            moment(new Date(formik?.values?.dateSelect), moment.ISO_8601, true).isValid()
                                                ? `${getCustomWeekNumber(formik?.values?.dateSelect)} (${changeFormat(startLimit, GLOBAL_DATE_FORMAT)} - ${changeFormat(endDateLimit, GLOBAL_DATE_FORMAT)})`
                                                : 'N/A'}
                                        </Typography>
                                    </Stack>
                                )}
                                <Stack direction="row" justifyContent="flex-end" gap={2} sx={{ alignItems: 'center' }}>
                                    {formikV2?.values?.missedMinutes !== '' &&
                                        missedTreatmentSuccess &&
                                        !missedTreatmentFetching &&
                                        !missedTreatmentLoading && (
                                            <Typography sx={{ fontSize: '14px' }} variant="span">
                                                <Typography variant="span" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                    {remainingTime}
                                                </Typography>{' '}
                                                /{' '}
                                                <Typography variant="span" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                    {formikV2?.values?.missedMinutes}
                                                </Typography>{' '}
                                                Missed minutes reasons added.
                                            </Typography>
                                        )}
                                    {missedTreatmentSuccess && !missedTreatmentFetching && !missedTreatmentLoading && firstSearch && (
                                        <Button
                                            variant="contained"
                                            sx={
                                                {
                                                    // minHeight: 50
                                                    // width: '200px',
                                                    // marginTop: '10px'
                                                }
                                            }
                                            startIcon={<AddIcon />}
                                            onClick={() => addReasonField()}
                                            // onClick={() => (firstSearch ? callNotesList() : addReasonField())}
                                        >
                                            Add Additional Note
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        )}
                        {/* * Form Fields for Start Date, Therapist, Start Time, End Time, and Reason * */}
                        {missedTreatmentFetching || missedTreatmentLoading ? (
                            <Spinner />
                        ) : (
                            formikV2.values.missedSessionReason?.map((item, i) => renderFormFields(item, i))
                        )}
                        {missedTreatmentSuccess &&
                            !missedTreatmentFetching &&
                            !missedTreatmentLoading &&
                            startLimit &&
                            endDateLimit &&
                            firstSearch && (
                                <Stack
                                    direction="row"
                                    sx={{
                                        marginTop: '20px'
                                    }}
                                    spacing={1}
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleClose();
                                        }}
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                    <LoadingButton loading={saveLoading} variant="contained" onClick={handleSubmit}>
                                        Save
                                    </LoadingButton>
                                </Stack>
                            )}
                    </>
                </DialogContent>
            </Dialog>
            <Dialog open={openCreateConfirm} onClose={handleCloseConfirm} fullWidth maxWidth="xs">
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h3">Invalid Dates</Typography>
                    <IconButton
                        onClick={() => {
                            handleCloseConfirm();
                        }}
                        sx={{ position: 'absolute', top: 10, right: 10, color: (theme) => theme.palette.grey[500] }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ pt: 2 }}>
                    <Typography>Missed notes cannot be added for the following dates as they are prior to the evaluation dates:</Typography>
                    <ul style={{ padding: '12px 0px 0px 8px' }}>
                        {openCreateConfirm?.map((date, index) => (
                            <li key={index}>{utcToLocal(date, 'MM/DD/YYYY')}</li>
                        ))}
                    </ul>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddMissedNote;
