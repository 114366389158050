import { api } from './api';

export const employeeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // common
        getEmployeeList: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['EmployeeList']
        }),
        // employee details
        getEmployeeDetails: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['EmployeeDetails']
        }),

        // update
        addUpdateEmployeeDetails: builder.mutation({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.emp_id ? 'UPDATE' : 'CREATE'
                }
            }),
            // providesTags: ['EmployeeList', 'EmployeeDetails']
            invalidatesTags: (result, error, arg) => (result ? ['EmployeeDetails'] : [])
        }),
        deleteEmployee: builder.mutation({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['EmployeeList']
        }),
        getRolesWithPermissions: builder.query({
            query: (payload) => ({
                url: 'api/roles-permissions/roles/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),
        getPermissions: builder.query({
            query: (payload) => ({
                url: 'api/roles-permissions/permissions/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),

        sendVerification: builder.mutation({
            query: (body) => ({
                url: 'api/user/mail/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'welcome'
                }
            }),
            invalidatesTags: ['EmployeeList']
        }),
        changePassword: builder.mutation({
            query: (body) => ({
                url: 'api/user/resetpassword/',
                method: 'POST',
                body,
                headers: {
                    SUBTYPE: 'change'
                }
            })
        }),

        // general
        getEmpLocationList: builder.query({
            query: (payload) => ({
                url: 'api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
            // providesTags: ['EmployeeDetails']
        }),
        // history
        getEmployeeHistory: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'ACTIVITY'
                }
            }),
            invalidatesTags: ['EmployeeHistory']
        }),
        getTherapistList: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LISTTHRP'
                }
            }),
            providesTags: ['TherapistList']
        }),
        getPatientTherapist: builder.query({
            query: (payload) => ({
                url: 'api/patient/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'patienttherapist'
                }
            }),
            providesTags: ['TherapistList']
        }),
        getPhysicianList: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LISTPHYS'
                }
            }),
            providesTags: ['PhysicianList']
        }),
        getEmploymentTypes: builder.query({
            query: (payload) => ({
                url: 'api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listempltypes'
                }
            })
            // providesTags: ['TherapistList']
        }),
        getServiceExistCheck: builder.query({
            query: (payload) => ({
                url: 'api/employees/services/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'serviceavailed'
                }
            }),
            providesTags: ['EmployeeServices']
        }),
        getSuperviseeList: builder.query({
            query: (payload) => ({
                url: '/api/employees/supervisee-list/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            })
        }),
        getSuperviseeListActivityReport: builder.query({
            query: (payload) => ({
                url: '/api/employees/supervisee-list/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list_supervisee'
                }
            })
        }),
        getEmployeeServices: builder.query({
            query: (payload) => ({
                url: 'api/employees/services/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['EmployeeServicesList']
        })
    })
});

export const {
    useAddUpdateEmployeeDetailsMutation,
    useGetEmployeeListQuery,
    useGetEmployeeDetailsQuery,
    useDeleteEmployeeMutation,
    useGetRolesWithPermissionsQuery,
    useGetPermissionsQuery,
    useSendVerificationMutation,
    useChangePasswordMutation,
    useGetEmpLocationListQuery,
    useGetEmployeeHistoryQuery,
    useLazyGetEmployeeHistoryQuery,
    useGetTherapistListQuery,
    useGetPatientTherapistQuery,
    useGetEmploymentTypesQuery,
    useGetPhysicianListQuery,
    useGetServiceExistCheckQuery,
    useGetSuperviseeListQuery,
    useGetEmployeeServicesQuery,
    useGetSuperviseeListActivityReportQuery
    
} = employeeApi;
