import { api } from '../api';

export const classroomApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: (payload) => ({
                url: '/api/location/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            })
        }),
        getTeachers: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listtchr'
                }
            })
        }),
        getStudents: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'liststuds'
                }
            }),
            providesTags: ['StudentList']
        }),

        getClassrooms: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'LIST'
                }
            }),
            providesTags: ['ClassroomList']
        }),
        getClassroomDetails: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DETAIL'
                }
            }),
            providesTags: ['ClassroomDetails']
        }),

        addUpdateClassroom: builder.mutation({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: payload.cls_id ? 'update' : 'create'
                }
            }),
            invalidatesTags: ['ClassroomList', 'ClassroomDetails']
        }),
        deleteClassroom: builder.mutation({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'DELETE'
                }
            }),
            invalidatesTags: ['ClassroomList']
        }),

        getClassroomHistory: builder.query({
            query: (payload) => ({
                url: '/api/classroom/classroom-pdc/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'activity'
                }
            }),
            invalidatesTags: ['ClassroomHistory']
        })
    })
});

export const {
    useGetLocationsQuery,
    useGetTeachersQuery,
    useGetStudentsQuery,
    useGetClassroomsQuery,
    useGetClassroomDetailsQuery,
    useAddUpdateClassroomMutation,
    useDeleteClassroomMutation,
    useGetClassroomHistoryQuery,
    useLazyGetClassroomHistoryQuery
} = classroomApi;
