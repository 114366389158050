import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Stack } from '@mui/material';

function BackAction({ backLabel = 'Back To List', link, actionElement, sx, ...rest }) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        } else {
            navigate(-1);
        }
    };

    return (
        <Stack
            sx={{
                mt: 2,
                width: '100%',
                ...sx
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            {...rest}
        >
            <Button color="primary" onClick={handleClick}>
                {backLabel}
            </Button>

            {actionElement}
        </Stack>
    );
}

export default BackAction;

// write react prop types
