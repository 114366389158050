// useEventForm.js
import { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import { useAddEventMutation, useGetEventDetailsQuery } from 'store/services/schedule/events';
import { useGetServicesQuery } from 'store/services/common';
import usePermission from 'hooks/usePermission';
import useAuth from 'hooks/useAuth';
import { PAYLOAD_DATE_TIME_FORMAT, THERAPY_MIN_TIME, THERAPY_MAX_TIME } from 'constant';
import { isDateValid } from 'utils/validation';

// constant
const EventSchema = Yup.object().shape({
    title: Yup.string().max(255),
    description: Yup.string().max(5000).nullable(),
    // end: Yup.date().when('start', (start, schema) => start && schema.min(start, '*End date must be later than start date')),
    start: Yup.date()
        .required()
        .test('is-valid-date', 'Please Enter a Valid Date', (value) => isDateValid(value))
        .typeError('Please enter a valid date'),
    hour: Yup.number().required('*Please select a hour.'),
    minute: Yup.number().required('*Please select a minute'),
    visitType: Yup.string().required('*Please select a visit type.'),
    status: Yup.string(),
    service: Yup.object()
        .shape({
            id: Yup.number().required('*Select a valid service type.'),
            service_name: Yup.string().required('*Select a valid service type.')
        })
        .required('*Select a valid service type.')
        .nullable(),
    employee: Yup.object().nullable().required('*Select a valid Employee.'),
    patient: Yup.object()
        .shape({
            id: Yup.number().required('*Please select a Patient.'),
            name: Yup.string().required('*Please select a Patient.')
        })
        .nullable()
        .when('treatment_type', {
            is: (value) => value !== 'group', // Required only if treatment_type is NOT 'group'
            then: (schema) => schema.required('*Please select a Patient.'),
            otherwise: (schema) => schema.notRequired().nullable()
        }),
    location: Yup.object()
        .shape({
            id: Yup.number().required('*Select a valid location.'),
            name: Yup.string().required('*Select a valid location.')
        })
        .required('*Select a valid location.')
        .nullable(),
    color: Yup.string().max(255).nullable(),
    textColor: Yup.string().max(255).nullable(),
    treatment_type: Yup.string(),
    grp_patients: Yup.array()
        .of(Yup.object())
        .nullable()
        .when('treatment_type', {
            // eslint-disable-next-line arrow-body-style
            is: (value) => {
                return value === 'group';
            },
            then: Yup.array()
                .of(Yup.object())
                .min(2, 'Please select at least two Patients.')
                .max(4, 'Maximun of 4 patients allowed.')
                .required('Please select at least two Patients.')
                .typeError('Please select at least two Patients.')
        })
});

const getInitialValues = (event, range) => {
    const newEvent = {
        title: '',
        description: '',
        color: '#2196f3',
        textColor: '',
        allDay: false,
        status: '',
        days: [],
        visitType: event ? event.visit_type : '',
        employee: !event ? null : event.employees?.[0],
        // eslint-disable-next-line consistent-return
        patient: (() => {
            if (!event) {
                return null;
            }
            if (!event.is_grouped) {
                return event.patients?.[0];
            }
            return null;
        })(),
        hour: event ? event.duration_hour : 1,
        minute: event ? event.duration_minute : 0,
        location: event ? event.locations?.[0] : null,
        service: event ? event.services?.[0] : null,
        start: (() => {
            if (event) {
                return moment.utc(event.start_date_time).local();
            }
            if (range) {
                return moment(new Date(range.start)).add(6, 'hours');
            }
            return null;
        })(),
        end: (() => {
            if (event) {
                return moment.utc(event.end_date_time).local();
            }
            if (range) {
                return moment(new Date(range.end));
            }
            return null;
        })(),
        treatment_type: event && event?.is_grouped ? 'group' : 'individual',
        // eslint-disable-next-line consistent-return
        grp_patients: (() => {
            if (!event) {
                return null;
            }
            // for handling patient dropdown structure
            if (event.is_grouped) {
                return event.patients.map((item) => ({
                    id: item.id,
                    user: {
                        name: item.name
                    }
                }));
            }
            return null;
        })()
    };

    if (event || range) {
        return _.merge({}, newEvent, event);
    }

    return newEvent;
};

const generateHour = () => {
    const hours = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 13; i++) {
        hours.push({ value: i, label: i });
    }

    return hours;
};

const generateMinute = () => {
    const minute = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 61; i += 5) {
        if (i === 60) {
            minute.push({ value: 59, label: 59 });
        } else {
            minute.push({ value: i, label: i });
        }
    }

    return minute;
};

const makeTitle = (service, patient) => {
    let title = '';
    if (service) {
        title += service;
        title += ' for ';
    }

    if (Array.isArray(patient)) {
        if (patient.length > 0) {
            title = `Group ${title}`;
            title += patient.map((pat) => pat.name).join(', ');
        }
    }

    let newTitle = title.toLowerCase();
    newTitle = newTitle.charAt(0).toUpperCase() + newTitle.slice(1);

    return newTitle;
};

const transformData = (array, string, id) => {
    const transformedArray = [];

    array.forEach((item) => {
        transformedArray.push({ name: item[string], id: item[id] });
    });

    return transformedArray;
};

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const visitValues = [
    { value: 'SCREENING', label: 'Screening' },
    { value: 'EVALUATION', label: 'Evaluation' },
    { value: 'TREATMENT', label: 'Treatment' }
];

export { getInitialValues, generateHour, generateMinute, makeTitle, transformData, EventSchema, days, visitValues };
