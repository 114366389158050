import React, { useMemo } from 'react';
import { Typography, Dialog, DialogContent, DialogTitle, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLazyGetEvaluationHistoryQuery } from 'store/services/therapy/evaluation';
import History from 'components/common/History';

const actions = [
    {
        label: 'Create',
        value: 'create'
    },
    {
        label: 'Update',
        value: 'update'
    },
    {
        label: 'Delete',
        value: 'delete'
    },
    {
        label: 'General',
        value: 'general'
    },
    {
        label: 'Activate',
        value: 'activate'
    },
    {
        label: 'Deactivate',
        value: 'deactivate'
    }
];

export default function EvaluationViewModal({ evaluationId, handleClose }) {
    const open = Boolean(evaluationId);

    const historyData = useLazyGetEvaluationHistoryQuery();

    const historyPayload = useMemo(
        () => ({
            filters: {
                evaluation_id: evaluationId
            }
        }),
        [evaluationId]
    );

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
                <DialogTitle sx={{ p: 2 }}>
                    <Typography variant="h4">Evaluation History</Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: 10, right: 10, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ m: 0, pt: 1 }}>
                    {evaluationId && <History data={historyData} payload={historyPayload} actions={actions} />}
                </DialogContent>
            </Dialog>
        </>
    );
}
