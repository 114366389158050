/* eslint-disable arrow-body-style */
import PropType from 'prop-types';

const TimeLineCard = ({ action, headerPrimary, headerSecondary, description, footer }) => {
    return (
        <div className={`timeline__card card timeline__${action}`}>
            <header className="card__header">
                <time className="time">
                    <span className="time__day">{headerPrimary}</span>
                    <span className="time__month">{headerSecondary}</span>
                </time>
                <span className="timeline__action">{action}</span>
            </header>
            <div className="card__content">
                <p>
                    {description} {footer && <span>by</span>} {footer}
                </p>
            </div>
        </div>
    );
};

TimeLineCard.propTypes = {
    action: PropType.string,
    headerPrimary: PropType.string,
    headerSecondary: PropType.string,
    description: PropType.string,
    footer: PropType.string
};

export default TimeLineCard;
