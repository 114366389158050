import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import { Typography } from '@mui/material';
import { useGetClientLogoQuery } from 'store/services/client';

function ClientLogo() {
    const { user } = useAuth();
    const { client } = useAuth();

    const {
        data: logo,
        isError,
        isFetching,
        isLoading
    } = useGetClientLogoQuery(
        {
            ...(user?.client_id && { client_id: user.client_id })
        },
        { skip: !user?.client_id }
    );

    const [imageError, setImageError] = useState(false);

    useEffect(() => {
        setImageError(false);
    }, [user?.client_id]);

    const handleError = () => {
        setImageError(true);
    };

    return (
        <>
            {logo && !isError && !(isFetching || isLoading) ? (
                <div style={{ maxWidth: '100%', maxHeight: '50px' }}>
                    {!imageError ? (
                        <img className="alt-logo" style={{ maxWidth: '100px', maxHeight: '50px' }} src={logo} alt="" onError={handleError} />
                    ) : (
                        !user?.is_superuser && (
                            <Typography color="white" variant="h4">
                                {client.name}
                            </Typography>
                        )
                    )}
                </div>
            ) : (
                !user?.is_superuser && (
                    <Typography color="white" style={{ maxWidth: '100%', maxHeight: '50px' }} variant="h4">
                        {client?.name}
                    </Typography>
                )
            )}
        </>
    );
}

export default ClientLogo;
