import { api } from '../api';

export const therapyApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTherapyProgress: builder.query({
            query: (payload) => ({
                url: '/api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'list'
                }
            }),
            providesTags: ['TherapyProgress']
        }),
        getTherapistList: builder.query({
            query: (payload) => ({
                url: '/api/employees/employee/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'listthrp'
                }
            }),
            providesTags: ['TherapistList']
        }),
        getPatientRecords: builder.query({
            query: (payload) => ({
                url: '/api/therapy/progress/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'patient_records'
                }
            }),
            providesTags: ['PatientRecords']
        }),
        getPatientLocations: builder.query({
            query: (payload) => ({
                url: '/api/therapy/',
                method: 'POST',
                body: payload,
                headers: {
                    SUBTYPE: 'getpatientlocation'
                }
            }),
            // providesTags: ['PatientRecords']
        }),
    })
});

export const { useGetTherapyProgressQuery, useGetTherapistListQuery, useGetPatientRecordsQuery, useLazyGetPatientLocationsQuery} = therapyApi;
